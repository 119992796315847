@import url('https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,600,700,700i&display=swap');

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (min-width: 768px) {
    h1, .h1 {
        margin-top: 4rem !important;
        font: 700 3.25rem/3.75rem "Montserrat", sans-serif !important;
        color: #333;
    }
    h1 span {
        color: rgb(53, 224, 224);
    }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn-solid-lg {
    display: inline-block;
    margin: auto 0.5rem;
    padding: 1rem 1.6rem;
    border: 0.125rem solid #00c9db;
    border-radius: 2rem;
    background-color: #00c9db;
    color: #fff;
    font: 700 0.75rem/0 "Montserrat", sans-serif;
    text-decoration: none;
    transition: all 0.2s;
}

/* FORMS */

label.col-form-label {
    padding-top: 10px;
    font-weight: 500;
    font-size: 0.90rem !important;
}
label.form-check-label {
    font-weight: 500;
    font-size: 0.75rem !important;
}

input.form-control {
    padding: 0.1rem 0.5rem;
}

input::placeholder {
    font-weight: 400;
    font-size: 0.85rem !important;
}

.bzr-form {
    /* max-width: 768px; */
    border: 0px !important;
    border-radius: 0px !important;
    box-shadow: 0 0.0625rem 0.375rem 0 rgba(0, 0, 0, 0.2);
}
.bzr-form h6 {
    color: #333;
    font-size: 1.2rem;
    font-weight: 300;
}
.bzr-form h5,h4 {
    color: #333;
}
.form-group label{
    color: #333;
    font-size: 0.85rem;
    font-weight: 300;
}
.form-group button{
    text-shadow: 0px 0px 1rem #fff;
    box-shadow: 0 0 3px #333;
}

/*  check this declarations*/

button.btn {
    padding: 0.1rem 0.5rem;
}


/************************/
/*     03. TopNavbar    */
/************************/

/* Bootstrap Navbar Class equivalent*/
nav.navbar-custom {
    margin-bottom: 5px;
    background-color: #fff;
    box-shadow: 0 0.0625rem 0.375rem 0 rgba(0, 0, 0, 0.1);
    font: 500 0.90rem/2rem "Montserrat", sans-serif;
    transition: all 0.2s;
}

nav.navbar-custom a.navbar-brand {
    /* color: #430064; */
    color: #333;
    text-decoration: none; 
    font-size: 2rem;
    padding: 0.5rem 0 0 0.2rem;
    line-height: 35px;
}

nav.navbar-custom a.navbar-brand span {
  text-decoration: none; 
  font-style: italic !important;
  font-size: 0.75rem;
}

nav.navbar-custom.scrolled-nav {
    /* background-color: #4633af; */
    background-color: rgba(255, 255, 255, 0.795) !important;
}

nav.navbar-custom a.nav-link {
    padding: 1rem 1rem;
    font-size: 1rem !important;
    color: #555 !important;
}
nav.navbar-custom a:hover.nav-link {
    text-shadow: 0px 0px 1rem #999;
}

nav.navbar-custom a.right-top-links, .user-dropdown a {
    padding: 0.5rem 5px 0 5px;
    font-size: 0.95rem !important;
    font-weight: 600;
    text-decoration: none;
    color: #1d7acb !important;
}


nav.navbar-custom a:hover.right-top-links {
    text-shadow: 0px 0px 1rem #999;
}

.navbar-custom .nav-item .nav-link:hover,
.navbar-custom .nav-item .nav-link.active {
    color: #00c9db;
}

/* Dropdown Menu */
.navbar-custom .dropdown:hover > .dropdown-menu {
    /* display: block; */ /* this makes the dropdown menu stay open while hovering it */
    /* min-width: auto; */
    /* animation: fadeDropdown 0.2s; */ /* required for the fade animation */
}

@keyframes fadeDropdown {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.navbar-custom .dropdown-toggle:focus { /* removes dropdown outline on focus  */
    outline: 0;
}

.navbar-custom .dropdown-menu {
    position: absolute;
    left: -60px;
    margin-top: 0;
    border: solid gray 1px;
    border-radius: 0.25rem;
}

.navbar-custom .dropdown-item {
    text-decoration: none;
}

.navbar-custom .dropdown-item:hover {
   /* background-color: #4633af; */
}

.navbar-custom .dropdown-item .item-text {
    font: 700 0.75rem/1.5rem "Montserrat", sans-serif;
}

.navbar-custom .dropdown-item:hover .item-text {
    color: #00c9db;
}

.navbar-custom .dropdown-items-divide-hr {
    width: 100%;
    height: 1px;
    margin: 0.25rem auto 0.25rem auto;
    border: none;
    background-color: #b5bcc4;
    opacity: 0.2;
}
/* end of dropdown menu */

.navbar-custom .social-icons {
    display: none;
}



.navbar-custom .navbar-toggler {
    border: none;
    color: #333;
    font-size: 2rem;
}
.navbar-custom.top-nav-collapse .navbar-toggler {
  color: #fff;
}


.navbar-custom button[aria-expanded='false'] .navbar-toggler-awesome.fas.fa-times{
    display: none;
}

.navbar-custom button[aria-expanded='false'] .navbar-toggler-awesome.fas.fa-bars{
    display: inline-block;
}

.navbar-custom button[aria-expanded='true'] .navbar-toggler-awesome.fas.fa-bars{
    display: none;
}

.navbar-custom button[aria-expanded='true'] .navbar-toggler-awesome.fas.fa-times{
    display: inline-block;
    margin-right: 0.125rem;
}

/*************************/
/*     04. AdmNavbar     */
/*************************/

/* Bootstrap Navbar Class equivalent*/
nav.navbar-adm {
    background-color: #fff;
    box-shadow: 0 0.0625rem 0.375rem 0 rgba(0, 0, 0, 0.1);
    font: 500 0.90rem/2rem "Montserrat", sans-serif;
    transition: all 0.2s;
    padding: 0 1rem;
}

nav.navbar-adm a.navbar-brand {
    /* color: #430064; */
    color: #333;
    text-decoration: none; 
    font-size: 2rem;
    padding: 0.8rem 0 0 0.2rem;
}

nav.navbar-adm a.navbar-brand span {
  text-decoration: none; 
  font-style: italic !important;
  font-size: 0.75rem;
}

nav.navbar-adm.scrolled-nav {
    /* background-color: #4633af; */
    background-color: rgba(255, 255, 255, 0.795) !important;
}

nav.navbar-adm a.nav-link {
    padding-top: 1.2rem;
    font-size: 1rem !important;
    color: #555 !important;
}
nav.navbar-adm a:hover.nav-link {
    text-shadow: 0px 0px 1rem #999;
}

nav.navbar-adm a.right-top-links {
    padding: 0.5rem 5px 0 5px;
    font-size: 0.9rem !important;
    text-decoration: none;
    color: #555 !important;
}
nav.navbar-adm a:hover.right-top-links {
    text-shadow: 0px 0px 1rem #999;
}


/*   Continue editing from here   */



.navbar-adm .nav-item .nav-link:hover,
.navbar-adm .nav-item .nav-link.active {
    color: #00c9db;
}

/* Dropdown Menu */
.navbar-adm .dropdown:hover > .dropdown-menu {
    /* display: block; */ /* this makes the dropdown menu stay open while hovering it */
    /* min-width: auto; */
    /* animation: fadeDropdown 0.2s; */ /* required for the fade animation */
}

.navbar-adm .dropdown-toggle:focus { /* removes dropdown outline on focus  */
    outline: 0;
}

.navbar-adm .dropdown-menu {
    position: absolute;
    left: -60px;
    margin-top: 0;
    border: solid gray 1px;
    border-radius: 0.25rem;
}

.navbar-adm .dropdown-item {
    text-decoration: none;
}
.navbar-adm .dropdown-item:hover {
   /* background-color: #4633af; */
}
.navbar-adm .dropdown-item .item-text {
    font: 700 0.75rem/1.5rem "Montserrat", sans-serif;
}
.navbar-adm .dropdown-item:hover .item-text {
    color: #00c9db;
}
.navbar-adm .dropdown-items-divide-hr {
    width: 100%;
    height: 1px;
    margin: 0.25rem auto 0.25rem auto;
    border: none;
    background-color: #b5bcc4;
    opacity: 0.2;
}
/* end of dropdown menu */

.navbar-adm .social-icons {
    display: none;
}
.navbar-adm .navbar-toggler {
    border: none;
    color: #333;
    font-size: 2rem;
}
.navbar-adm.top-nav-collapse .navbar-toggler {
  color: #fff;
}


.navbar-adm button[aria-expanded='false'] .navbar-toggler-awesome.fas.fa-times{
    display: none;
}

.navbar-adm button[aria-expanded='false'] .navbar-toggler-awesome.fas.fa-bars{
    display: inline-block;
}

.navbar-adm button[aria-expanded='true'] .navbar-toggler-awesome.fas.fa-bars{
    display: none;
}

.navbar-adm button[aria-expanded='true'] .navbar-toggler-awesome.fas.fa-times{
    display: inline-block;
    margin-right: 0.125rem;
}

/**************************/
/*       04. SideBar      */
/**************************/

aside.left-panel {
    display: table-cell;
    height: 100vh;
    min-height: 100%;
    vertical-align: top;
    width: 250px;
    transition: width 0.3s ease; 
    font: 400 0.75rem/2rem "Montserrat", sans-serif;
}
aside.left-panel .navbar {
    flex-direction: column;
    padding: 2rem 1.5rem;
    align-items: flex-start !important;
    font-size: 0.95rem;
}
aside.left-panel .navbar.navbar-expand .navbar-nav {
    flex-direction: column;
    width: 100%;
}
aside.left-panel .navbar.navbar-expand .navbar-nav .nav-link {
    padding: 0.2rem 0px;
}

aside.left-panel .navbar-nav .dropdown-menu {
    position: static;
    float: none;
}



aside.left-panel .navbar h3.menu-title {
    border-bottom: 1px solid #4e4e52;
    color: #9496a1;
    clear: both;
    display: block;
    font-size: 0.9rem;
    font-weight: 700;
    line-height: 50px;
    padding: 15px 0 0 0;
    text-transform: uppercase;
    width: 100%;
}

aside.left-panel .navbar span.navbar-brand a { 
    color: #9496a1;
    clear: both;
    display: block;
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
    width: 100%;
}

aside.left-panel .navbar span.navbar-brand a:hover { 
    color: #ddd;
    text-decoration: none;
}

aside.left-panel .navbar .navbar-nav div a.dropdown-toggle:after {
    display: none; 
}

aside.left-panel .navbar .navbar-nav div a:before {
    font-family: 'Font Awesome 5 Free';
    font-weight: 400;
    content: "\f105";

    color: #c8c9ce;
    position: absolute;
    top: 0.2rem;
    right: 0;
    text-align: right;
    -webkit-transition: all .25s ease;
    -moz-transition: all .25s ease;
    -ms-transition: all .25s ease;
    -o-transition: all .25s ease;
    transition: all .25s ease;
}

aside.left-panel .navbar .navbar-nav div.show a:before {
    font-family: 'Font Awesome 5 Free';
    font-weight: 400;
    content: "\f107";
    position:absolute;
    color:#ccc;
    top:0.2rem;
    right:0;
    font-weight:500;
}

/* Nav.Link */
aside.left-panel .navbar .navbar-nav a svg {
    color: #8b939b;
    margin-left: 5px;
    margin-right: 20px;
}

/* NavDropdown*/
aside.left-panel .navbar .navbar-nav div svg {
    color: #8b939b;
    margin-left: 5px;
    margin-right: 20px;
}



.right-panel {
    display: table-cell;
    width: 100%;
    padding-top: 1.5rem !important;
    padding-left: 2rem !important;
    -webkit-transition: all .35s ease;
    -moz-transition: all .35s ease;
    -ms-transition: all .35s ease;
    -o-transition: all .35s ease;
    transition: all .35s ease; 
}


