@import url(https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,600,700,700i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,600,700,700i&display=swap);
body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (min-width: 768px) {
    h1, .h1 {
        margin-top: 4rem !important;
        font: 700 3.25rem/3.75rem "Montserrat", sans-serif !important;
        color: #333;
    }
    h1 span {
        color: rgb(53, 224, 224);
    }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn-solid-lg {
    display: inline-block;
    margin: auto 0.5rem;
    padding: 1rem 1.6rem;
    border: 0.125rem solid #00c9db;
    border-radius: 2rem;
    background-color: #00c9db;
    color: #fff;
    font: 700 0.75rem/0 "Montserrat", sans-serif;
    text-decoration: none;
    transition: all 0.2s;
}

/* FORMS */

label.col-form-label {
    padding-top: 10px;
    font-weight: 500;
    font-size: 0.90rem !important;
}
label.form-check-label {
    font-weight: 500;
    font-size: 0.75rem !important;
}

input.form-control {
    padding: 0.1rem 0.5rem;
}

input::-webkit-input-placeholder {
    font-weight: 400;
    font-size: 0.85rem !important;
}

input:-ms-input-placeholder {
    font-weight: 400;
    font-size: 0.85rem !important;
}

input::-ms-input-placeholder {
    font-weight: 400;
    font-size: 0.85rem !important;
}

input::placeholder {
    font-weight: 400;
    font-size: 0.85rem !important;
}

.bzr-form {
    /* max-width: 768px; */
    border: 0px !important;
    border-radius: 0px !important;
    box-shadow: 0 0.0625rem 0.375rem 0 rgba(0, 0, 0, 0.2);
}
.bzr-form h6 {
    color: #333;
    font-size: 1.2rem;
    font-weight: 300;
}
.bzr-form h5,h4 {
    color: #333;
}
.form-group label{
    color: #333;
    font-size: 0.85rem;
    font-weight: 300;
}
.form-group button{
    text-shadow: 0px 0px 1rem #fff;
    box-shadow: 0 0 3px #333;
}

/*  check this declarations*/

button.btn {
    padding: 0.1rem 0.5rem;
}


/************************/
/*     03. TopNavbar    */
/************************/

/* Bootstrap Navbar Class equivalent*/
nav.navbar-custom {
    margin-bottom: 5px;
    background-color: #fff;
    box-shadow: 0 0.0625rem 0.375rem 0 rgba(0, 0, 0, 0.1);
    font: 500 0.90rem/2rem "Montserrat", sans-serif;
    transition: all 0.2s;
}

nav.navbar-custom a.navbar-brand {
    /* color: #430064; */
    color: #333;
    text-decoration: none; 
    font-size: 2rem;
    padding: 0.5rem 0 0 0.2rem;
    line-height: 35px;
}

nav.navbar-custom a.navbar-brand span {
  text-decoration: none; 
  font-style: italic !important;
  font-size: 0.75rem;
}

nav.navbar-custom.scrolled-nav {
    /* background-color: #4633af; */
    background-color: rgba(255, 255, 255, 0.795) !important;
}

nav.navbar-custom a.nav-link {
    padding: 1rem 1rem;
    font-size: 1rem !important;
    color: #555 !important;
}
nav.navbar-custom a:hover.nav-link {
    text-shadow: 0px 0px 1rem #999;
}

nav.navbar-custom a.right-top-links, .user-dropdown a {
    padding: 0.5rem 5px 0 5px;
    font-size: 0.95rem !important;
    font-weight: 600;
    text-decoration: none;
    color: #1d7acb !important;
}


nav.navbar-custom a:hover.right-top-links {
    text-shadow: 0px 0px 1rem #999;
}

.navbar-custom .nav-item .nav-link:hover,
.navbar-custom .nav-item .nav-link.active {
    color: #00c9db;
}

/* Dropdown Menu */
.navbar-custom .dropdown:hover > .dropdown-menu {
    /* display: block; */ /* this makes the dropdown menu stay open while hovering it */
    /* min-width: auto; */
    /* animation: fadeDropdown 0.2s; */ /* required for the fade animation */
}

@-webkit-keyframes fadeDropdown {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeDropdown {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.navbar-custom .dropdown-toggle:focus { /* removes dropdown outline on focus  */
    outline: 0;
}

.navbar-custom .dropdown-menu {
    position: absolute;
    left: -60px;
    margin-top: 0;
    border: solid gray 1px;
    border-radius: 0.25rem;
}

.navbar-custom .dropdown-item {
    text-decoration: none;
}

.navbar-custom .dropdown-item:hover {
   /* background-color: #4633af; */
}

.navbar-custom .dropdown-item .item-text {
    font: 700 0.75rem/1.5rem "Montserrat", sans-serif;
}

.navbar-custom .dropdown-item:hover .item-text {
    color: #00c9db;
}

.navbar-custom .dropdown-items-divide-hr {
    width: 100%;
    height: 1px;
    margin: 0.25rem auto 0.25rem auto;
    border: none;
    background-color: #b5bcc4;
    opacity: 0.2;
}
/* end of dropdown menu */

.navbar-custom .social-icons {
    display: none;
}



.navbar-custom .navbar-toggler {
    border: none;
    color: #333;
    font-size: 2rem;
}
.navbar-custom.top-nav-collapse .navbar-toggler {
  color: #fff;
}


.navbar-custom button[aria-expanded='false'] .navbar-toggler-awesome.fas.fa-times{
    display: none;
}

.navbar-custom button[aria-expanded='false'] .navbar-toggler-awesome.fas.fa-bars{
    display: inline-block;
}

.navbar-custom button[aria-expanded='true'] .navbar-toggler-awesome.fas.fa-bars{
    display: none;
}

.navbar-custom button[aria-expanded='true'] .navbar-toggler-awesome.fas.fa-times{
    display: inline-block;
    margin-right: 0.125rem;
}

/*************************/
/*     04. AdmNavbar     */
/*************************/

/* Bootstrap Navbar Class equivalent*/
nav.navbar-adm {
    background-color: #fff;
    box-shadow: 0 0.0625rem 0.375rem 0 rgba(0, 0, 0, 0.1);
    font: 500 0.90rem/2rem "Montserrat", sans-serif;
    transition: all 0.2s;
    padding: 0 1rem;
}

nav.navbar-adm a.navbar-brand {
    /* color: #430064; */
    color: #333;
    text-decoration: none; 
    font-size: 2rem;
    padding: 0.8rem 0 0 0.2rem;
}

nav.navbar-adm a.navbar-brand span {
  text-decoration: none; 
  font-style: italic !important;
  font-size: 0.75rem;
}

nav.navbar-adm.scrolled-nav {
    /* background-color: #4633af; */
    background-color: rgba(255, 255, 255, 0.795) !important;
}

nav.navbar-adm a.nav-link {
    padding-top: 1.2rem;
    font-size: 1rem !important;
    color: #555 !important;
}
nav.navbar-adm a:hover.nav-link {
    text-shadow: 0px 0px 1rem #999;
}

nav.navbar-adm a.right-top-links {
    padding: 0.5rem 5px 0 5px;
    font-size: 0.9rem !important;
    text-decoration: none;
    color: #555 !important;
}
nav.navbar-adm a:hover.right-top-links {
    text-shadow: 0px 0px 1rem #999;
}


/*   Continue editing from here   */



.navbar-adm .nav-item .nav-link:hover,
.navbar-adm .nav-item .nav-link.active {
    color: #00c9db;
}

/* Dropdown Menu */
.navbar-adm .dropdown:hover > .dropdown-menu {
    /* display: block; */ /* this makes the dropdown menu stay open while hovering it */
    /* min-width: auto; */
    /* animation: fadeDropdown 0.2s; */ /* required for the fade animation */
}

.navbar-adm .dropdown-toggle:focus { /* removes dropdown outline on focus  */
    outline: 0;
}

.navbar-adm .dropdown-menu {
    position: absolute;
    left: -60px;
    margin-top: 0;
    border: solid gray 1px;
    border-radius: 0.25rem;
}

.navbar-adm .dropdown-item {
    text-decoration: none;
}
.navbar-adm .dropdown-item:hover {
   /* background-color: #4633af; */
}
.navbar-adm .dropdown-item .item-text {
    font: 700 0.75rem/1.5rem "Montserrat", sans-serif;
}
.navbar-adm .dropdown-item:hover .item-text {
    color: #00c9db;
}
.navbar-adm .dropdown-items-divide-hr {
    width: 100%;
    height: 1px;
    margin: 0.25rem auto 0.25rem auto;
    border: none;
    background-color: #b5bcc4;
    opacity: 0.2;
}
/* end of dropdown menu */

.navbar-adm .social-icons {
    display: none;
}
.navbar-adm .navbar-toggler {
    border: none;
    color: #333;
    font-size: 2rem;
}
.navbar-adm.top-nav-collapse .navbar-toggler {
  color: #fff;
}


.navbar-adm button[aria-expanded='false'] .navbar-toggler-awesome.fas.fa-times{
    display: none;
}

.navbar-adm button[aria-expanded='false'] .navbar-toggler-awesome.fas.fa-bars{
    display: inline-block;
}

.navbar-adm button[aria-expanded='true'] .navbar-toggler-awesome.fas.fa-bars{
    display: none;
}

.navbar-adm button[aria-expanded='true'] .navbar-toggler-awesome.fas.fa-times{
    display: inline-block;
    margin-right: 0.125rem;
}

/**************************/
/*       04. SideBar      */
/**************************/

aside.left-panel {
    display: table-cell;
    height: 100vh;
    min-height: 100%;
    vertical-align: top;
    width: 250px;
    transition: width 0.3s ease; 
    font: 400 0.75rem/2rem "Montserrat", sans-serif;
}
aside.left-panel .navbar {
    flex-direction: column;
    padding: 2rem 1.5rem;
    align-items: flex-start !important;
    font-size: 0.95rem;
}
aside.left-panel .navbar.navbar-expand .navbar-nav {
    flex-direction: column;
    width: 100%;
}
aside.left-panel .navbar.navbar-expand .navbar-nav .nav-link {
    padding: 0.2rem 0px;
}

aside.left-panel .navbar-nav .dropdown-menu {
    position: static;
    float: none;
}



aside.left-panel .navbar h3.menu-title {
    border-bottom: 1px solid #4e4e52;
    color: #9496a1;
    clear: both;
    display: block;
    font-size: 0.9rem;
    font-weight: 700;
    line-height: 50px;
    padding: 15px 0 0 0;
    text-transform: uppercase;
    width: 100%;
}

aside.left-panel .navbar span.navbar-brand a { 
    color: #9496a1;
    clear: both;
    display: block;
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
    width: 100%;
}

aside.left-panel .navbar span.navbar-brand a:hover { 
    color: #ddd;
    text-decoration: none;
}

aside.left-panel .navbar .navbar-nav div a.dropdown-toggle:after {
    display: none; 
}

aside.left-panel .navbar .navbar-nav div a:before {
    font-family: 'Font Awesome 5 Free';
    font-weight: 400;
    content: "\f105";

    color: #c8c9ce;
    position: absolute;
    top: 0.2rem;
    right: 0;
    text-align: right;
    transition: all .25s ease;
}

aside.left-panel .navbar .navbar-nav div.show a:before {
    font-family: 'Font Awesome 5 Free';
    font-weight: 400;
    content: "\f107";
    position:absolute;
    color:#ccc;
    top:0.2rem;
    right:0;
    font-weight:500;
}

/* Nav.Link */
aside.left-panel .navbar .navbar-nav a svg {
    color: #8b939b;
    margin-left: 5px;
    margin-right: 20px;
}

/* NavDropdown*/
aside.left-panel .navbar .navbar-nav div svg {
    color: #8b939b;
    margin-left: 5px;
    margin-right: 20px;
}



.right-panel {
    display: table-cell;
    width: 100%;
    padding-top: 1.5rem !important;
    padding-left: 2rem !important;
    transition: all .35s ease; 
}



/*!

 =========================================================
 * Light Bootstrap Dashboard React - v1.3.0
 * Based on Light Bootstrap Dashboard - v1.3.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/light-bootstrap-dashboard-react
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
/*      light colors         */

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg); } }

/*           Font Smoothing      */
body,
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6,
p,
.navbar,
.brand,
.btn-simple,
.alert,
a,
.td-name,
td,
button.close {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  /* font-family: 'Open Sans', sans-serif; */
  font-weight: 400; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
  text-transform: capitalize !important;
  font-weight: 400;
  margin: 1rem 0 0.5rem; 
}

h1, .h1 {
  font-size: 52px; 
  text-transform: uppercase;
}

h2, .h2 {
  font-size: 36px; }

h3, .h3 {
  font-size: 28px;
  margin: 20px 0 10px; }

h5, .h5 {
  text-transform: uppercase;
  margin-top: 0.5rem;
  font-size: 0.9rem;
  font-weight: 600 !important;
}

p {
  font-size: 16px;
  line-height: 1.5; }

h1 small, h2 small, h3 small, h4 small, h5 small, h6 small, .h1 small, .h2 small, .h3 small, .h4 small, .h5 small, .h6 small, h1 .small, h2 .small, h3 .small, h4 .small, h5 .small, h6 .small, .h1 .small, .h2 .small, .h3 .small, .h4 .small, .h5 .small, .h6 .small {
  color: #9A9A9A;
  font-weight: 300;
  line-height: 1.5; }

h1 small, h2 small, h3 small, h1 .small, h2 .small, h3 .small {
  font-size: 60%; }

h1 .subtitle {
  display: block;
  margin: 0 0 30px; }

.text-muted {
  color: #9A9A9A; }

.text-primary, .text-primary:hover {
  color: #1D62F0 !important; }

.text-info, .text-info:hover {
  color: #1DC7EA !important; }

.text-success, .text-success:hover {
  color: #87CB16 !important; }

.text-warning, .text-warning:hover {
  color: #FF9500 !important; }

.text-danger, .text-danger:hover {
  color: #FF4A55 !important; }

/*     General overwrite     */
body,
.wrapper {
  min-height: 100vh;
  position: relative;
  background-color: white; }

a {
  color: #1DC7EA; }
  a:hover, a:focus {
    color: #42d0ed;
    text-decoration: none; }

a:focus, a:active,
button::-moz-focus-inner,
input::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  outline: 0; }

.ui-slider-handle:focus,
input:focus {
  outline: 0 !important; }
.navbar-light .navbar-toggler {
    border-color: transparent;
}

/*           Animations              */
.form-control,
.input-group-addon,
.tagsinput,
.navbar,
.navbar .alert {
  transition: all 300ms linear; }

.sidebar .nav a,
.table > tbody > tr .td-actions .btn {
  transition: all 150ms ease-in; }



.btn {
  transition: all 100ms ease-in; }

.fa {
  width: 18px;
  text-align: center; }

.margin-top {
  margin-top: 50px; }

.wrapper {
  position: relative;
  top: 0;
  height: 100vh; }

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 225px;
  display: block;
  z-index: 1;
  color: #fff;
  font-weight: 200;
  background-size: cover;
  background-position: center center; }
  .sidebar .sidebar-wrapper {
    position: relative;
    height: calc(100vh - 75px);
    overflow: auto;
    width: 225px;
    z-index: 4;
    padding-bottom: 30px; }
  .sidebar .sidebar-background {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center; }
  .sidebar .logo {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    height: 60px;
    position: relative;
    z-index: 4; 
  }
    .sidebar .logo p {
      float: left;
      font-size: 20px;
      margin: 10px 10px;
      color: #FFFFFF;
      line-height: 20px;
      font-family: 'Open Sans', sans-serif;
  }
    .sidebar .logo a.logo-mini {
      float: left;
      text-align: center;
      width: 30px;
      margin-right: 15px; }
      .sidebar .logo a.logo-mini img {
        height: 40px;
        display: block; 
      }
    .sidebar .logo a.logo-normal {
      display: block; 
      font-size: 28px;
      padding-top: 15px;
    }
    .sidebar .logo a.logo-normal span {
      font-style: italic;
      font-weight: 500;
      font-size: 10px;
    }
    .sidebar .logo .logo-img {
      display: inline-block;
      padding: 0;
      margin: 10px 0px;
      text-align: center; 
    }
  .sidebar .logo-tim {
    border-radius: 50%;
    border: 1px solid #333;
    display: block;
    height: 61px;
    width: 61px;
    float: left;
    overflow: hidden; }
    .sidebar .logo-tim img {
      width: 60px;
      height: 60px; }
  .sidebar .nav {
    margin-top: 15px; 
  }
    .sidebar .nav a {
      color: #FFFFFF;
      margin: 0px 5px;
      opacity: .86;
      border-radius: 2px; }
    .sidebar .nav li:hover > a,
    .sidebar .nav li.open > a,
    .sidebar .nav li.open > a:focus,
    .sidebar .nav li.open > a:hover {
      background: rgba(255, 255, 255, 0.13);
      opacity: 1; }
.sidebar .nav li.active > a {
    color: #FFFFFF;
    opacity: 1;
    background: rgba(255, 255, 255, 0.23); 
}
.sidebar .nav p {
    margin: 0;
    display: inline;
    line-height: 30px;
    font-size: 12px;
    font-weight: 600;
    text-transform: capitalize; 
}
.sidebar .nav .caret {
    top: 24px;
    position: absolute;
    right: 15px; 
}
.sidebar .nav i {
    font-size: 26px;
    float: left;
    margin-right: 15px;
    line-height: 30px;
    width: 30px;
    text-align: center; 
}

.sidebar .logo,
body > .navbar-collapse .logo {
  padding: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2); 
}
  .sidebar .logo p,
  body > .navbar-collapse .logo p {
    float: left;
    font-size: 20px;
    margin: 10px 10px;
    color: #FFFFFF;
    line-height: 20px;
    font-family: 'Open Sans', sans-serif;
  }


.sidebar .logo-tim,
body > .navbar-collapse .logo-tim {
  border-radius: 50%;
  border: 1px solid #333;
  display: block;
  height: 61px;
  width: 61px;
  float: left;
  overflow: hidden; }
  .sidebar .logo-tim img,
  body > .navbar-collapse .logo-tim img {
    width: 60px;
    height: 60px; }

.sidebar:after, .sidebar:before,
body > .navbar-collapse:after,
body > .navbar-collapse:before {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2; }

.sidebar:before,
body > .navbar-collapse:before {
  opacity: .33;
  background: #000000; }

.sidebar:after,
body > .navbar-collapse:after {
  background: #282828;
  background: linear-gradient(to bottom, #282828 0%, #111 100%);
  background-size: 150% 150%;
  z-index: 3;
  opacity: 1; }

.sidebar[data-image]:after, .sidebar.has-image:after,
body > .navbar-collapse[data-image]:after,
body > .navbar-collapse.has-image:after {
  opacity: .77; }

.sidebar[data-color="blue"]:after,
body > .navbar-collapse[data-color="blue"]:after {
  background: #1b8dff;
  background: linear-gradient(to bottom, #1b8dff 0%, #2632c1 100%);
  background-size: 150% 150%; }

.sidebar[data-color="azure"]:after,
body > .navbar-collapse[data-color="azure"]:after {
  background: #1DC7EA;
  background: linear-gradient(to bottom, #1DC7EA 0%, #4091ff 100%);
  background-size: 150% 150%; }

.sidebar[data-color="green"]:after,
body > .navbar-collapse[data-color="green"]:after {
  background: #87CB16;
  background: linear-gradient(to bottom, #87CB16 0%, #6dc030 100%);
  background-size: 150% 150%; }

.sidebar[data-color="orange"]:after,
body > .navbar-collapse[data-color="orange"]:after {
  background: #FFA534;
  background: linear-gradient(to bottom, #FFA534 0%, #ff5221 100%);
  background-size: 150% 150%; }

.sidebar[data-color="red"]:after,
body > .navbar-collapse[data-color="red"]:after {
  background: #FB404B;
  background: linear-gradient(to bottom, #FB404B 0%, #bb0502 100%);
  background-size: 150% 150%; }

.sidebar[data-color="purple"]:after,
body > .navbar-collapse[data-color="purple"]:after {
  background: #9368E9;
  background: linear-gradient(to bottom, #9368E9 0%, #943bea 100%);
  background-size: 150% 150%; }



.btn {
  border-width: 1px;
  background-color: transparent;
  font-weight: 400;
  filter: alpha(opacity=80);
  padding: 8px 16px;
}
  .btn:hover, .btn:focus, .btn:active, .btn.active,
  .open > .btn.dropdown-toggle {
    background-color: transparent;
    color: #777777;
    border-color: #777777; }

  .dropdown .dropdown-toggle > div {
    display: inline;
  }
  .btn.disabled, .btn.disabled:hover, .btn.disabled:focus, .btn.disabled.focus, .btn.disabled:active, .btn.disabled.active, .btn:disabled, .btn:disabled:hover, .btn:disabled:focus, .btn:disabled.focus, .btn:disabled:active, .btn:disabled.active, .btn[disabled], .btn[disabled]:hover, .btn[disabled]:focus, .btn[disabled].focus, .btn[disabled]:active, .btn[disabled].active,
  fieldset[disabled] .btn,
  fieldset[disabled] .btn:hover,
  fieldset[disabled] .btn:focus,
  fieldset[disabled] .btn.focus,
  fieldset[disabled] .btn:active,
  fieldset[disabled] .btn.active {
    background-color: transparent;
    border-color: #888888; }
  .btn.btn-fill {
    color: #FFFFFF;
    background-color: #888888;
    opacity: 1;
    filter: alpha(opacity=100); }
    .btn.btn-fill:hover, .btn.btn-fill:focus, .btn.btn-fill:active, .btn.btn-fill.active,
    .open > .btn.btn-fill.dropdown-toggle {
      background-color: #777777;
      color: #FFFFFF; }
    .btn.btn-fill .caret {
      border-top-color: #FFFFFF; }
  .btn .caret {
    border-top-color: #888888; }
  .btn:hover, .btn:focus {
    opacity: 1;
    filter: alpha(opacity=100);
    outline: 0 !important; }
  .btn:active, .btn.active,
  .open > .btn.dropdown-toggle {
    box-shadow: none;
    outline: 0 !important; }
  .btn.btn-icon {
    padding: 8px; }

.btn-primary {
  background-color: #3472F7;
  color: white; 
}
.btn-secondary {
  background-color: #6c757d;
  color: white; 
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active,
.open > .btn-primary.dropdown-toggle {
  background-color: transparent;
  color: #1D62F0;
  border-color: #1D62F0; 
}
  .btn-primary.disabled, .btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled.focus, .btn-primary.disabled:active, .btn-primary.disabled.active, .btn-primary:disabled, .btn-primary:disabled:hover, .btn-primary:disabled:focus, .btn-primary:disabled.focus, .btn-primary:disabled:active, .btn-primary:disabled.active, .btn-primary[disabled], .btn-primary[disabled]:hover, .btn-primary[disabled]:focus, .btn-primary[disabled].focus, .btn-primary[disabled]:active, .btn-primary[disabled].active,
  fieldset[disabled] .btn-primary,
  fieldset[disabled] .btn-primary:hover,
  fieldset[disabled] .btn-primary:focus,
  fieldset[disabled] .btn-primary.focus,
  fieldset[disabled] .btn-primary:active,
  fieldset[disabled] .btn-primary.active {
    background-color: transparent;
    border-color: #3472F7; }
  .btn-primary.btn-fill {
    color: #FFFFFF;
    background-color: #3472F7;
    opacity: 1;
    filter: alpha(opacity=100); }
    .btn-primary.btn-fill:hover, .btn-primary.btn-fill:focus, .btn-primary.btn-fill:active, .btn-primary.btn-fill.active,
    .open > .btn-primary.btn-fill.dropdown-toggle {
      background-color: #1D62F0;
      color: #FFFFFF; }
    .btn-primary.btn-fill .caret {
      border-top-color: #FFFFFF; }
  .btn-primary .caret {
    border-top-color: #3472F7; }

.btn-success {
  background-color: #87CB16;
  border-color: #87CB16;
  color: #FFFFFF; }
  .btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active,
  .open > .btn-success.dropdown-toggle {
    opacity: 0.8;
    background-color: #87CB16;
    color: #FFFFFF;
    border-color: #049F0C; }
  .btn-success.disabled, .btn-success.disabled:hover, .btn-success.disabled:focus, .btn-success.disabled.focus, .btn-success.disabled:active, .btn-success.disabled.active, .btn-success:disabled, .btn-success:disabled:hover, .btn-success:disabled:focus, .btn-success:disabled.focus, .btn-success:disabled:active, .btn-success:disabled.active, .btn-success[disabled], .btn-success[disabled]:hover, .btn-success[disabled]:focus, .btn-success[disabled].focus, .btn-success[disabled]:active, .btn-success[disabled].active,
  fieldset[disabled] .btn-success,
  fieldset[disabled] .btn-success:hover,
  fieldset[disabled] .btn-success:focus,
  fieldset[disabled] .btn-success.focus,
  fieldset[disabled] .btn-success:active,
  fieldset[disabled] .btn-success.active {
    background-color: #87CB16;
    border-color: #87CB16; }
  .btn-success.btn-fill {
    color: #FFFFFF;
    background-color: #87CB16;
    opacity: 1;
    filter: alpha(opacity=100); }
    .btn-success.btn-fill:hover, .btn-success.btn-fill:focus, .btn-success.btn-fill:active, .btn-success.btn-fill.active,
    .open > .btn-success.btn-fill.dropdown-toggle {
      background-color: #049F0C;
      color: #FFFFFF; }
    .btn-success.btn-fill .caret {
      border-top-color: #FFFFFF; }
  .btn-success .caret {
    border-top-color: #87CB16; }

.btn-info {
  background-color: #42d0ed;
  border-color: #1DC7EA;
  color: #FFFFFF; }
  .btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active,
  .open > .btn-info.dropdown-toggle {
    opacity: 0.8;
    background-color: #42d0ed;
    color: #FFFFFF;
    border-color: rgb(14, 119, 139); }
  .btn-info.disabled, .btn-info.disabled:hover, .btn-info.disabled:focus, .btn-info.disabled.focus, .btn-info.disabled:active, .btn-info.disabled.active, .btn-info:disabled, .btn-info:disabled:hover, .btn-info:disabled:focus, .btn-info:disabled.focus, .btn-info:disabled:active, .btn-info:disabled.active, .btn-info[disabled], .btn-info[disabled]:hover, .btn-info[disabled]:focus, .btn-info[disabled].focus, .btn-info[disabled]:active, .btn-info[disabled].active,
  fieldset[disabled] .btn-info,
  fieldset[disabled] .btn-info:hover,
  fieldset[disabled] .btn-info:focus,
  fieldset[disabled] .btn-info.focus,
  fieldset[disabled] .btn-info:active,
  fieldset[disabled] .btn-info.active {
    background-color: #42d0ed;
    border-color: rgb(14, 119, 139);; }
  .btn-info.btn-fill {
    color: #FFFFFF;
    background-color: rgb(14, 119, 139);;
    opacity: 1;
    filter: alpha(opacity=100); }
    .btn-info.btn-fill:hover, .btn-info.btn-fill:focus, .btn-info.btn-fill:active, .btn-info.btn-fill.active,
    .open > .btn-info.btn-fill.dropdown-toggle {

      background-color: rgb(14, 119, 139);;
      color: #FFFFFF; }
    .btn-info.btn-fill .caret {
      border-top-color: #FFFFFF; }
  .btn-info .caret {
    border-top-color: rgb(14, 119, 139); }

.btn-warning {
  border-color: #FF9500;
  color: #FF9500; }
  .btn-warning:hover, .btn-warning:focus, .btn-warning:active, .btn-warning.active,
  .open > .btn-warning.dropdown-toggle {
    background-color: transparent;
    color: #ED8D00;
    border-color: #ED8D00; }
  .btn-warning.disabled, .btn-warning.disabled:hover, .btn-warning.disabled:focus, .btn-warning.disabled.focus, .btn-warning.disabled:active, .btn-warning.disabled.active, .btn-warning:disabled, .btn-warning:disabled:hover, .btn-warning:disabled:focus, .btn-warning:disabled.focus, .btn-warning:disabled:active, .btn-warning:disabled.active, .btn-warning[disabled], .btn-warning[disabled]:hover, .btn-warning[disabled]:focus, .btn-warning[disabled].focus, .btn-warning[disabled]:active, .btn-warning[disabled].active,
  fieldset[disabled] .btn-warning,
  fieldset[disabled] .btn-warning:hover,
  fieldset[disabled] .btn-warning:focus,
  fieldset[disabled] .btn-warning.focus,
  fieldset[disabled] .btn-warning:active,
  fieldset[disabled] .btn-warning.active {
    background-color: transparent;
    border-color: #FF9500; }
  .btn-warning.btn-fill {
    color: #FFFFFF;
    background-color: #FF9500;
    opacity: 1;
    filter: alpha(opacity=100); }
    .btn-warning.btn-fill:hover, .btn-warning.btn-fill:focus, .btn-warning.btn-fill:active, .btn-warning.btn-fill.active,
    .open > .btn-warning.btn-fill.dropdown-toggle {
      background-color: #ED8D00;
      color: #FFFFFF; }
    .btn-warning.btn-fill .caret {
      border-top-color: #FFFFFF; }
  .btn-warning .caret {
    border-top-color: #FF9500; }

.btn-danger {
  background-color: #EE2D20;
  border-color: #EE2D20;
  color: #FFFFFF; 
}
  .btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger.active,
  .open > .btn-danger.dropdown-toggle {
    opacity: 0.8;
    background-color: #EE2D20;
    color: #FFFFFF;
    border-color: #EE2D20; }
  .btn-danger.disabled, .btn-danger.disabled:hover, .btn-danger.disabled:focus, .btn-danger.disabled.focus, .btn-danger.disabled:active, .btn-danger.disabled.active, .btn-danger:disabled, .btn-danger:disabled:hover, .btn-danger:disabled:focus, .btn-danger:disabled.focus, .btn-danger:disabled:active, .btn-danger:disabled.active, .btn-danger[disabled], .btn-danger[disabled]:hover, .btn-danger[disabled]:focus, .btn-danger[disabled].focus, .btn-danger[disabled]:active, .btn-danger[disabled].active,
  fieldset[disabled] .btn-danger,
  fieldset[disabled] .btn-danger:hover,
  fieldset[disabled] .btn-danger:focus,
  fieldset[disabled] .btn-danger.focus,
  fieldset[disabled] .btn-danger:active,
  fieldset[disabled] .btn-danger.active {
    background-color: #EE2D20;
    border-color: #EE2D20; }
  .btn-danger.btn-fill {
    color: #FFFFFF;
    background-color: #EE2D20;
    opacity: 1;
    filter: alpha(opacity=100); }
    .btn-danger.btn-fill:hover, .btn-danger.btn-fill:focus, .btn-danger.btn-fill:active, .btn-danger.btn-fill.active,
    .open > .btn-danger.btn-fill.dropdown-toggle {
      background-color: #EE2D20;
      color: #FFFFFF; }
    .btn-danger.btn-fill .caret {
      border-top-color: #FFFFFF; }
  .btn-danger .caret {
    border-top-color: #EE2D20; }

  /*   #EE2D20;#FF4A55 */

.btn-neutral {
  border-color: #FFFFFF;
  color: #FFFFFF; }
  .btn-neutral:hover, .btn-neutral:focus, .btn-neutral:active, .btn-neutral.active,
  .open > .btn-neutral.dropdown-toggle {
    background-color: transparent;
    color: #FFFFFF;
    border-color: #FFFFFF; }
  .btn-neutral.disabled, .btn-neutral.disabled:hover, .btn-neutral.disabled:focus, .btn-neutral.disabled.focus, .btn-neutral.disabled:active, .btn-neutral.disabled.active, .btn-neutral:disabled, .btn-neutral:disabled:hover, .btn-neutral:disabled:focus, .btn-neutral:disabled.focus, .btn-neutral:disabled:active, .btn-neutral:disabled.active, .btn-neutral[disabled], .btn-neutral[disabled]:hover, .btn-neutral[disabled]:focus, .btn-neutral[disabled].focus, .btn-neutral[disabled]:active, .btn-neutral[disabled].active,
  fieldset[disabled] .btn-neutral,
  fieldset[disabled] .btn-neutral:hover,
  fieldset[disabled] .btn-neutral:focus,
  fieldset[disabled] .btn-neutral.focus,
  fieldset[disabled] .btn-neutral:active,
  fieldset[disabled] .btn-neutral.active {
    background-color: transparent;
    border-color: #FFFFFF; }
  .btn-neutral.btn-fill {
    color: #FFFFFF;
    background-color: #FFFFFF;
    opacity: 1;
    filter: alpha(opacity=100); }
    .btn-neutral.btn-fill:hover, .btn-neutral.btn-fill:focus, .btn-neutral.btn-fill:active, .btn-neutral.btn-fill.active,
    .open > .btn-neutral.btn-fill.dropdown-toggle {
      background-color: #FFFFFF;
      color: #FFFFFF; }
    .btn-neutral.btn-fill .caret {
      border-top-color: #FFFFFF; }
  .btn-neutral .caret {
    border-top-color: #FFFFFF; }
  .btn-neutral:active, .btn-neutral.active,
  .open > .btn-neutral.dropdown-toggle {
    background-color: #FFFFFF;
    color: #888888; }
  .btn-neutral.btn-fill, .btn-neutral.btn-fill:hover, .btn-neutral.btn-fill:focus {
    color: #888888; }
  .btn-neutral.btn-simple:active, .btn-neutral.btn-simple.active {
    background-color: transparent; }

.btn:disabled, .btn[disabled], .btn.disabled {
  opacity: 0.5;
  filter: alpha(opacity=50); }

.btn-round {
  border-width: 1px;
  border-radius: 30px !important;
  padding: 9px 18px; }
  .btn-round.btn-icon {
    padding: 9px; }

.btn-simple {
  border: 0;
  font-size: 16px;
  padding: 8px 16px; }
  .btn-simple.btn-icon {
    padding: 8px; }

.btn-lg {
  font-size: 18px;
  border-radius: 6px;
  padding: 14px 30px;
  font-weight: 400; }
  .btn-lg.btn-round {
    padding: 15px 30px; }
  .btn-lg.btn-simple {
    padding: 16px 30px; }

.btn-sm {
  font-size: 12px;
  border-radius: 3px;
  padding: 5px 10px; }
  .btn-sm.btn-round {
    padding: 6px 10px; }
  .btn-sm.btn-simple {
    padding: 7px 10px; }

.btn-xs {
  font-size: 12px;
  border-radius: 3px;
  padding: 1px 5px; }
  .btn-xs.btn-round {
    padding: 2px 5px; }
  .btn-xs.btn-simple {
    padding: 3px 5px; }

.btn-wd {
  min-width: 140px; }

.btn-group.select {
  width: 100%; }

.btn-group.select .btn {
  text-align: left; }

.btn-group.select .caret {
  position: absolute;
  top: 50%;
  margin-top: -1px;
  right: 8px; }

.form-control::-moz-placeholder {
  color: #DDDDDD;
  opacity: 1;
  filter: alpha(opacity=100); }

.form-control:-moz-placeholder {
  color: #DDDDDD;
  opacity: 1;
  filter: alpha(opacity=100); }

.form-control::-webkit-input-placeholder {
  color: #DDDDDD;
  opacity: 1;
  filter: alpha(opacity=100); }

.form-control:-ms-input-placeholder {
  color: #DDDDDD;
  opacity: 1;
  filter: alpha(opacity=100); }

.form-control {
  background-color: #FFFFFF;
  border: 1px solid #E3E3E3;
  border-radius: 2px;
  color: rgb(0, 92, 179);
  font-weight: 600;
  padding: 5px 10px;
  height: 30px;
  line-height: 1.2;
  box-shadow: none; 
}
.form-control:focus {
  background-color: #FFFFFF;
  border: 1px solid #AAAAAA;
  box-shadow: none;
  outline: 0 !important;
  color: #333333; 
}
  .has-success .form-control,
  .has-error .form-control,
  .has-success .form-control:focus,
  .has-error .form-control:focus {
    border-color: #E3E3E3;
    box-shadow: none; }
  .has-success .form-control {
    color: #87CB16; }
  .has-success .form-control:focus {
    border-color: #87CB16; }
  .has-error .form-control {
    color: #FF4A55; }
  .has-error .form-control:focus {
    border-color: #FF4A55; }
  .form-control + .form-control-feedback {
    border-radius: 2px;
    font-size: 12px;
    margin-top: -7px;
    position: absolute;
    right: 10px;
    top: 50%;
    vertical-align: middle; }
  .open .form-control {
    border-radius: 2px 2px 0 0;
    border-bottom-color: transparent; }

.input-lg {
  height: 55px;
  padding: 14px 30px; }

.has-error .form-control-feedback {
  color: #FF4A55; }

.has-success .form-control-feedback {
  color: #87CB16; }

.input-group-addon {
  background-color: #FFFFFF;
  border: 1px solid #E3E3E3;
  border-radius: 2px; }
  .has-success .input-group-addon,
  .has-error .input-group-addon {
    background-color: #FFFFFF;
    border: 1px solid #E3E3E3; }
  .has-error .form-control:focus + .input-group-addon {
    border-color: #FF4A55;
    color: #FF4A55; }
  .has-success .form-control:focus + .input-group-addon {
    border-color: #87CB16;
    color: #87CB16; }
  .form-control:focus + .input-group-addon,
  .form-control:focus ~ .input-group-addon {
    background-color: #FFFFFF;
    border-color: #9A9A9A; }

.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
  border-right: 0 none; }

.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child) {
  border-left: 0 none; }

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
  background-color: #F5F5F5;
  color: #333;
  cursor: not-allowed; }

.input-group-btn .btn {
  border-width: 1px;
  padding: 9px 16px; }

.input-group-btn .btn-default:not(.btn-fill) {
  border-color: #DDDDDD; }

.input-group-btn:last-child > .btn {
  margin-left: 0; }

.input-group-focus .input-group-addon {
  border-color: #9A9A9A; }

.alert {
  border: 0;
  border-radius: 0;
  padding: 10px 15px;
  font-size: 1rem;
  font-weight: 500; 
}
.container .alert {
    border-radius: 2px; }
.navbar .alert {
    border-radius: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 85px;
    width: 100%;
    z-index: 3; }
  .navbar:not(.navbar-transparent) .alert {
    top: 70px; }
.alert-info {
  background-color: #63d8f1; }

.alert-success {
  background-color: #5ee79c; }

.alert-warning {
  background-color: #ffbc67; }

.alert-danger {
  background-color: #fc727a; }

.table .radio,
.table .checkbox {
  position: relative;
  height: 20px;
  display: block;
  width: 20px;
  padding: 0px 0px;
  margin: 0px 5px;
  text-align: center; }
  .table .radio .icons,
  .table .checkbox .icons {
    left: 5px; }

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 6px 5px;
  vertical-align: middle; 
}

.table > tbody > tr > td {
  font-size: 0.8rem; 
}

.table > thead > tr > th {
  border-bottom-width: 1px;
  font-size: 12px;
  text-transform: uppercase;
  color: #999;
  font-weight: 700;
  padding-bottom: 5px; }

.table .td-actions .btn {
  opacity: 0.36;
  filter: alpha(opacity=36); }
  .table .td-actions .btn.btn-xs {
    padding-left: 3px;
    padding-right: 3px; }

.table .td-actions {
  min-width: 90px; }

.table > tbody > tr {
  position: relative; }
.table > tbody > tr:hover .td-actions .btn {
    opacity: 1;
  filter: alpha(opacity=100); 
}

.table > tbody > tr:hover {
    background-color: #f5f5f5;
  }

/*      Checkbox and radio         */
.checkbox,
.radio {
  margin-bottom: 12px; }

.checkbox label,
.radio label {
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding-left: 24px;
  margin-bottom: 0; }

.checkbox label::before,
.checkbox label::after {
  font-family: 'FontAwesome';
  content: "\f096";
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  cursor: pointer;
  line-height: 19px;
  font-size: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  top: 1px;
  color: #DDDDDD;
  transition: color 0.2s linear;
  padding: 1px; }

.checkbox label::after {
  content: "";
  text-align: center;
  opacity: 1;
  left: 1px;
  color: #DDDDDD; }

.checkbox input[type="checkbox"],
.radio input[type="radio"] {
  opacity: 0;
  margin-left: 0; }

.checkbox input[type="checkbox"]:checked + label::after {
  font-family: 'FontAwesome';
  content: "\f046"; }

.checkbox input[type="checkbox"]:checked + label::after {
  color: #1DC7EA; }

.checkbox input[type="checkbox"]:checked + label::before {
  opacity: 0; }

.checkbox input[type="checkbox"]:disabled + label,
.radio input[type="radio"]:disabled + label,
.checkbox input[type="checkbox"]:disabled:checked + label::after {
  color: #DDDDDD; }

.checkbox input[type="checkbox"]:disabled + label::before,
.checkbox input[type="checkbox"]:disabled + label::after {
  cursor: not-allowed; }

.checkbox input[type="checkbox"]:disabled + label,
.radio input[type="radio"]:disabled + label {
  cursor: not-allowed; }

.checkbox.checkbox-circle label::before {
  border-radius: 50%; }

.checkbox.checkbox-inline {
  padding-left: 0; }

.checkbox-primary input[type="checkbox"]:checked + label::before {
  background-color: #428bca;
  border-color: #428bca; }

.checkbox-primary input[type="checkbox"]:checked + label::after {
  color: #fff; }

.checkbox-danger input[type="checkbox"]:checked + label::before {
  background-color: #d9534f;
  border-color: #d9534f; }

.checkbox-danger input[type="checkbox"]:checked + label::after {
  color: #fff; }

.checkbox-info input[type="checkbox"]:checked + label::before {
  background-color: #5bc0de;
  border-color: #5bc0de; }

.checkbox-info input[type="checkbox"]:checked + label::after {
  color: #fff; }

.checkbox-warning input[type="checkbox"]:checked + label::before {
  background-color: #f0ad4e;
  border-color: #f0ad4e; }

.checkbox-warning input[type="checkbox"]:checked + label::after {
  color: #fff; }

.checkbox-success input[type="checkbox"]:checked + label::before {
  background-color: #5cb85c;
  border-color: #5cb85c; }

.checkbox-success input[type="checkbox"]:checked + label::after {
  color: #fff; }

.radio label::before,
.radio label::after {
  font-family: 'FontAwesome';
  content: "\f10c";
  font-size: 20px;
  height: 20px;
  width: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  position: absolute;
  line-height: 19px;
  left: 0;
  top: 0;
  color: #DDDDDD;
  padding: 1px;
  transition: color 0.2s linear; }

.radio input[type="radio"]:checked + label::after {
  font-family: 'FontAwesome';
  content: "\f192";
  color: #DDDDDD; }

.radio input[type="radio"]:checked + label::after {
  color: #1DC7EA; }

.radio input[type="radio"]:disabled + label {
  color: #ddd; }

.radio input[type="radio"]:disabled + label::before,
.radio input[type="radio"]:disabled + label::after {
  color: #ddd; }

.radio.radio-inline {
  margin-top: 0; }

/**
  * bootstrap-switch - Turn checkboxes and radio buttons into toggle switches.
  *
  * @version v3.3.4
  * @homepage https://bttstrp.github.io/bootstrap-switch
  * @author Mattia Larentis <mattia@larentis.eu> (http://larentis.eu)
  * @license Apache-2.0
  */
.bootstrap-switch {
  display: inline-block;
  direction: ltr;
  cursor: pointer;
  border-radius: 2px;
  border: 1px solid;
  border-color: #ccc;
  position: relative;
  text-align: left;
  overflow: hidden;
  line-height: 8px;
  z-index: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s; }

.bootstrap-switch .bootstrap-switch-container {
  display: inline-block;
  top: 0;
  border-radius: 2px;
  transform: translate3d(0, 0, 0); }

.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off,
.bootstrap-switch .bootstrap-switch-label {
  box-sizing: border-box;
  cursor: pointer;
  display: table-cell;
  vertical-align: middle;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 20px; }

.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off {
  text-align: center;
  z-index: 1; }

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-primary {
  color: #fff;
  background: #337ab7; }

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-info,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-info {
  color: #fff;
  background: #5bc0de; }

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-success,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-success {
  color: #fff;
  background: #5cb85c; }

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-warning,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-warning {
  background: #f0ad4e;
  color: #fff; }

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-danger,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-danger {
  color: #fff;
  background: #d9534f; }

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-default,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default {
  color: #000;
  background: #eeeeee; }

.bootstrap-switch .bootstrap-switch-label {
  text-align: center;
  margin-top: -1px;
  margin-bottom: -1px;
  z-index: 100;
  color: #333;
  background: #fff; }

.bootstrap-switch span::before {
  content: "\200b"; }

.bootstrap-switch .bootstrap-switch-handle-on {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px; }

.bootstrap-switch .bootstrap-switch-handle-off {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px; }

.bootstrap-switch input[type='radio'],
.bootstrap-switch input[type='checkbox'] {
  position: absolute !important;
  top: 0;
  left: 0;
  margin: 0;
  z-index: -1;
  opacity: 0;
  filter: alpha(opacity=0);
  visibility: hidden; }

.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-label {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5; }

.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-label {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5; }

.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-label {
  padding: 6px 16px;
  font-size: 18px;
  line-height: 1.3333333; }

.bootstrap-switch.bootstrap-switch-disabled,
.bootstrap-switch.bootstrap-switch-readonly,
.bootstrap-switch.bootstrap-switch-indeterminate {
  cursor: default !important; }

.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-label {
  opacity: 0.5;
  filter: alpha(opacity=50);
  cursor: default !important; }

.bootstrap-switch.bootstrap-switch-animate .bootstrap-switch-container {
  transition: margin-left 0.5s; }

.bootstrap-switch.bootstrap-switch-inverse .bootstrap-switch-handle-on {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px; }

.bootstrap-switch.bootstrap-switch-inverse .bootstrap-switch-handle-off {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px; }

.bootstrap-switch.bootstrap-switch-focused {
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-inverse.bootstrap-switch-off .bootstrap-switch-label {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px; }

.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-inverse.bootstrap-switch-on .bootstrap-switch-label {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px; }

.nav > a:hover,
.nav > a:focus {
  background-color: transparent; }

.navbar {
  border: 0;
  font-size: 0.95rem;
  border-radius: 0; 
  padding: 0 10px;
}
.navbar .navbar-brand {
  height: 50px;
  font-weight: 400;
  margin: 5px 0px;
  padding: 10px 15px;
  font-size: 1.2rem; 
  color: #777 !important;
}
.navbar .navbar-brand > a{ 
  color: #777 !important;
}
.navbar-default .navbar-nav {
  display: inline-flex;
  }

.navbar .navbar-nav > a {
  padding: 10px 15px;
  margin: 0 3px;
  position: relative; 
}
.navbar .navbar-nav > a.btn {
  margin: 15px 3px;
  padding: .1rem .5rem; }
.navbar .navbar-nav > a.btn-round {
  margin: 16px 3px; }
.navbar .navbar-nav > a [class^="fa"] {
  font-size: 19px;
  position: relative;
  line-height: 16px;
  top: 1px; }
.navbar .navbar-nav .notification {
  position: absolute;
  background-color: #FB404B;
  text-align: center;
  border-radius: 10px;
  min-width: 18px;
  padding: 0 5px;
  height: 18px;
  font-size: 12px;
  color: #FFFFFF;
  font-weight: bold;
  line-height: 18px;
  top: 0px;
  left: 7px; }
.navbar .btn {
  margin: 15px 3px;
  font-size: 14px; }
.navbar .btn-simple {
  font-size: 16px; }
.navbar.fixed {
  width: calc(100% - 225px);
  right: 0;
  left: auto;
  border-radius: 0; }

.navbar-nav > li > .dropdown-menu {
  border-radius: 5px;
  margin-top: -5px; }

.navbar-transparent .navbar-brand, [class*="navbar-ct"] .navbar-brand {
  color: #FFFFFF;
  opacity: 0.9;
  filter: alpha(opacity=90); }
  .navbar-transparent .navbar-brand:focus, .navbar-transparent .navbar-brand:hover, [class*="navbar-ct"] .navbar-brand:focus, [class*="navbar-ct"] .navbar-brand:hover {
    background-color: transparent;
    opacity: 1;
    filter: alpha(opacity=100); }

.navbar-transparent .navbar-nav > a:not(.btn), [class*="navbar-ct"] .navbar-nav > a:not(.btn) {
  color: #FFFFFF;
  border-color: #FFFFFF;
  opacity: 0.8;
  filter: alpha(opacity=80); }

.navbar-transparent .navbar-nav > .active > a:not(.btn),
.navbar-transparent .navbar-nav > .active > a:hover:not(.btn),
.navbar-transparent .navbar-nav > .active > a:focus:not(.btn),
.navbar-transparent .navbar-nav > a:hover:not(.btn),
.navbar-transparent .navbar-nav > a:focus:not(.btn), [class*="navbar-ct"] .navbar-nav > .active > a:not(.btn),
[class*="navbar-ct"] .navbar-nav > .active > a:hover:not(.btn),
[class*="navbar-ct"] .navbar-nav > .active > a:focus:not(.btn),
[class*="navbar-ct"] .navbar-nav > a:hover:not(.btn),
[class*="navbar-ct"] .navbar-nav > a:focus:not(.btn) {
  background-color: transparent;
  border-radius: 3px;
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100); }

.navbar-transparent .navbar-nav .nav > a.btn:hover, [class*="navbar-ct"] .navbar-nav .nav > a.btn:hover {
  background-color: transparent; }

.navbar-transparent .navbar-nav > .dropdown > a .caret,
.navbar-transparent .navbar-nav > .dropdown > a:hover .caret,
.navbar-transparent .navbar-nav > .dropdown > a:focus .caret, [class*="navbar-ct"] .navbar-nav > .dropdown > a .caret,
[class*="navbar-ct"] .navbar-nav > .dropdown > a:hover .caret,
[class*="navbar-ct"] .navbar-nav > .dropdown > a:focus .caret {
  border-bottom-color: #FFFFFF;
  border-top-color: #FFFFFF; }

.navbar-transparent .navbar-nav > .open > a,
.navbar-transparent .navbar-nav > .open > a:hover,
.navbar-transparent .navbar-nav > .open > a:focus, [class*="navbar-ct"] .navbar-nav > .open > a,
[class*="navbar-ct"] .navbar-nav > .open > a:hover,
[class*="navbar-ct"] .navbar-nav > .open > a:focus {
  background-color: transparent;
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100); }

.navbar-transparent .btn-default, [class*="navbar-ct"] .btn-default {
  color: #FFFFFF;
  border-color: #FFFFFF; }

.navbar-transparent .btn-default.btn-fill, [class*="navbar-ct"] .btn-default.btn-fill {
  color: #9A9A9A;
  background-color: #FFFFFF;
  opacity: 0.9;
  filter: alpha(opacity=90); }

.navbar-transparent .btn-default.btn-fill:hover,
.navbar-transparent .btn-default.btn-fill:focus,
.navbar-transparent .btn-default.btn-fill:active,
.navbar-transparent .btn-default.btn-fill.active,
.navbar-transparent .open .dropdown-toggle.btn-fill.btn-default, [class*="navbar-ct"] .btn-default.btn-fill:hover,
[class*="navbar-ct"] .btn-default.btn-fill:focus,
[class*="navbar-ct"] .btn-default.btn-fill:active,
[class*="navbar-ct"] .btn-default.btn-fill.active,
[class*="navbar-ct"] .open .dropdown-toggle.btn-fill.btn-default {
  border-color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100); }

.navbar-transparent .dropdown-menu .divider {
  background-color: rgba(255, 255, 255, 0.2); 
}

.nav-link {
  padding: 10px 1rem;
}

.sidebar .nav a.dropdown-item {
    color: #333;
    margin: 0px 35px;
    opacity: .86;
    border-radius: 2px;
}
.navbar-default {
  background-color: rgba(255, 255, 255, 0.96);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  .navbar-default .navbar-nav > a:not(.btn) {
    color: #9A9A9A; }
  .navbar-default .navbar-nav > .active > a,
  .navbar-default .navbar-nav > .active > a:not(.btn):hover,
  .navbar-default .navbar-nav > .active > a:not(.btn):focus,
  .navbar-default .navbar-nav > a:not(.btn):hover,
  .navbar-default .navbar-nav > a:not(.btn):focus {
    background-color: transparent;
    border-radius: 3px;
    color: #1DC7EA;
    opacity: 1;
    filter: alpha(opacity=100); }
  .navbar-default .navbar-nav > .dropdown > a:hover .caret,
  .navbar-default .navbar-nav > .dropdown > a:focus .caret {
    border-bottom-color: #1DC7EA;
    border-top-color: #1DC7EA; }
  .navbar-default .navbar-nav > .open > a,
  .navbar-default .navbar-nav > .open > a:hover,
  .navbar-default .navbar-nav > .open > a:focus {
    background-color: transparent;
    color: #1DC7EA; }
  .navbar-default:not(.navbar-transparent) .btn-default:hover {
    color: #1DC7EA;
    border-color: #1DC7EA; }
  .navbar-default:not(.navbar-transparent) .btn-neutral,
  .navbar-default:not(.navbar-transparent) .btn-neutral:hover,
  .navbar-default:not(.navbar-transparent) .btn-neutral:active {
    color: #9A9A9A; }

/*      Navbar with icons            */
.navbar-icons.navbar .navbar-brand {
  margin-top: 12px;
  margin-bottom: 12px; }

.navbar-icons .navbar-nav > a {
  text-align: center;
  padding: 6px 15px;
  margin: 6px 3px; }

.navbar-icons .navbar-nav [class^="pe"] {
  font-size: 30px;
  position: relative; }

.navbar-icons .navbar-nav p {
  margin: 3px 0 0; }

.navbar-form {
  box-shadow: none; }
  .navbar-form .form-control {
    border-radius: 0;
    border: 0;
    padding: 0;
    background-color: transparent;
    height: 22px;
    font-size: 16px;
    line-height: 1.5;
    color: #E3E3E3; }
  .navbar-transparent .navbar-form .form-control,
  [class*="navbar-ct"] .navbar-form .form-control {
    color: #FFFFFF;
    border: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.6); }

.navbar-ct-blue {
  background-color: #4ea7ff; }

.navbar-ct-azure {
  background-color: #63d8f1; }

.navbar-ct-green {
  background-color: #a1e82c; }

.navbar-ct-orange {
  background-color: #ffbc67; }

.navbar-ct-red {
  background-color: #fc727a; }

.navbar-transparent {
  padding-top: 15px;
  background-color: transparent;
  border-bottom: 1px solid transparent; }



.footer {
  background-color: #FFFFFF;
  line-height: 20px; }
  .footer nav > ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-weight: normal; }
    .footer nav > ul a:not(.btn) {
      color: #9A9A9A;
      display: block;
      margin-bottom: 3px; }
      .footer nav > ul a:not(.btn):hover, .footer nav > ul a:not(.btn):focus {
        color: #777777; }
  .footer .social-area {
    padding: 15px 0; }
    .footer .social-area h5 {
      padding-bottom: 15px; }
  .footer .social-area > a:not(.btn) {
    color: #9A9A9A;
    display: inline-block;
    vertical-align: top;
    padding: 10px 5px;
    font-size: 20px;
    font-weight: normal;
    line-height: 20px;
    text-align: center; }
    .footer .social-area > a:not(.btn):hover, .footer .social-area > a:not(.btn):focus {
      color: #777777; }
  .footer .copyright {
    color: #777777;
    padding: 10px 15px;
    margin: 10px 3px;
    line-height: 20px;
    font-size: 14px; }
  .footer hr {
    border-color: #DDDDDD; }
  .footer .title {
    color: #777777; }

.footer-default {
  background-color: #F5F5F5; }

.footer:not(.footer-big) nav > ul {
  font-size: 14px; }
  .footer:not(.footer-big) nav > ul li {
    margin-left: 20px;
    float: left; }
  .footer:not(.footer-big) nav > ul a {
    padding: 10px 0px;
    margin: 10px 10px 10px 0px; }

.dropdown-menu {
  visibility: hidden;
  margin: 0;
  padding: 0;
  border-radius: 5px;
  display: block;
  z-index: 9000;
  position: absolute;
  opacity: 0;
  filter: alpha(opacity=0);
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.125); }
  .show .dropdown-menu {
    opacity: 1;
    filter: alpha(opacity=100);
    visibility: visible; 
  }
  .sidebar .show .dropdown-menu {
      opacity: 1;
      filter: alpha(opacity=100);
      visibility: visible; 
      top: 100% !important;
      transform: none !important;
      position: static !important;
      float: none;
      width: auto;
      margin-top: 0;
      background-color: transparent;
      border: 0;
      box-shadow: none; 
      line-height: 30px;
      font-size: 12px;
      text-transform: uppercase;
    }
    .sidebar .show .dropdown-menu a {
      font-weight: 500;
      color: #eee;
    }
    .sidebar .show .dropdown-menu a:hover {
      color:#333;
    }
  .select .dropdown-menu {
    border-radius: 0 0 5px 5px;
    box-shadow: none;
    transform-origin: 50% -40px;
    transform: scale(1);
    transition: all 150ms linear;
    margin-top: -20px; 
  }
  .select.open .dropdown-menu {
    margin-top: -1px; }
  .dropdown-menu > a {
    padding: 8px 16px;
    color: #333333; }
    .dropdown-menu > a img {
      margin-top: -3px; }
  .dropdown-menu > a:focus {
    outline: 0 !important; }
  .btn-group.select .dropdown-menu {
    min-width: 100%; }
  .dropdown-menu > a:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px; }
  .dropdown-menu > a:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; }
  .select .dropdown-menu > a:first-child {
    border-radius: 0;
    border-bottom: 0 none; }
  .dropdown-menu > a:hover,
  .dropdown-menu > a:focus {
    background-color: #F5F5F5;
    color: #333333;
    opacity: 1;
    text-decoration: none; }
  .dropdown-menu.dropdown-blue > a:hover,
  .dropdown-menu.dropdown-blue > a:focus {
    background-color: rgba(52, 114, 247, 0.2); }
  .dropdown-menu.dropdown-azure > a:hover,
  .dropdown-menu.dropdown-azure > a:focus {
    background-color: rgba(29, 199, 234, 0.2); }
  .dropdown-menu.ct-green > a:hover,
  .dropdown-menu.ct-green > a:focus {
    background-color: rgba(135, 203, 22, 0.2); }
  .dropdown-menu.dropdown-orange > a:hover,
  .dropdown-menu.dropdown-orange > a:focus {
    background-color: rgba(255, 149, 0, 0.2); }
  .dropdown-menu.dropdown-red > a:hover,
  .dropdown-menu.dropdown-red > a:focus {
    background-color: rgba(255, 74, 85, 0.2); }

.dropdown-with-icons > a {
  padding-left: 0px;
  line-height: 28px; }

.dropdown-with-icons i {
  text-align: center;
  line-height: 28px;
  float: left; }
  .dropdown-with-icons i[class^="pe-"] {
    font-size: 24px;
    width: 46px; }
  .dropdown-with-icons i[class^="fa"] {
    font-size: 14px;
    width: 38px; }

.btn-group.select {
  overflow: hidden; }

.btn-group.select.open {
  overflow: visible; }

.card {
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(63, 63, 68, 0.1);
  background-color: #FFFFFF;
  margin-bottom: 30px; }
  .card .image {
    width: 100%;
    overflow: hidden;
    height: 260px;
    border-radius: 2px 2px 0 0;
    position: relative;
    transform-style: preserve-3d; }
    .card .image img {
      width: 100%; }
  .card .filter {
    position: absolute;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.68);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    opacity: 0;
    filter: alpha(opacity=0); }
    .card .filter .btn {
      position: relative;
      top: 50%;
      transform: translateY(-50%); }
  .card:hover .filter {
    opacity: 1;
    filter: alpha(opacity=100); }
  .card .btn-hover {
    opacity: 0;
    filter: alpha(opacity=0); }
  .card:hover .btn-hover {
    opacity: 1;
    filter: alpha(opacity=100); }
  .card .content {
    padding: 15px 15px 10px 15px; }
  .card .header {
    padding: 15px 15px 0; }
.card .category,
.row .card label.col-form-label {
  font-size: 0.75rem !important;
  padding: 10px 0 5px;
  text-transform: capitalize; 
  font-weight: 500;
  color: #555;
}
.card .category i,
.card label i {
  font-size: 0.8rem;
}
  .card .title {
    margin: 0;
    color: #333333;
    font-weight: 300; }
  .card .avatar {
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 5px; }
  .card .description {
    font-size: 14px;
    color: #333; }
  .card .footer {
    padding: 0;
    background-color: transparent;
    line-height: 30px; }
    .card .footer .legend {
      padding: 5px 0; }
    .card .footer hr {
      margin-top: 5px;
      margin-bottom: 5px; }
  .card .stats {
    color: #a9a9a9; }
  .card .footer div {
    display: inline-block; }
  .card .author {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase; }
  .card .author i {
    font-size: 14px; }
  .card h6 {
    font-size: 12px;
    margin: .5rem; 
  }
  .card.card-separator:after {
    height: 100%;
    right: -15px;
    top: 0;
    width: 1px;
    background-color: #DDDDDD;
    content: "";
    position: absolute; }
  .card .ct-chart {
    margin: 30px 0 30px;
    height: 245px; }
  .card .table tbody td:first-child,
  .card .table thead th:first-child {
    padding-left: 15px; }
  .card .table tbody td:last-child,
  .card .table thead th:last-child {
    padding-right: 15px; }


.card-user .image {
  height: 110px; }

.card-user .image-plain {
  height: 0;
  margin-top: 110px; }

.card-user .author {
  text-align: center;
  text-transform: none;
  margin-top: -70px; }

.card-user .avatar {
  width: 124px;
  height: 124px;
  border: 5px solid #FFFFFF;
  position: relative;
  margin-bottom: 15px; }
  .card-user .avatar.border-gray {
    border-color: #EEEEEE; }

.card-user .title {
  line-height: 24px; }

.card-user .content {
  min-height: 240px; }

.card-user .footer,
.card-price .footer {
  padding: 5px 15px 10px; }

.card-user hr,
.card-price hr {
  margin: 5px 15px; }

.card-plain {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0; }
  .card-plain .image {
    border-radius: 2px; }

.card-stats .icon-big {
  font-size: 3em;
  min-height: 64px; }
  .card-stats .icon-big i {
    font-weight: bold;
    line-height: 59px; }

.card-stats .numbers {
  font-size: 2em;
  text-align: right; }
  .card-stats .numbers p {
    margin: 0; }

.ct-label {
  fill: rgba(0, 0, 0, 0.4);
  color: rgba(0, 0, 0, 0.4);
  font-size: 1.3rem;
  line-height: 1; }

.ct-chart-line .ct-label,
.ct-chart-bar .ct-label {
  display: block;
  display: flex; }

.ct-label.ct-horizontal.ct-start {
  align-items: flex-end;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

.ct-label.ct-horizontal.ct-end {
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

.ct-label.ct-vertical.ct-start {
  align-items: flex-end;
  justify-content: flex-end;
  text-align: right;
  text-anchor: end; }

.ct-label.ct-vertical.ct-end {
  align-items: flex-end;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

.ct-chart-bar .ct-label.ct-horizontal.ct-start {
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  text-anchor: start; }

.ct-chart-bar .ct-label.ct-horizontal.ct-end {
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  text-anchor: start; }

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-start {
  align-items: flex-end;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-end {
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-start {
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  text-anchor: end; }

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-end {
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  text-anchor: end; }

.ct-grid {
  stroke: rgba(0, 0, 0, 0.2);
  stroke-width: 1px;
  stroke-dasharray: 2px; }

.ct-point {
  stroke-width: 8px;
  stroke-linecap: round; }

.ct-line {
  fill: none;
  stroke-width: 3px; }

.ct-area {
  stroke: none;
  fill-opacity: 0.8; }

.ct-bar {
  fill: none;
  stroke-width: 10px; }

.ct-slice-donut {
  fill: none;
  stroke-width: 60px; }

.ct-series-a .ct-point, .ct-series-a .ct-line, .ct-series-a .ct-bar, .ct-series-a .ct-slice-donut {
  stroke: #1DC7EA; }

.ct-series-a .ct-slice-pie, .ct-series-a .ct-area {
  fill: #1DC7EA; }

.ct-series-b .ct-point, .ct-series-b .ct-line, .ct-series-b .ct-bar, .ct-series-b .ct-slice-donut {
  stroke: #FB404B; }

.ct-series-b .ct-slice-pie, .ct-series-b .ct-area {
  fill: #FB404B; }

.ct-series-c .ct-point, .ct-series-c .ct-line, .ct-series-c .ct-bar, .ct-series-c .ct-slice-donut {
  stroke: #FFA534; }

.ct-series-c .ct-slice-pie, .ct-series-c .ct-area {
  fill: #FFA534; }

.ct-series-d .ct-point, .ct-series-d .ct-line, .ct-series-d .ct-bar, .ct-series-d .ct-slice-donut {
  stroke: #9368E9; }

.ct-series-d .ct-slice-pie, .ct-series-d .ct-area {
  fill: #9368E9; }

.ct-series-e .ct-point, .ct-series-e .ct-line, .ct-series-e .ct-bar, .ct-series-e .ct-slice-donut {
  stroke: #87CB16; }

.ct-series-e .ct-slice-pie, .ct-series-e .ct-area {
  fill: #87CB16; }

.ct-series-f .ct-point, .ct-series-f .ct-line, .ct-series-f .ct-bar, .ct-series-f .ct-slice-donut {
  stroke: #1b8dff; }

.ct-series-f .ct-slice-pie, .ct-series-f .ct-area {
  fill: #1b8dff; }

.ct-series-g .ct-point, .ct-series-g .ct-line, .ct-series-g .ct-bar, .ct-series-g .ct-slice-donut {
  stroke: #5e5e5e; }

.ct-series-g .ct-slice-pie, .ct-series-g .ct-area {
  fill: #5e5e5e; }

.ct-series-h .ct-point, .ct-series-h .ct-line, .ct-series-h .ct-bar, .ct-series-h .ct-slice-donut {
  stroke: #dd4b39; }

.ct-series-h .ct-slice-pie, .ct-series-h .ct-area {
  fill: #dd4b39; }

.ct-series-i .ct-point, .ct-series-i .ct-line, .ct-series-i .ct-bar, .ct-series-i .ct-slice-donut {
  stroke: #35465c; }

.ct-series-i .ct-slice-pie, .ct-series-i .ct-area {
  fill: #35465c; }

.ct-series-j .ct-point, .ct-series-j .ct-line, .ct-series-j .ct-bar, .ct-series-j .ct-slice-donut {
  stroke: #e52d27; }

.ct-series-j .ct-slice-pie, .ct-series-j .ct-area {
  fill: #e52d27; }

.ct-series-k .ct-point, .ct-series-k .ct-line, .ct-series-k .ct-bar, .ct-series-k .ct-slice-donut {
  stroke: #55acee; }

.ct-series-k .ct-slice-pie, .ct-series-k .ct-area {
  fill: #55acee; }

.ct-series-l .ct-point, .ct-series-l .ct-line, .ct-series-l .ct-bar, .ct-series-l .ct-slice-donut {
  stroke: #cc2127; }

.ct-series-l .ct-slice-pie, .ct-series-l .ct-area {
  fill: #cc2127; }

.ct-series-m .ct-point, .ct-series-m .ct-line, .ct-series-m .ct-bar, .ct-series-m .ct-slice-donut {
  stroke: #1769ff; }

.ct-series-m .ct-slice-pie, .ct-series-m .ct-area {
  fill: #1769ff; }

.ct-series-n .ct-point, .ct-series-n .ct-line, .ct-series-n .ct-bar, .ct-series-n .ct-slice-donut {
  stroke: #6188e2; }

.ct-series-n .ct-slice-pie, .ct-series-n .ct-area {
  fill: #6188e2; }

.ct-series-o .ct-point, .ct-series-o .ct-line, .ct-series-o .ct-bar, .ct-series-o .ct-slice-donut {
  stroke: #a748ca; }

.ct-series-o .ct-slice-pie, .ct-series-o .ct-area {
  fill: #a748ca; }

.ct-square {
  display: block;
  position: relative;
  width: 100%; }
  .ct-square:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 100%; }
  .ct-square:after {
    content: "";
    display: table;
    clear: both; }
  .ct-square > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-minor-second {
  display: block;
  position: relative;
  width: 100%; }
  .ct-minor-second:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 93.75%; }
  .ct-minor-second:after {
    content: "";
    display: table;
    clear: both; }
  .ct-minor-second > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-major-second {
  display: block;
  position: relative;
  width: 100%; }
  .ct-major-second:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 88.88889%; }
  .ct-major-second:after {
    content: "";
    display: table;
    clear: both; }
  .ct-major-second > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-minor-third {
  display: block;
  position: relative;
  width: 100%; }
  .ct-minor-third:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 83.33333%; }
  .ct-minor-third:after {
    content: "";
    display: table;
    clear: both; }
  .ct-minor-third > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-major-third {
  display: block;
  position: relative;
  width: 100%; }
  .ct-major-third:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 80%; }
  .ct-major-third:after {
    content: "";
    display: table;
    clear: both; }
  .ct-major-third > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-perfect-fourth {
  display: block;
  position: relative;
  width: 100%; }
  .ct-perfect-fourth:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 75%; }
  .ct-perfect-fourth:after {
    content: "";
    display: table;
    clear: both; }
  .ct-perfect-fourth > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-perfect-fifth {
  display: block;
  position: relative;
  width: 100%; }
  .ct-perfect-fifth:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 66.66667%; }
  .ct-perfect-fifth:after {
    content: "";
    display: table;
    clear: both; }
  .ct-perfect-fifth > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-minor-sixth {
  display: block;
  position: relative;
  width: 100%; }
  .ct-minor-sixth:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 62.5%; }
  .ct-minor-sixth:after {
    content: "";
    display: table;
    clear: both; }
  .ct-minor-sixth > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-golden-section {
  display: block;
  position: relative;
  width: 100%; }
  .ct-golden-section:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 61.8047%; }
  .ct-golden-section:after {
    content: "";
    display: table;
    clear: both; }
  .ct-golden-section > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-major-sixth {
  display: block;
  position: relative;
  width: 100%; }
  .ct-major-sixth:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 60%; }
  .ct-major-sixth:after {
    content: "";
    display: table;
    clear: both; }
  .ct-major-sixth > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-minor-seventh {
  display: block;
  position: relative;
  width: 100%; }
  .ct-minor-seventh:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 56.25%; }
  .ct-minor-seventh:after {
    content: "";
    display: table;
    clear: both; }
  .ct-minor-seventh > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-major-seventh {
  display: block;
  position: relative;
  width: 100%; }
  .ct-major-seventh:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 53.33333%; }
  .ct-major-seventh:after {
    content: "";
    display: table;
    clear: both; }
  .ct-major-seventh > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-octave {
  display: block;
  position: relative;
  width: 100%; }
  .ct-octave:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 50%; }
  .ct-octave:after {
    content: "";
    display: table;
    clear: both; }
  .ct-octave > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-major-tenth {
  display: block;
  position: relative;
  width: 100%; }
  .ct-major-tenth:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 40%; }
  .ct-major-tenth:after {
    content: "";
    display: table;
    clear: both; }
  .ct-major-tenth > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-major-eleventh {
  display: block;
  position: relative;
  width: 100%; }
  .ct-major-eleventh:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 37.5%; }
  .ct-major-eleventh:after {
    content: "";
    display: table;
    clear: both; }
  .ct-major-eleventh > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-major-twelfth {
  display: block;
  position: relative;
  width: 100%; }
  .ct-major-twelfth:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 33.33333%; }
  .ct-major-twelfth:after {
    content: "";
    display: table;
    clear: both; }
  .ct-major-twelfth > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

.ct-double-octave {
  display: block;
  position: relative;
  width: 100%; }
  .ct-double-octave:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 25%; }
  .ct-double-octave:after {
    content: "";
    display: table;
    clear: both; }
  .ct-double-octave > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }

@media (min-width: 992px) {
  .navbar-form {
    margin-top: 21px;
    margin-bottom: 21px;
    padding-left: 5px;
    padding-right: 5px; }
  .navbar-nav .dropdown  > .dropdown-menu {
    transform: scale(0);
    transition: all 370ms cubic-bezier(0.34, 1.61, 0.7, 1); }
  .navbar-nav .dropdown.show  > .dropdown-menu.show {
    transform: scale(1);
    transform-origin: 29px -50px; }
  .navbar-nav .dropdown > .dropdown-menu:before {
    border-bottom: 11px solid rgba(0, 0, 0, 0.2);
    border-left: 11px solid rgba(0, 0, 0, 0);
    border-right: 11px solid rgba(0, 0, 0, 0);
    content: "";
    display: inline-block;
    position: absolute;
    left: 12px;
    top: -11px; }
  .navbar-nav .dropdown > .dropdown-menu:after {
    border-bottom: 11px solid #FFFFFF;
    border-left: 11px solid rgba(0, 0, 0, 0);
    border-right: 11px solid rgba(0, 0, 0, 0);
    content: "";
    display: inline-block;
    position: absolute;
    left: 12px;
    top: -10px; }
  .navbar-nav.navbar-right .dropdown > .dropdown-menu:before {
    left: auto;
    right: 12px; }
  .navbar-nav.navbar-right .dropdown > .dropdown-menu:after {
    left: auto;
    right: 12px; }
  .footer:not(.footer-big) nav > ul li:first-child {
    margin-left: 0; }
  body > .navbar-collapse.collapse {
    display: none !important; 
  }

  .card form [class*="col-"]:first-child {
    padding-left: 5px; }
  .card form [class*="col-"]:last-child {
    padding-right: 5px; } 
}



/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
.fixed-plugin .button-container .github-btn{
    display: inline-block;
    font-size: 14px;
}
/*.fixed-plugin .button-container .github-btn .gh-btn,
.fixed-plugin .button-container .github-btn .gh-count{
    padding: 5px 7px 5px 7px;
}*/
.fixed-plugin .SocialMediaShareButton{
    display: inline-block;
    margin: 0 2px;
}
.fixed-plugin li > a,
.fixed-plugin .badge{
    transition: all .34s;
    -webkit-transition: all .34s;
    -moz-transition: all .34s;
}

.all-icons [class*="pe-"]{
    font-size: 40px;
}
.all-icons input{
    border: 0;
}
.all-icons .font-icon-detail{
    text-align: center;
    padding: 45px 0px 30px;
    border: 1px solid #e5e5e5;
    border-radius: 6px;
    margin: 15px 0;
}
.all-icons .font-icon-detail input{
    margin: 25px auto 0;
    width: 100%;
    text-align: center;
    display: block;
    color: #aaa;
    font-size: 13px;
}

#map{
    position:relative;
    width:100%;
    height: calc(100% - 60px);
}

.places-buttons .btn{
    margin-bottom: 30px
}
.sidebar .nav > li.active-pro{
    position: absolute;
    width: 100%;
    bottom: 10px;
}
.sidebar .nav > li.active-pro a{
    background: rgba(255, 255, 255, 0.14);
    opacity: 1;
    color: #FFFFFF;
}

.table-upgrade td:nth-child(2),
.table-upgrade td:nth-child(3){
    text-align: center;
}

.fixed-plugin{
    position: absolute;
    top: 180px;
    right: 0;
    width: 64px;
    background: rgba(0,0,0,.3);
    z-index: 1031;
    border-radius: 8px 0 0 8px;
    text-align: center;
}
.fixed-plugin .fa-cog{
    color: #FFFFFF;
    padding: 10px;
    border-radius: 0 0 6px 6px;
    width: auto;
}
.fixed-plugin .dropdown-menu{
    right: 80px;
    left: auto;
    width: 290px;
    border-radius: 10px;
    padding: 0 10px;
}
.fixed-plugin .dropdown-menu:after, .fixed-plugin .dropdown-menu:before{
    right: 10px;
    margin-left: auto;
    left: auto;
}
.fixed-plugin .fa-circle-thin{
    color: #FFFFFF;
}
.fixed-plugin .active .fa-circle-thin{
    color: #00bbff;
}

.footer-dropdown{
	top: -120px !important;
}

.footer-dropdown:before, .footer-dropdown:after{
	top: 300px !important;
}

.fixed-plugin .dropdown-menu > .active > a,
.fixed-plugin .dropdown-menu > .active > a:hover,
.fixed-plugin .dropdown-menu > .active > a:focus{
    color: #777777;
    text-align: center;
}

.fixed-plugin img{
    border-radius: 0;
    width: 100%;
    max-height: 175px;
    margin: 0 auto;
}

.fixed-plugin .badge{
    border: 3px solid #FFFFFF;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    height: 23px;
    margin-right: 5px;
    position: relative;
    width: 23px;
}
.fixed-plugin .badge.active,
.fixed-plugin .badge:hover{
    border-color: #00bbff;
}

.fixed-plugin .badge-white{
    background-color: #FFFFFF;
}
.fixed-plugin .badge-black{
    background-color: #1DC7EA;
}
.fixed-plugin .badge-azure{
    background-color: #1DC7EA;
}
.fixed-plugin .badge-green{
    background-color: #87CB16;
}
.fixed-plugin .badge-orange{
    background-color: #FFA534;
}
.fixed-plugin .badge-purple{
    background-color: #9368E9;
}
.fixed-plugin .badge-red{
    background-color: #FB404B;
}
.fixed-plugin h5{
    font-size: 14px;
    margin: 10px;
}
.fixed-plugin .dropdown-menu li{
    display: block;
    padding: 5px 2px;
    width: 25%;
    float: left;
}

.fixed-plugin li.adjustments-line,
.fixed-plugin li.header-title,
.fixed-plugin li.button-container{
    width: 100%;
    height: 50px;
    min-height: inherit;
    text-align: center;
}
.fixed-plugin .pro-title{
    margin: 10px 0 5px 0;
    text-align: center;
}

.fixed-plugin #sharrreTitle{
    text-align: center;
    padding: 10px 0;
    height: 50px;
}

.fixed-plugin li.header-title{
    height: 30px;
    line-height: 40px;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}

.fixed-plugin .adjustments-line p{
    float: left;
    display: inline-block;
    margin-bottom: 0;
    font-size: 1em;
}
.fixed-plugin .adjustments-line .switch{
    float: right;
}
.fixed-plugin .dropdown-menu > li.adjustments-line > a{
      padding-right: 0;
      padding-left: 0;
      border-bottom: 1px solid #ddd;
      margin: 0;
}
.fixed-plugin .dropdown-menu > li > a.img-holder{
      font-size: 16px;
      text-align: center;
      border-radius: 10px;
      background-color: #FFF;
      border: 3px solid #FFF;
      padding-left: 0;
      padding-right: 0;
      opacity: 1;
      cursor: pointer;
      max-height: 86px;
      overflow: hidden;
      padding: 0;
}

.fixed-plugin .dropdown-menu > li > a.switch-trigger:hover,
.fixed-plugin .dropdown-menu > li > a.switch-trigger:focus{
    background-color: transparent;
}
.fixed-plugin .dropdown-menu > li:hover > a.img-holder,
.fixed-plugin .dropdown-menu > li:focus > a.img-holder{
    border-color: rgba(0, 187, 255, 0.53);;
}
.fixed-plugin .dropdown-menu > .active > a.img-holder,
.fixed-plugin .dropdown-menu > .active > a.img-holder{
    border-color: #00bbff;
    background-color: #FFFFFF;
}

.fixed-plugin .dropdown-menu > li > a img{
    margin-top: auto;
}

.fixed-plugin .btn-social{
    width: 50%;
    display: block;
    width: 48%;
    float: left;
    font-weight: 600;
}
.fixed-plugin .btn-social i{
    margin-right: 5px;
}
.fixed-plugin .btn-social:first-child{
    margin-right: 2%;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus{
    opacity: 1;
}


@media (min-width: 992px){
    .fixed-plugin .dropdown .dropdown-menu{
         transform: translateY(-50%);
         top: 27px;
         opacity: 0;

         transform-origin: 0 0;
    }
    .fixed-plugin .dropdown.open .dropdown-menu{
         opacity: 1;
         transform: translateY(-50%);

         transform-origin: 0 0;
    }

    .fixed-plugin .dropdown-menu:before,
    .fixed-plugin .dropdown-menu:after{
        content: "";
        display: inline-block;
        position: absolute;
        top: 50%;
        width: 16px;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);

    }
    .fixed-plugin .dropdown-menu:before{
        border-bottom: 16px solid rgba(0, 0, 0, 0);
        border-left: 16px solid rgba(0,0,0,0.2);
        border-top: 16px solid rgba(0,0,0,0);
        right: -16px;
    }

    .fixed-plugin .dropdown-menu:after{
        border-bottom: 16px solid rgba(0, 0, 0, 0);
        border-left: 16px solid #FFFFFF;
        border-top: 16px solid rgba(0,0,0,0);
        right: -15px;
    }

    .typo-line{
        padding-left: 140px;
        margin-bottom: 40px;
        position: relative;
    }

    .typo-line .category{
        transform: translateY(-50%);
        top: 50%;
        left: 0px;
        position: absolute;
    }

    .fixed-plugin{
        top: 300px;
    }

}

@media (max-width: 991px){
    .fixed-plugin .dropdown-menu{
        right: 60px;
        width: 220px;
    }
    .fixed-plugin .dropdown-menu li{
        width: 50%;
    }

    .fixed-plugin li.adjustments-line,
    .fixed-plugin li.header-title,
    .fixed-plugin li.button-container{
        width: 100%;
        height: 55px;
        min-height: inherit;
    }

    .fixed-plugin .adjustments-line .switch{
        float: right;
        margin: 0 0px;
    }

    .fixed-plugin li.header-title{
        height: 40px;
    }
    .fixed-plugin .dropdown .dropdown-menu{
        top: -170px;
    }
}

.btn-social {
  opacity: 0.85;
  padding: 8px 9px;
}
.btn-social .fa {
  font-size: 18px;
  vertical-align: middle;
  display: inline-block;
}
.btn-social.btn-round {
  padding: 9px 10px;
}
.btn-social.btn-simple {
  padding: 9px 5px;
  font-size: 16px;
}
.btn-social.btn-simple .fa {
  font-size: 20px;
  position: relative;
  top: -2px;
  width: 24px;
}

.btn-facebook {
  border-color: #3b5998;
  color: #3b5998;
}
.btn-facebook:hover,
.btn-facebook:focus,
.btn-facebook:active,
.btn-facebook.active,
.open > .btn-facebook.dropdown-toggle {
  background-color: transparent;
  color: #3b5998;
  border-color: #3b5998;
  opacity: 1;
}
.btn-facebook:disabled,
.btn-facebook[disabled],
.btn-facebook.disabled {
  background-color: transparent;
  border-color: #3b5998;
}
.btn-facebook.btn-fill {
  color: #ffffff;
  background-color: #3b5998;
  opacity: 0.9;
}
.btn-facebook.btn-fill:hover,
.btn-facebook.btn-fill:focus,
.btn-facebook.btn-fill:active,
.btn-facebook.btn-fill.active,
.open > .btn-facebook.btn-fill.dropdown-toggle {
  background-color: #3b5998;
  color: #ffffff;
  opacity: 1;
}
.btn-twitter {
  border-color: #55acee;
  color: #55acee;
}
.btn-twitter:hover,
.btn-twitter:focus,
.btn-twitter:active,
.btn-twitter.active,
.open > .btn-twitter.dropdown-toggle {
  background-color: transparent;
  color: #55acee;
  border-color: #55acee;
  opacity: 1;
}
.btn-twitter:disabled,
.btn-twitter[disabled],
.btn-twitter.disabled {
  background-color: transparent;
  border-color: #55acee;
}
.btn-twitter.btn-fill {
  color: #ffffff;
  background-color: #55acee;
  opacity: 0.9;
}
.btn-twitter.btn-fill:hover,
.btn-twitter.btn-fill:focus,
.btn-twitter.btn-fill:active,
.btn-twitter.btn-fill.active,
.open > .btn-twitter.btn-fill.dropdown-toggle {
  background-color: #55acee;
  color: #ffffff;
  opacity: 1;
}

@media (min-width: 992px){
    .typo-line{
        padding-left: 140px;
        margin-bottom: 40px;
        position: relative;
    }

    .typo-line .category{
        transform: translateY(-50%);
        top: 50%;
        left: 0px;
        position: absolute;
    }
}

.all-icons [class*="pe-"]{
    font-size: 40px;
}
.all-icons input{
    border: 0;
}
.all-icons .font-icon-detail{
    text-align: center;
    padding: 45px 0px 30px;
    border: 1px solid #e5e5e5;
    border-radius: 6px;
    margin: 15px 0;
}
.all-icons .font-icon-detail input{
    margin: 25px auto 0;
    width: 100%;
    text-align: center;
    display: block;
    color: #aaa;
    font-size: 13px;
}

#map{
    position:relative;
    width:100%;
    height: calc(100% - 60px);
}

.places-buttons .btn{
    margin-bottom: 30px
}
.sidebar .nav > li.active-pro{
    position: absolute;
    width: 100%;
    bottom: 10px;
}
.sidebar .nav > li.active-pro a{
    background: rgba(255, 255, 255, 0.14);
    opacity: 1;
    color: #FFFFFF;
}

.table-upgrade td:nth-child(2),
.table-upgrade td:nth-child(3){
    text-align: center;
}
.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4D4D4D;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #5d5d5d;
}

.react-toggle--checked .react-toggle-track {
  background-color: #1fc6ea;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #1fc6ea;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4D4D4D;
  border-radius: 50%;
  background-color: #FAFAFA;
  box-sizing: border-box;
  transition: all 0.25s ease;

  outline: 0;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #1fc6ea;
}

.github-btn {
  font: bold 11px/14px 'Helvetica Neue', Helvetica, Arial, sans-serif;
  height: 20px;
  overflow: hidden;
}
.gh-btn,
.gh-count,
.gh-ico {
  float: left;
}
.gh-btn,
.gh-count {
  padding: 2px 5px 2px 4px;
  color: #333;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 3px;
}
.gh-btn {
  background-color: #eee;
  background-image: linear-gradient(to bottom, #fcfcfc 0, #eee 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fcfcfc', endColorstr='#eeeeee', GradientType=0);
  background-repeat: no-repeat;
  border: 1px solid #d5d5d5;
}
.gh-btn:hover,
.gh-btn:focus {
  text-decoration: none;
  background-color: #ddd;
  background-image: linear-gradient(to bottom, #eee 0, #ddd 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#dddddd', GradientType=0);
  border-color: #ccc;
}
.gh-btn:active {
  background-image: none;
  background-color: #dcdcdc;
  border-color: #b5b5b5;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15);
}
.gh-ico {
  width: 14px;
  height: 14px;
  margin-right: 4px;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjQwcHgiIGhlaWdodD0iNDBweCIgdmlld0JveD0iMTIgMTIgNDAgNDAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMTIgMTIgNDAgNDAiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGZpbGw9IiMzMzMzMzMiIGQ9Ik0zMiAxMy40Yy0xMC41IDAtMTkgOC41LTE5IDE5YzAgOC40IDUuNSAxNS41IDEzIDE4YzEgMC4yIDEuMy0wLjQgMS4zLTAuOWMwLTAuNSAwLTEuNyAwLTMuMiBjLTUuMyAxLjEtNi40LTIuNi02LjQtMi42QzIwIDQxLjYgMTguOCA0MSAxOC44IDQxYy0xLjctMS4yIDAuMS0xLjEgMC4xLTEuMWMxLjkgMC4xIDIuOSAyIDIuOSAyYzEuNyAyLjkgNC41IDIuMSA1LjUgMS42IGMwLjItMS4yIDAuNy0yLjEgMS4yLTIuNmMtNC4yLTAuNS04LjctMi4xLTguNy05LjRjMC0yLjEgMC43LTMuNyAyLTUuMWMtMC4yLTAuNS0wLjgtMi40IDAuMi01YzAgMCAxLjYtMC41IDUuMiAyIGMxLjUtMC40IDMuMS0wLjcgNC44LTAuN2MxLjYgMCAzLjMgMC4yIDQuNyAwLjdjMy42LTIuNCA1LjItMiA1LjItMmMxIDIuNiAwLjQgNC42IDAuMiA1YzEuMiAxLjMgMiAzIDIgNS4xYzAgNy4zLTQuNSA4LjktOC43IDkuNCBjMC43IDAuNiAxLjMgMS43IDEuMyAzLjVjMCAyLjYgMCA0LjYgMCA1LjJjMCAwLjUgMC40IDEuMSAxLjMgMC45YzcuNS0yLjYgMTMtOS43IDEzLTE4LjFDNTEgMjEuOSA0Mi41IDEzLjQgMzIgMTMuNHoiLz48L3N2Zz4=');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.gh-count {
  position: relative;
  display: none; /* hidden to start */
  margin-left: 4px;
  background-color: #fafafa;
  border: 1px solid #d4d4d4;
}
.gh-count:hover,
.gh-count:focus {
  color: #4183C4;
}
.gh-count:before,
.gh-count:after {
  content: '';
  position: absolute;
  display: inline-block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.gh-count:before {
  top: 50%;
  left: -3px;
  margin-top: -4px;
  border-width: 4px 4px 4px 0;
  border-right-color: #fafafa;
}
.gh-count:after {
  top: 50%;
  left: -4px;
  z-index: -1;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #d4d4d4;
}
.github-btn-large {
  height: 30px;
}
.github-btn-large .gh-btn,
.github-btn-large .gh-count {
  padding: 3px 10px 3px 8px;
  font-size: 16px;
  line-height: 22px;
  border-radius: 4px;
}
.github-btn-large .gh-ico {
  width: 20px;
  height: 20px;
}
.github-btn-large .gh-count {
  margin-left: 6px;
}
.github-btn-large .gh-count:before {
  left: -5px;
  margin-top: -6px;
  border-width: 6px 6px 6px 0;
}
.github-btn-large .gh-count:after {
  left: -6px;
  margin-top: -7px;
  border-width: 7px 7px 7px 0;
}

/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
@font-face {
	font-family: 'Pe-icon-7-stroke';
	src:url(/static/media/Pe-icon-7-stroke.71394c0c.eot);
	src:url(/static/media/Pe-icon-7-stroke.71394c0c.eot?#iefixd7yf1v) format('embedded-opentype'),
		url(/static/media/Pe-icon-7-stroke.b38ef310.woff) format('woff'),
		url(/static/media/Pe-icon-7-stroke.01798bc1.ttf) format('truetype'),
		url(/static/media/Pe-icon-7-stroke.c45f7de0.svg#Pe-icon-7-stroke) format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="pe-7s-"], [class*=" pe-7s-"] {
	display: inline-block;
	font-family: 'Pe-icon-7-stroke';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-feature-settings: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.pe-7s-album:before {
	content: "\e6aa";
}
.pe-7s-arc:before {
	content: "\e6ab";
}
.pe-7s-back-2:before {
	content: "\e6ac";
}
.pe-7s-bandaid:before {
	content: "\e6ad";
}
.pe-7s-car:before {
	content: "\e6ae";
}
.pe-7s-diamond:before {
	content: "\e6af";
}
.pe-7s-door-lock:before {
	content: "\e6b0";
}
.pe-7s-eyedropper:before {
	content: "\e6b1";
}
.pe-7s-female:before {
	content: "\e6b2";
}
.pe-7s-gym:before {
	content: "\e6b3";
}
.pe-7s-hammer:before {
	content: "\e6b4";
}
.pe-7s-headphones:before {
	content: "\e6b5";
}
.pe-7s-helm:before {
	content: "\e6b6";
}
.pe-7s-hourglass:before {
	content: "\e6b7";
}
.pe-7s-leaf:before {
	content: "\e6b8";
}
.pe-7s-magic-wand:before {
	content: "\e6b9";
}
.pe-7s-male:before {
	content: "\e6ba";
}
.pe-7s-map-2:before {
	content: "\e6bb";
}
.pe-7s-next-2:before {
	content: "\e6bc";
}
.pe-7s-paint-bucket:before {
	content: "\e6bd";
}
.pe-7s-pendrive:before {
	content: "\e6be";
}
.pe-7s-photo:before {
	content: "\e6bf";
}
.pe-7s-piggy:before {
	content: "\e6c0";
}
.pe-7s-plugin:before {
	content: "\e6c1";
}
.pe-7s-refresh-2:before {
	content: "\e6c2";
}
.pe-7s-rocket:before {
	content: "\e6c3";
}
.pe-7s-settings:before {
	content: "\e6c4";
}
.pe-7s-shield:before {
	content: "\e6c5";
}
.pe-7s-smile:before {
	content: "\e6c6";
}
.pe-7s-usb:before {
	content: "\e6c7";
}
.pe-7s-vector:before {
	content: "\e6c8";
}
.pe-7s-wine:before {
	content: "\e6c9";
}
.pe-7s-cloud-upload:before {
	content: "\e68a";
}
.pe-7s-cash:before {
	content: "\e68c";
}
.pe-7s-close:before {
	content: "\e680";
}
.pe-7s-bluetooth:before {
	content: "\e68d";
}
.pe-7s-cloud-download:before {
	content: "\e68b";
}
.pe-7s-way:before {
	content: "\e68e";
}
.pe-7s-close-circle:before {
	content: "\e681";
}
.pe-7s-id:before {
	content: "\e68f";
}
.pe-7s-angle-up:before {
	content: "\e682";
}
.pe-7s-wristwatch:before {
	content: "\e690";
}
.pe-7s-angle-up-circle:before {
	content: "\e683";
}
.pe-7s-world:before {
	content: "\e691";
}
.pe-7s-angle-right:before {
	content: "\e684";
}
.pe-7s-volume:before {
	content: "\e692";
}
.pe-7s-angle-right-circle:before {
	content: "\e685";
}
.pe-7s-users:before {
	content: "\e693";
}
.pe-7s-angle-left:before {
	content: "\e686";
}
.pe-7s-user-female:before {
	content: "\e694";
}
.pe-7s-angle-left-circle:before {
	content: "\e687";
}
.pe-7s-up-arrow:before {
	content: "\e695";
}
.pe-7s-angle-down:before {
	content: "\e688";
}
.pe-7s-switch:before {
	content: "\e696";
}
.pe-7s-angle-down-circle:before {
	content: "\e689";
}
.pe-7s-scissors:before {
	content: "\e697";
}
.pe-7s-wallet:before {
	content: "\e600";
}
.pe-7s-safe:before {
	content: "\e698";
}
.pe-7s-volume2:before {
	content: "\e601";
}
.pe-7s-volume1:before {
	content: "\e602";
}
.pe-7s-voicemail:before {
	content: "\e603";
}
.pe-7s-video:before {
	content: "\e604";
}
.pe-7s-user:before {
	content: "\e605";
}
.pe-7s-upload:before {
	content: "\e606";
}
.pe-7s-unlock:before {
	content: "\e607";
}
.pe-7s-umbrella:before {
	content: "\e608";
}
.pe-7s-trash:before {
	content: "\e609";
}
.pe-7s-tools:before {
	content: "\e60a";
}
.pe-7s-timer:before {
	content: "\e60b";
}
.pe-7s-ticket:before {
	content: "\e60c";
}
.pe-7s-target:before {
	content: "\e60d";
}
.pe-7s-sun:before {
	content: "\e60e";
}
.pe-7s-study:before {
	content: "\e60f";
}
.pe-7s-stopwatch:before {
	content: "\e610";
}
.pe-7s-star:before {
	content: "\e611";
}
.pe-7s-speaker:before {
	content: "\e612";
}
.pe-7s-signal:before {
	content: "\e613";
}
.pe-7s-shuffle:before {
	content: "\e614";
}
.pe-7s-shopbag:before {
	content: "\e615";
}
.pe-7s-share:before {
	content: "\e616";
}
.pe-7s-server:before {
	content: "\e617";
}
.pe-7s-search:before {
	content: "\e618";
}
.pe-7s-film:before {
	content: "\e6a5";
}
.pe-7s-science:before {
	content: "\e619";
}
.pe-7s-disk:before {
	content: "\e6a6";
}
.pe-7s-ribbon:before {
	content: "\e61a";
}
.pe-7s-repeat:before {
	content: "\e61b";
}
.pe-7s-refresh:before {
	content: "\e61c";
}
.pe-7s-add-user:before {
	content: "\e6a9";
}
.pe-7s-refresh-cloud:before {
	content: "\e61d";
}
.pe-7s-paperclip:before {
	content: "\e69c";
}
.pe-7s-radio:before {
	content: "\e61e";
}
.pe-7s-note2:before {
	content: "\e69d";
}
.pe-7s-print:before {
	content: "\e61f";
}
.pe-7s-network:before {
	content: "\e69e";
}
.pe-7s-prev:before {
	content: "\e620";
}
.pe-7s-mute:before {
	content: "\e69f";
}
.pe-7s-power:before {
	content: "\e621";
}
.pe-7s-medal:before {
	content: "\e6a0";
}
.pe-7s-portfolio:before {
	content: "\e622";
}
.pe-7s-like2:before {
	content: "\e6a1";
}
.pe-7s-plus:before {
	content: "\e623";
}
.pe-7s-left-arrow:before {
	content: "\e6a2";
}
.pe-7s-play:before {
	content: "\e624";
}
.pe-7s-key:before {
	content: "\e6a3";
}
.pe-7s-plane:before {
	content: "\e625";
}
.pe-7s-joy:before {
	content: "\e6a4";
}
.pe-7s-photo-gallery:before {
	content: "\e626";
}
.pe-7s-pin:before {
	content: "\e69b";
}
.pe-7s-phone:before {
	content: "\e627";
}
.pe-7s-plug:before {
	content: "\e69a";
}
.pe-7s-pen:before {
	content: "\e628";
}
.pe-7s-right-arrow:before {
	content: "\e699";
}
.pe-7s-paper-plane:before {
	content: "\e629";
}
.pe-7s-delete-user:before {
	content: "\e6a7";
}
.pe-7s-paint:before {
	content: "\e62a";
}
.pe-7s-bottom-arrow:before {
	content: "\e6a8";
}
.pe-7s-notebook:before {
	content: "\e62b";
}
.pe-7s-note:before {
	content: "\e62c";
}
.pe-7s-next:before {
	content: "\e62d";
}
.pe-7s-news-paper:before {
	content: "\e62e";
}
.pe-7s-musiclist:before {
	content: "\e62f";
}
.pe-7s-music:before {
	content: "\e630";
}
.pe-7s-mouse:before {
	content: "\e631";
}
.pe-7s-more:before {
	content: "\e632";
}
.pe-7s-moon:before {
	content: "\e633";
}
.pe-7s-monitor:before {
	content: "\e634";
}
.pe-7s-micro:before {
	content: "\e635";
}
.pe-7s-menu:before {
	content: "\e636";
}
.pe-7s-map:before {
	content: "\e637";
}
.pe-7s-map-marker:before {
	content: "\e638";
}
.pe-7s-mail:before {
	content: "\e639";
}
.pe-7s-mail-open:before {
	content: "\e63a";
}
.pe-7s-mail-open-file:before {
	content: "\e63b";
}
.pe-7s-magnet:before {
	content: "\e63c";
}
.pe-7s-loop:before {
	content: "\e63d";
}
.pe-7s-look:before {
	content: "\e63e";
}
.pe-7s-lock:before {
	content: "\e63f";
}
.pe-7s-lintern:before {
	content: "\e640";
}
.pe-7s-link:before {
	content: "\e641";
}
.pe-7s-like:before {
	content: "\e642";
}
.pe-7s-light:before {
	content: "\e643";
}
.pe-7s-less:before {
	content: "\e644";
}
.pe-7s-keypad:before {
	content: "\e645";
}
.pe-7s-junk:before {
	content: "\e646";
}
.pe-7s-info:before {
	content: "\e647";
}
.pe-7s-home:before {
	content: "\e648";
}
.pe-7s-help2:before {
	content: "\e649";
}
.pe-7s-help1:before {
	content: "\e64a";
}
.pe-7s-graph3:before {
	content: "\e64b";
}
.pe-7s-graph2:before {
	content: "\e64c";
}
.pe-7s-graph1:before {
	content: "\e64d";
}
.pe-7s-graph:before {
	content: "\e64e";
}
.pe-7s-global:before {
	content: "\e64f";
}
.pe-7s-gleam:before {
	content: "\e650";
}
.pe-7s-glasses:before {
	content: "\e651";
}
.pe-7s-gift:before {
	content: "\e652";
}
.pe-7s-folder:before {
	content: "\e653";
}
.pe-7s-flag:before {
	content: "\e654";
}
.pe-7s-filter:before {
	content: "\e655";
}
.pe-7s-file:before {
	content: "\e656";
}
.pe-7s-expand1:before {
	content: "\e657";
}
.pe-7s-exapnd2:before {
	content: "\e658";
}
.pe-7s-edit:before {
	content: "\e659";
}
.pe-7s-drop:before {
	content: "\e65a";
}
.pe-7s-drawer:before {
	content: "\e65b";
}
.pe-7s-download:before {
	content: "\e65c";
}
.pe-7s-display2:before {
	content: "\e65d";
}
.pe-7s-display1:before {
	content: "\e65e";
}
.pe-7s-diskette:before {
	content: "\e65f";
}
.pe-7s-date:before {
	content: "\e660";
}
.pe-7s-cup:before {
	content: "\e661";
}
.pe-7s-culture:before {
	content: "\e662";
}
.pe-7s-crop:before {
	content: "\e663";
}
.pe-7s-credit:before {
	content: "\e664";
}
.pe-7s-copy-file:before {
	content: "\e665";
}
.pe-7s-config:before {
	content: "\e666";
}
.pe-7s-compass:before {
	content: "\e667";
}
.pe-7s-comment:before {
	content: "\e668";
}
.pe-7s-coffee:before {
	content: "\e669";
}
.pe-7s-cloud:before {
	content: "\e66a";
}
.pe-7s-clock:before {
	content: "\e66b";
}
.pe-7s-check:before {
	content: "\e66c";
}
.pe-7s-chat:before {
	content: "\e66d";
}
.pe-7s-cart:before {
	content: "\e66e";
}
.pe-7s-camera:before {
	content: "\e66f";
}
.pe-7s-call:before {
	content: "\e670";
}
.pe-7s-calculator:before {
	content: "\e671";
}
.pe-7s-browser:before {
	content: "\e672";
}
.pe-7s-box2:before {
	content: "\e673";
}
.pe-7s-box1:before {
	content: "\e674";
}
.pe-7s-bookmarks:before {
	content: "\e675";
}
.pe-7s-bicycle:before {
	content: "\e676";
}
.pe-7s-bell:before {
	content: "\e677";
}
.pe-7s-battery:before {
	content: "\e678";
}
.pe-7s-ball:before {
	content: "\e679";
}
.pe-7s-back:before {
	content: "\e67a";
}
.pe-7s-attention:before {
	content: "\e67b";
}
.pe-7s-anchor:before {
	content: "\e67c";
}
.pe-7s-albums:before {
	content: "\e67d";
}
.pe-7s-alarm:before {
	content: "\e67e";
}
.pe-7s-airplay:before {
	content: "\e67f";
}

@font-face {
	font-family: 'themify';
	src:url(/static/media/themify.2c454669.eot);
	src:url(/static/media/themify.2c454669.eot?#iefix-fvbane) format('embedded-opentype'),
		url(/static/media/themify.a1ecc3b8.woff) format('woff'),
		url(/static/media/themify.e23a7dca.ttf) format('truetype'),
		url(/static/media/themify.9c8e96ec.svg#themify) format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="ti-"], [class*=" ti-"] {
	font-family: 'themify';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-feature-settings: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.ti-wand:before {
	content: "\e600";
}
.ti-volume:before {
	content: "\e601";
}
.ti-user:before {
	content: "\e602";
}
.ti-unlock:before {
	content: "\e603";
}
.ti-unlink:before {
	content: "\e604";
}
.ti-trash:before {
	content: "\e605";
}
.ti-thought:before {
	content: "\e606";
}
.ti-target:before {
	content: "\e607";
}
.ti-tag:before {
	content: "\e608";
}
.ti-tablet:before {
	content: "\e609";
}
.ti-star:before {
	content: "\e60a";
}
.ti-spray:before {
	content: "\e60b";
}
.ti-signal:before {
	content: "\e60c";
}
.ti-shopping-cart:before {
	content: "\e60d";
}
.ti-shopping-cart-full:before {
	content: "\e60e";
}
.ti-settings:before {
	content: "\e60f";
}
.ti-search:before {
	content: "\e610";
}
.ti-zoom-in:before {
	content: "\e611";
}
.ti-zoom-out:before {
	content: "\e612";
}
.ti-cut:before {
	content: "\e613";
}
.ti-ruler:before {
	content: "\e614";
}
.ti-ruler-pencil:before {
	content: "\e615";
}
.ti-ruler-alt:before {
	content: "\e616";
}
.ti-bookmark:before {
	content: "\e617";
}
.ti-bookmark-alt:before {
	content: "\e618";
}
.ti-reload:before {
	content: "\e619";
}
.ti-plus:before {
	content: "\e61a";
}
.ti-pin:before {
	content: "\e61b";
}
.ti-pencil:before {
	content: "\e61c";
}
.ti-pencil-alt:before {
	content: "\e61d";
}
.ti-paint-roller:before {
	content: "\e61e";
}
.ti-paint-bucket:before {
	content: "\e61f";
}
.ti-na:before {
	content: "\e620";
}
.ti-mobile:before {
	content: "\e621";
}
.ti-minus:before {
	content: "\e622";
}
.ti-medall:before {
	content: "\e623";
}
.ti-medall-alt:before {
	content: "\e624";
}
.ti-marker:before {
	content: "\e625";
}
.ti-marker-alt:before {
	content: "\e626";
}
.ti-arrow-up:before {
	content: "\e627";
}
.ti-arrow-right:before {
	content: "\e628";
}
.ti-arrow-left:before {
	content: "\e629";
}
.ti-arrow-down:before {
	content: "\e62a";
}
.ti-lock:before {
	content: "\e62b";
}
.ti-location-arrow:before {
	content: "\e62c";
}
.ti-link:before {
	content: "\e62d";
}
.ti-layout:before {
	content: "\e62e";
}
.ti-layers:before {
	content: "\e62f";
}
.ti-layers-alt:before {
	content: "\e630";
}
.ti-key:before {
	content: "\e631";
}
.ti-import:before {
	content: "\e632";
}
.ti-image:before {
	content: "\e633";
}
.ti-heart:before {
	content: "\e634";
}
.ti-heart-broken:before {
	content: "\e635";
}
.ti-hand-stop:before {
	content: "\e636";
}
.ti-hand-open:before {
	content: "\e637";
}
.ti-hand-drag:before {
	content: "\e638";
}
.ti-folder:before {
	content: "\e639";
}
.ti-flag:before {
	content: "\e63a";
}
.ti-flag-alt:before {
	content: "\e63b";
}
.ti-flag-alt-2:before {
	content: "\e63c";
}
.ti-eye:before {
	content: "\e63d";
}
.ti-export:before {
	content: "\e63e";
}
.ti-exchange-vertical:before {
	content: "\e63f";
}
.ti-desktop:before {
	content: "\e640";
}
.ti-cup:before {
	content: "\e641";
}
.ti-crown:before {
	content: "\e642";
}
.ti-comments:before {
	content: "\e643";
}
.ti-comment:before {
	content: "\e644";
}
.ti-comment-alt:before {
	content: "\e645";
}
.ti-close:before {
	content: "\e646";
}
.ti-clip:before {
	content: "\e647";
}
.ti-angle-up:before {
	content: "\e648";
}
.ti-angle-right:before {
	content: "\e649";
}
.ti-angle-left:before {
	content: "\e64a";
}
.ti-angle-down:before {
	content: "\e64b";
}
.ti-check:before {
	content: "\e64c";
}
.ti-check-box:before {
	content: "\e64d";
}
.ti-camera:before {
	content: "\e64e";
}
.ti-announcement:before {
	content: "\e64f";
}
.ti-brush:before {
	content: "\e650";
}
.ti-briefcase:before {
	content: "\e651";
}
.ti-bolt:before {
	content: "\e652";
}
.ti-bolt-alt:before {
	content: "\e653";
}
.ti-blackboard:before {
	content: "\e654";
}
.ti-bag:before {
	content: "\e655";
}
.ti-move:before {
	content: "\e656";
}
.ti-arrows-vertical:before {
	content: "\e657";
}
.ti-arrows-horizontal:before {
	content: "\e658";
}
.ti-fullscreen:before {
	content: "\e659";
}
.ti-arrow-top-right:before {
	content: "\e65a";
}
.ti-arrow-top-left:before {
	content: "\e65b";
}
.ti-arrow-circle-up:before {
	content: "\e65c";
}
.ti-arrow-circle-right:before {
	content: "\e65d";
}
.ti-arrow-circle-left:before {
	content: "\e65e";
}
.ti-arrow-circle-down:before {
	content: "\e65f";
}
.ti-angle-double-up:before {
	content: "\e660";
}
.ti-angle-double-right:before {
	content: "\e661";
}
.ti-angle-double-left:before {
	content: "\e662";
}
.ti-angle-double-down:before {
	content: "\e663";
}
.ti-zip:before {
	content: "\e664";
}
.ti-world:before {
	content: "\e665";
}
.ti-wheelchair:before {
	content: "\e666";
}
.ti-view-list:before {
	content: "\e667";
}
.ti-view-list-alt:before {
	content: "\e668";
}
.ti-view-grid:before {
	content: "\e669";
}
.ti-uppercase:before {
	content: "\e66a";
}
.ti-upload:before {
	content: "\e66b";
}
.ti-underline:before {
	content: "\e66c";
}
.ti-truck:before {
	content: "\e66d";
}
.ti-timer:before {
	content: "\e66e";
}
.ti-ticket:before {
	content: "\e66f";
}
.ti-thumb-up:before {
	content: "\e670";
}
.ti-thumb-down:before {
	content: "\e671";
}
.ti-text:before {
	content: "\e672";
}
.ti-stats-up:before {
	content: "\e673";
}
.ti-stats-down:before {
	content: "\e674";
}
.ti-split-v:before {
	content: "\e675";
}
.ti-split-h:before {
	content: "\e676";
}
.ti-smallcap:before {
	content: "\e677";
}
.ti-shine:before {
	content: "\e678";
}
.ti-shift-right:before {
	content: "\e679";
}
.ti-shift-left:before {
	content: "\e67a";
}
.ti-shield:before {
	content: "\e67b";
}
.ti-notepad:before {
	content: "\e67c";
}
.ti-server:before {
	content: "\e67d";
}
.ti-quote-right:before {
	content: "\e67e";
}
.ti-quote-left:before {
	content: "\e67f";
}
.ti-pulse:before {
	content: "\e680";
}
.ti-printer:before {
	content: "\e681";
}
.ti-power-off:before {
	content: "\e682";
}
.ti-plug:before {
	content: "\e683";
}
.ti-pie-chart:before {
	content: "\e684";
}
.ti-paragraph:before {
	content: "\e685";
}
.ti-panel:before {
	content: "\e686";
}
.ti-package:before {
	content: "\e687";
}
.ti-music:before {
	content: "\e688";
}
.ti-music-alt:before {
	content: "\e689";
}
.ti-mouse:before {
	content: "\e68a";
}
.ti-mouse-alt:before {
	content: "\e68b";
}
.ti-money:before {
	content: "\e68c";
}
.ti-microphone:before {
	content: "\e68d";
}
.ti-menu:before {
	content: "\e68e";
}
.ti-menu-alt:before {
	content: "\e68f";
}
.ti-map:before {
	content: "\e690";
}
.ti-map-alt:before {
	content: "\e691";
}
.ti-loop:before {
	content: "\e692";
}
.ti-location-pin:before {
	content: "\e693";
}
.ti-list:before {
	content: "\e694";
}
.ti-light-bulb:before {
	content: "\e695";
}
.ti-Italic:before {
	content: "\e696";
}
.ti-info:before {
	content: "\e697";
}
.ti-infinite:before {
	content: "\e698";
}
.ti-id-badge:before {
	content: "\e699";
}
.ti-hummer:before {
	content: "\e69a";
}
.ti-home:before {
	content: "\e69b";
}
.ti-help:before {
	content: "\e69c";
}
.ti-headphone:before {
	content: "\e69d";
}
.ti-harddrives:before {
	content: "\e69e";
}
.ti-harddrive:before {
	content: "\e69f";
}
.ti-gift:before {
	content: "\e6a0";
}
.ti-game:before {
	content: "\e6a1";
}
.ti-filter:before {
	content: "\e6a2";
}
.ti-files:before {
	content: "\e6a3";
}
.ti-file:before {
	content: "\e6a4";
}
.ti-eraser:before {
	content: "\e6a5";
}
.ti-envelope:before {
	content: "\e6a6";
}
.ti-download:before {
	content: "\e6a7";
}
.ti-direction:before {
	content: "\e6a8";
}
.ti-direction-alt:before {
	content: "\e6a9";
}
.ti-dashboard:before {
	content: "\e6aa";
}
.ti-control-stop:before {
	content: "\e6ab";
}
.ti-control-shuffle:before {
	content: "\e6ac";
}
.ti-control-play:before {
	content: "\e6ad";
}
.ti-control-pause:before {
	content: "\e6ae";
}
.ti-control-forward:before {
	content: "\e6af";
}
.ti-control-backward:before {
	content: "\e6b0";
}
.ti-cloud:before {
	content: "\e6b1";
}
.ti-cloud-up:before {
	content: "\e6b2";
}
.ti-cloud-down:before {
	content: "\e6b3";
}
.ti-clipboard:before {
	content: "\e6b4";
}
.ti-car:before {
	content: "\e6b5";
}
.ti-calendar:before {
	content: "\e6b6";
}
.ti-book:before {
	content: "\e6b7";
}
.ti-bell:before {
	content: "\e6b8";
}
.ti-basketball:before {
	content: "\e6b9";
}
.ti-bar-chart:before {
	content: "\e6ba";
}
.ti-bar-chart-alt:before {
	content: "\e6bb";
}
.ti-back-right:before {
	content: "\e6bc";
}
.ti-back-left:before {
	content: "\e6bd";
}
.ti-arrows-corner:before {
	content: "\e6be";
}
.ti-archive:before {
	content: "\e6bf";
}
.ti-anchor:before {
	content: "\e6c0";
}
.ti-align-right:before {
	content: "\e6c1";
}
.ti-align-left:before {
	content: "\e6c2";
}
.ti-align-justify:before {
	content: "\e6c3";
}
.ti-align-center:before {
	content: "\e6c4";
}
.ti-alert:before {
	content: "\e6c5";
}
.ti-alarm-clock:before {
	content: "\e6c6";
}
.ti-agenda:before {
	content: "\e6c7";
}
.ti-write:before {
	content: "\e6c8";
}
.ti-window:before {
	content: "\e6c9";
}
.ti-widgetized:before {
	content: "\e6ca";
}
.ti-widget:before {
	content: "\e6cb";
}
.ti-widget-alt:before {
	content: "\e6cc";
}
.ti-wallet:before {
	content: "\e6cd";
}
.ti-video-clapper:before {
	content: "\e6ce";
}
.ti-video-camera:before {
	content: "\e6cf";
}
.ti-vector:before {
	content: "\e6d0";
}
.ti-themify-logo:before {
	content: "\e6d1";
}
.ti-themify-favicon:before {
	content: "\e6d2";
}
.ti-themify-favicon-alt:before {
	content: "\e6d3";
}
.ti-support:before {
	content: "\e6d4";
}
.ti-stamp:before {
	content: "\e6d5";
}
.ti-split-v-alt:before {
	content: "\e6d6";
}
.ti-slice:before {
	content: "\e6d7";
}
.ti-shortcode:before {
	content: "\e6d8";
}
.ti-shift-right-alt:before {
	content: "\e6d9";
}
.ti-shift-left-alt:before {
	content: "\e6da";
}
.ti-ruler-alt-2:before {
	content: "\e6db";
}
.ti-receipt:before {
	content: "\e6dc";
}
.ti-pin2:before {
	content: "\e6dd";
}
.ti-pin-alt:before {
	content: "\e6de";
}
.ti-pencil-alt2:before {
	content: "\e6df";
}
.ti-palette:before {
	content: "\e6e0";
}
.ti-more:before {
	content: "\e6e1";
}
.ti-more-alt:before {
	content: "\e6e2";
}
.ti-microphone-alt:before {
	content: "\e6e3";
}
.ti-magnet:before {
	content: "\e6e4";
}
.ti-line-double:before {
	content: "\e6e5";
}
.ti-line-dotted:before {
	content: "\e6e6";
}
.ti-line-dashed:before {
	content: "\e6e7";
}
.ti-layout-width-full:before {
	content: "\e6e8";
}
.ti-layout-width-default:before {
	content: "\e6e9";
}
.ti-layout-width-default-alt:before {
	content: "\e6ea";
}
.ti-layout-tab:before {
	content: "\e6eb";
}
.ti-layout-tab-window:before {
	content: "\e6ec";
}
.ti-layout-tab-v:before {
	content: "\e6ed";
}
.ti-layout-tab-min:before {
	content: "\e6ee";
}
.ti-layout-slider:before {
	content: "\e6ef";
}
.ti-layout-slider-alt:before {
	content: "\e6f0";
}
.ti-layout-sidebar-right:before {
	content: "\e6f1";
}
.ti-layout-sidebar-none:before {
	content: "\e6f2";
}
.ti-layout-sidebar-left:before {
	content: "\e6f3";
}
.ti-layout-placeholder:before {
	content: "\e6f4";
}
.ti-layout-menu:before {
	content: "\e6f5";
}
.ti-layout-menu-v:before {
	content: "\e6f6";
}
.ti-layout-menu-separated:before {
	content: "\e6f7";
}
.ti-layout-menu-full:before {
	content: "\e6f8";
}
.ti-layout-media-right-alt:before {
	content: "\e6f9";
}
.ti-layout-media-right:before {
	content: "\e6fa";
}
.ti-layout-media-overlay:before {
	content: "\e6fb";
}
.ti-layout-media-overlay-alt:before {
	content: "\e6fc";
}
.ti-layout-media-overlay-alt-2:before {
	content: "\e6fd";
}
.ti-layout-media-left-alt:before {
	content: "\e6fe";
}
.ti-layout-media-left:before {
	content: "\e6ff";
}
.ti-layout-media-center-alt:before {
	content: "\e700";
}
.ti-layout-media-center:before {
	content: "\e701";
}
.ti-layout-list-thumb:before {
	content: "\e702";
}
.ti-layout-list-thumb-alt:before {
	content: "\e703";
}
.ti-layout-list-post:before {
	content: "\e704";
}
.ti-layout-list-large-image:before {
	content: "\e705";
}
.ti-layout-line-solid:before {
	content: "\e706";
}
.ti-layout-grid4:before {
	content: "\e707";
}
.ti-layout-grid3:before {
	content: "\e708";
}
.ti-layout-grid2:before {
	content: "\e709";
}
.ti-layout-grid2-thumb:before {
	content: "\e70a";
}
.ti-layout-cta-right:before {
	content: "\e70b";
}
.ti-layout-cta-left:before {
	content: "\e70c";
}
.ti-layout-cta-center:before {
	content: "\e70d";
}
.ti-layout-cta-btn-right:before {
	content: "\e70e";
}
.ti-layout-cta-btn-left:before {
	content: "\e70f";
}
.ti-layout-column4:before {
	content: "\e710";
}
.ti-layout-column3:before {
	content: "\e711";
}
.ti-layout-column2:before {
	content: "\e712";
}
.ti-layout-accordion-separated:before {
	content: "\e713";
}
.ti-layout-accordion-merged:before {
	content: "\e714";
}
.ti-layout-accordion-list:before {
	content: "\e715";
}
.ti-ink-pen:before {
	content: "\e716";
}
.ti-info-alt:before {
	content: "\e717";
}
.ti-help-alt:before {
	content: "\e718";
}
.ti-headphone-alt:before {
	content: "\e719";
}
.ti-hand-point-up:before {
	content: "\e71a";
}
.ti-hand-point-right:before {
	content: "\e71b";
}
.ti-hand-point-left:before {
	content: "\e71c";
}
.ti-hand-point-down:before {
	content: "\e71d";
}
.ti-gallery:before {
	content: "\e71e";
}
.ti-face-smile:before {
	content: "\e71f";
}
.ti-face-sad:before {
	content: "\e720";
}
.ti-credit-card:before {
	content: "\e721";
}
.ti-control-skip-forward:before {
	content: "\e722";
}
.ti-control-skip-backward:before {
	content: "\e723";
}
.ti-control-record:before {
	content: "\e724";
}
.ti-control-eject:before {
	content: "\e725";
}
.ti-comments-smiley:before {
	content: "\e726";
}
.ti-brush-alt:before {
	content: "\e727";
}
.ti-youtube:before {
	content: "\e728";
}
.ti-vimeo:before {
	content: "\e729";
}
.ti-twitter:before {
	content: "\e72a";
}
.ti-time:before {
	content: "\e72b";
}
.ti-tumblr:before {
	content: "\e72c";
}
.ti-skype:before {
	content: "\e72d";
}
.ti-share:before {
	content: "\e72e";
}
.ti-share-alt:before {
	content: "\e72f";
}
.ti-rocket:before {
	content: "\e730";
}
.ti-pinterest:before {
	content: "\e731";
}
.ti-new-window:before {
	content: "\e732";
}
.ti-microsoft:before {
	content: "\e733";
}
.ti-list-ol:before {
	content: "\e734";
}
.ti-linkedin:before {
	content: "\e735";
}
.ti-layout-sidebar-2:before {
	content: "\e736";
}
.ti-layout-grid4-alt:before {
	content: "\e737";
}
.ti-layout-grid3-alt:before {
	content: "\e738";
}
.ti-layout-grid2-alt:before {
	content: "\e739";
}
.ti-layout-column4-alt:before {
	content: "\e73a";
}
.ti-layout-column3-alt:before {
	content: "\e73b";
}
.ti-layout-column2-alt:before {
	content: "\e73c";
}
.ti-instagram:before {
	content: "\e73d";
}
.ti-google:before {
	content: "\e73e";
}
.ti-github:before {
	content: "\e73f";
}
.ti-flickr:before {
	content: "\e740";
}
.ti-facebook:before {
	content: "\e741";
}
.ti-dropbox:before {
	content: "\e742";
}
.ti-dribbble:before {
	content: "\e743";
}
.ti-apple:before {
	content: "\e744";
}
.ti-android:before {
	content: "\e745";
}
.ti-save:before {
	content: "\e746";
}
.ti-save-alt:before {
	content: "\e747";
}
.ti-yahoo:before {
	content: "\e748";
}
.ti-wordpress:before {
	content: "\e749";
}
.ti-vimeo-alt:before {
	content: "\e74a";
}
.ti-twitter-alt:before {
	content: "\e74b";
}
.ti-tumblr-alt:before {
	content: "\e74c";
}
.ti-trello:before {
	content: "\e74d";
}
.ti-stack-overflow:before {
	content: "\e74e";
}
.ti-soundcloud:before {
	content: "\e74f";
}
.ti-sharethis:before {
	content: "\e750";
}
.ti-sharethis-alt:before {
	content: "\e751";
}
.ti-reddit:before {
	content: "\e752";
}
.ti-pinterest-alt:before {
	content: "\e753";
}
.ti-microsoft-alt:before {
	content: "\e754";
}
.ti-linux:before {
	content: "\e755";
}
.ti-jsfiddle:before {
	content: "\e756";
}
.ti-joomla:before {
	content: "\e757";
}
.ti-html5:before {
	content: "\e758";
}
.ti-flickr-alt:before {
	content: "\e759";
}
.ti-email:before {
	content: "\e75a";
}
.ti-drupal:before {
	content: "\e75b";
}
.ti-dropbox-alt:before {
	content: "\e75c";
}
.ti-css3:before {
	content: "\e75d";
}
.ti-rss:before {
	content: "\e75e";
}
.ti-rss-alt:before {
	content: "\e75f";
}

h6, .h6 {
    margin: .5rem;
    font-size: 0.7rem;
    font-weight: 600;
    text-transform: uppercase; 
}
.input-group-prepend span {
    padding: 2px 10px;
    margin-left: 0;
    height: 30px;
    border-radius: 2px;
}
.input-group-append span {
    padding: 2px 10px;
    margin-left: 0;
    height: 30px;
    border-radius: 2px;
}
.transacs-tabs {
    margin-bottom: -47px;
}
.transacs-tabs a {
    text-transform: capitalize;
    font-size: 1.5rem;
    font-weight: 300;
    z-index: 100;
    padding: 5px 15px;
    color: #1d7acb;
}
.transacs-tabs.nav-tabs .nav-link {
    border-top-left-radius: .1rem;
    border-top-right-radius: .1rem;
}
.bzr-home header div {
    max-width: 1100px;
    margin: auto;
}
.bzr-home div div {
    max-width: 1100px;
    margin: auto;
}
.bzr-form h4, .h4 {
    font-size: 20px;
    line-height: 30px; 
    margin-top: 0px;
    margin-bottom: .5rem;
    text-transform: capitalize;
}
.bzr-form {
    max-width: 768px;
    margin: 1rem auto;
    padding: 1rem;
}
label.col-form-label {
    padding-top: 5px;
}
.dc-popover a {
    color: #174ab1;
    font-weight: 600;
    text-transform: uppercase;
}
.dc-popover #delete.a {
    color: red;
}
.custom-select {
    height: 30px;
    padding: 0;
    padding-left: 10px;
    padding-right: 25px;
    text-transform: capitalize;
    font-size: .9rem;
    font-weight: 600;
}
.bzr-inventory-input select {
    padding: 0;
    padding-left: 10px;
    text-align: right;
    text-transform: uppercase;
    border: none;
    font-size: .7rem !important;
    font-weight: 600;
    background-color: #f3f3f3;
    color: dimgray;
    height: 20px;
}
.bzr-inventory-input label {
    margin-top: 1px;
    font-size: .7rem !important;
    font-weight: 600;
    color: #1d7acb !important;
    text-transform: uppercase;
    text-align: right;
}
#search-box {
    border: none;
    border-bottom: solid gray 1px;
}

.display-5 {
    font-size: 2rem;
    font-weight: 300;
    line-height: 1.2;
    padding: 10px;
}

.text-white div label {
    color: white !important;
}

.auth-form{
    max-width: 480px;
    min-width: 380px;
}

.header {
    margin-bottom: 2.5rem;
}

.card-out {
    font-size: 0.85rem;
    margin-bottom: -25px !important;
}

::-webkit-input-placeholder {
    color: #999 !important;
}

:-ms-input-placeholder {
    color: #999 !important;
}

::-ms-input-placeholder {
    color: #999 !important;
}

::placeholder {
    color: #999 !important;
}

.form-group {
    margin-bottom: 0.5rem;
}

.form-control {
    font-size: 0.9rem;
}

.form-control-resize > div {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}
.form-row {
    margin: 0;
}
.form-amount {
    border: none;
    font-size: 1.2rem;
    font-weight: 600;
}

.input-group.date-picker > .react-datepicker-wrapper {
    border: 1px solid #ced4da;
    border-left: none;
    border-radius: 0 3px 3px 0;
}
.input-group.date-picker > .react-datepicker-wrapper input.form-control{
    height: 25px;
    text-align: center;
    border: none;
}
.input-group.date-picker > .input-group-prepend > .input-group-text {
    padding: 0 5px;
}
.react-datepicker-wrapper .form-control {
    width: 110px;
    align-content: center;
}

nav #user-nav-dropdown .dropdown-menu {
    left: unset;
    right: 0 !important;
}
nav #user-nav-dropdown .dropdown-menu::before {
    left: 275px;
}
nav #user-nav-dropdown .dropdown-menu::after {
    left: 275px;
}

nav .user-dropdown > a {
    text-transform: capitalize;
}
nav .user-dropdown .dropdown-menu {
    width: 350px;
    height: 300px;
    padding: 1rem;
}

/* POS */
.bzr-pos {
    max-width: 1200;
    margin: 1rem auto;
    padding: 1rem;
}
.bzr-pos-grid-1 {
    padding-right: 3rem;
    padding-left: 1rem;
}
.bzr-pos-grid-2 {
    background-color: #1d7acb22;
    margin-left: .8rem;
    padding: 2px 1rem;
}
.bzr-pos-bill button {
    min-width: 200px;
}

/* filters */
.filt .dropdown {
    display: inline;
}
.filt .dropdown .dropdown-toggle.nav-link {
    display: inline;
}

.filt .dropdown-toggle::after {
    content: none;
}

.filt .dropdown > .dropdown-menu {
    min-width: 250px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-transform: capitalize;
}

.filt .dropdown > .dropdown-menu:before {
    border-bottom: 11px solid rgba(0,0,0);
    border-left: 11px solid  rgba(0,0,0,0);
    border-right: 11px solid rgba(0,0,0,0);
    content: "";
    display: inline-block;
    position: absolute;
    left: 12px;
    top: -11px;
    }
.filt .dropdown > .dropdown-menu:after {
    border-bottom: 11px solid #fff;
    border-left: 11px solid  rgba(0,0,0,0);
    border-right: 11px solid rgba(0,0,0,0);
    content: "";
    display: inline-block;
    position: absolute;
    left: 12px;
    top: -11px;
    }

.filt .filter-list .table thead tr:first-child th input.form-control  {
    display: inline-block !important;

}

.filt .filter-list .table thead tr:first-child th span  {
    margin-left: 10px;
    font-size: 1rem;
}

.filt .filter-list .table tbody td:first-child {
    width: 20px;
}
.filt .filter-list .table tbody tr:first-child td {
    font-weight: 600 !important;
}

div.filter-list table {
    min-width: 300px;
}
div.filter-list table > thead > tr > th div span {
    float: right !important;
    padding: 10px 10px 0px 0px;
}
div.filter-list table > thead > tr > th div > div {
    float: left !important;
    padding-top: 10px;
    padding-bottom: 0px;
}
div.filter-list table > thead > tr > th div > div > label {
    margin-left: 10px;
    font-weight: 600;
    color: #1d7acb;
}
.filter-list button {
    margin-top: 5px;
    margin-left: auto;
    padding: 0px 5px 1px 5px;
    font-size: .9rem;
    font-weight: 600;
}

/* Load Table*/
.load-table {
    margin: auto;
    width: 95%;
}
.load-table .table-responsive {
    overflow-x: auto;
}

/* list */
table.list > thead > tr > th {
    font-size: .8rem;
}
table.list > tbody > tr > td {
    font-size: .8rem;
}
table.list > tbody > tr > td.number-format {
    font-size: .85rem;
}

.bzr-table-actions.dropdown > div {
    padding: 10px 25px 10px 5px;
    min-width: 300px;
}
.bzr-table-actions.dropdown > div > a {
    text-transform: capitalize;
    font-size: 0.9rem;
    font-weight: 600;
    color: #333;
    padding: 2px 5px;
    border-radius: 0;

}
button.btn {
    border-radius: .2rem;
}

.bzr-table-actions button.btn {
    padding: 0 7px;
    line-height: 1rem;
}
.bzr-table-actions.dropdown > div > a:hover {
    background-color: #5fb4ff;
    color: #fff;
}
.bzr-table-actions.dropdown > div > a.disabled {
    color: #999;
}
.bzr-table-actions.dropdown > div > form {
    margin-left: 40px;
}
.bzr-row-bold > td {
    font-weight: 600;
}

.font-icon-detail span {
    display: inline-block;
    transition: color 150ms linear, background 150ms linear, font-size 150ms linear, width 150ms;
    padding: 2px 0px;
    font-size: 20px;
    width: 35px;
    margin: 5px;
    color: #1a1a1a;
    border: 1px solid #eaeaea;
    border-radius: 3px;
}
.font-icon-detail span:hover {
    transition: color 150ms linear, background 150ms linear, font-size 150ms linear, width 150ms;
    color: #eaeaea;
    background-color:  #1a1a1a;
}
.font-icon-detail > div {
    display: inline;
}
/*  ADMIN-NAVBAR  */
.admin-navbar .nav-item {
    padding-top: 7px;
}
.admin-navbar #active-bu.nav-item {
    margin-right: 20px;
    padding-top: 10px;
    color: #555;
    font-size: 1.1rem;
    font-weight: 600;
    text-transform: capitalize;
    cursor: pointer;
}
.admin-navbar .nav-item a.nav-link {
    margin: 0px;
    padding: 5px 5px;
}

.guest-navbar .navbar-collapse.collapse.show,
.guest-navbar .navbar-collapse.collapse.in {
  display: block !important; }

.guest-navbar .navbar-collapse.collapse.show a {
    padding: .2rem 1rem;
}
.guest-navbar .navbar-collapse.collapse.show .dropdown-menu.show {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
  

button.navbar-toggler {
    padding-top: .7rem;
    padding-bottom: 0;
} 

.navbar-light span.navbar-toggler-icon {
    background-image: none;
}

.navbar-light span.navbar-toggler-icon::after {
    font-family: "Font Awesome 5 Free";
    font-size: 1.5rem;
    content: "\f0c9";
}

@media (max-width: 991px) {
    .nav {
        display: block;
    }
}
@media (max-width: 991px) {
    .sidebar .sidebar-wrapper > .nav > div .nav > div > a {
        margin: 0px 15px;
    }
}

@media (max-width: 769px) {
    .form-control-resize > div {
        width: 90%;
    }
}


@media (min-width: 992px) {
.card form [class*="col-"]:last-child {
    padding-right: 0px;
    }
}

/************************/
/*     04. Sidebar     */
/************************/



#hide-button {
    background-color: #1d7acbad;
    opacity: 0.8;
    color: #eee;
    position: absolute;
    top: 43px;
    left: 225px;
    width: 13px;
    height: 35px; 
    padding-top: 8px;
    padding-right: 2px;
    font-size: 12px;
    font-weight: 400;
    transition: left 0.5s;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: 6100;
}

#hide-button:hover {
    background-color: #1d7acb;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, 0.5);
}

.hide #hide-button {
    left: 70px;
    transition: left 0.5s;
}

.sidebar {
    width: 225px;
    transition: width 0.5s;
    z-index: 6000;
}

.sidebar.hide {
    width: 70px;
    transition: width 0.5s;
}

.sidebar.hide .sidebar-wrapper {
    overflow: visible !important;
    width: 70px;
    /* transition: width 0.5s; */
}
.main-panel {
    background: rgba(203, 203, 210, 0.15);
}
.main-panel > .content {
    padding: 30px 15px;
    min-height: calc(100% - 123px); 
}
.main-panel > .footer {
    border-top: 1px solid #e7e7e7; 
}
.main-panel .navbar {
    margin-bottom: 0; 
}
.sidebar,
.main-panel {
    transition-property: top,bottom;
    transition-duration: .2s,.2s;
    transition-timing-function: linear,linear;
    -webkit-overflow-scrolling: touch; 
}
.main-panel.hide {
    width: calc(100% - 70px);
    transition: width 0.5s;
}

.sidebar.hide .nav li.active > a {
    color: #FFFFFF;
    opacity: 1;
    background: rgba(255, 255, 255, 0.23);
}
.sidebar.hide ul > li {
    width: 70px;
    /* transition: width 0.5s; */
}

.sidebar hr {
    width: 225px;
    margin-top: 1rem;
    border-top: 1px solid rgba(255, 255, 255, 0.7);
}
.sidebar.hide hr {
    width: 70px;
    /* transition: width 0.5s */;
}

.sidebar a {
    height: 50px;
}

.sidebar .logo .simple-text,
body > .navbar-collapse .logo .simple-text {
  display: block;
  margin-left: 15px;
  color: #FFFFFF;
  font-weight: 400;
  line-height: 30px; 
}

.sidebar .logo a.logo-normal {
    display: block; 
    font-size: 28px;
    padding-top: 15px;
  }
.sidebar .logo a.logo-normal span {
    font-style: italic;
    font-weight: 500;
    font-size: 10px;
}

.sidebar.hide .logo > a.logo-normal {
    display: none;
}

.sidebar.hide .nav > li > a > p {
    display: none;
}

.sidebar.hide .nav a::after {
    display: none;
}
.sidebar.hide .nav a > div > p {
    display: none;
}

.sidebar .dropdown.nav-item {
    width: 100%;
}

.sidebar .dropdown.nav-item {
    width: 100%;
}
.sidebar.hide div.dropdown-menu.show {
    background-color: #515158;
    position: absolute !important;
    transform: none !important;
    top: 0px !important;
    left: 70px !important;
}
.sidebar.hide .show .dropdown-menu a {
    background-color: #515158;
    height: 30px;
}

.sidebar.hide .nav a.dropdown-item {
    width: calc(100% - 10px) !important;
    padding: 0px;
    margin: 5px 5px !important;
}
.sidebar.hide .nav a:hover.dropdown-item {
    background-color: #ccc;
}


/************************/
/*     04. Buttons     */
/************************/

button.btn.btn-trash {
    padding: 0 5px;
}

button.btn.btn-download {
    background-color: #fff;
    color: #1dc7ea;
    padding: 0 5px;
}

.new-sale-btns .btn {
    font-size: 1rem;
    text-transform: capitalize;
    font-weight: 600;
    margin: auto 20px;
    padding: auto 50px;
    min-width: 150px;
}

.new-sale-btns .btn#clear {
    margin: auto;
    min-width: 100px;
}
.new-item-btn button {
    width: 100%;
    padding: 5px;
    text-transform: capitalize;
    font-weight: 600;
    border-radius: .1rem;
}

/************************/
/*     05. Features     */
/************************/
.tabs {
	padding-top: 2.5rem;
	padding-bottom: 2.5rem;
	background-color: #eee;
}

.tabs h2 {
	margin-bottom: 1.125rem;
    text-align: center;
    color: #717182;
    font: 700 2.25rem/2.75rem "Montserrat", sans-serif !important;
    text-transform: uppercase;
}

.tabs .p-heading {
	margin-bottom: 3.125rem;
}

.tabs .nav-tabs {
	margin-right: auto;
	margin-bottom: 2.5rem;
	margin-left: auto;
	justify-content: center;
	border-bottom: none;
}

.tabs .nav-link {
	margin-bottom: 1rem;
	padding: 0.5rem 1.375rem 0.25rem 1.375rem;
	border: none;
	border-bottom: 0.1875rem solid #f1f1f8;
	border-radius: 0;
	color: #515158;
	font: 700 1rem/1.75rem "Montserrat", sans-serif;
	text-decoration: none;
	transition: all 0.2s ease;
}

.tabs .nav-link.active,
.tabs .nav-link:hover {
	border-bottom: 0.1875rem solid #00c9db;
	background-color: transparent;
	color: #00c9db;
}

.tabs .nav-link .fas {
	margin-right: 0.375rem;
	font-size: 1rem;
}

.tabs .tab-content {
	width: 100%; /* for proper display in IE11 */
}

.tabs .card {
    border: none;
    box-shadow: none;
    background: transparent;
    margin-bottom: 0;
}

.tabs .card-body {
	padding: 1rem 0 1.25rem 0;
}

.tabs .card-title {
    
	margin-bottom: 0.5rem;
}

.tabs h4.card-title {
    color: #515158;
    font: 700 1.375rem/1.75rem "Montserrat", sans-serif !important;
    text-transform: capitalize;
}

.tabs .card .card-icon {
	display: inline-block;
	width: 3.5rem;
	height: 3.5rem;
	border-radius: 50%;
	background-color: #00c9db;
	text-align: center;
	vertical-align: top;
}

.tabs .card .card-icon .fas,
.tabs .card .card-icon .far {
	color: #fff;
	font-size: 1.75rem;
	line-height: 3.5rem;
}

.tabs #tab-1 .card.left-pane .text-wrapper {
	display: inline-block;
	width: 75%;
}

.tabs #tab-1 .card.left-pane .card-icon {
	float: left;
	margin-right: 1rem;
}

.tabs #tab-1 img {
	display: block;
	margin: 2rem auto 3rem auto;
}

.tabs #tab-1 .card.right-pane .text-wrapper {
	display: inline-block;
	width: 75%;
}

.tabs #tab-1 .card.right-pane .card-icon {
	margin-right: 1rem;
}

.tabs #tab-2 img {
	display: block;
	margin: 0 auto 2rem auto;
}

.tabs #tab-2 .text-area {
	margin-top: 1.5rem;
}

.tabs #tab-2 h3 {
	margin-bottom: 0.75rem;
}

.tabs #tab-2 .icon-cards-area {
	margin-top: 2.5rem;
}

.tabs #tab-2 .icon-cards-area .card {
	width: 100%; /* for proper display in IE11 */
}

.tabs #tab-3 .icon-cards-area .card {
	width: 100%; /* for proper display in IE11 */
}

.tabs #tab-3 .text-area {
	margin-top: 0.75rem;
	margin-bottom: 4rem;
}

.tabs #tab-3 h3 {
	margin-bottom: 0.75rem;
}

.tabs #tab-3 img {
	margin: 0 auto 3rem auto;
}

.tabs .card-body .text-wrapper p {
    font-size: 0.9rem;
}
.bzr-home header {
    margin: auto auto 50px;
}

.bzr-home .header-info > h1 {
    font-style: italic !important;
}
.bzr-home .header-info > h1:first-child {
    margin-top: 0px !important;
}
.bzr-home .header-info > h1:not(:first-child) {
    margin-top: 20px !important;
    font-style: italic !important;
}

.bzr-home .header-info > p {
    color: #555;
    font: 400 1.15rem "Open Sans", sans-serif;
    line-height: 35px;
}
.bzr-home .header-image {
    max-width: 400px;
}
.bzr-home p::first-letter { 
    text-transform: uppercase;
}
#features .card-body {
    margin: auto 10px;
}
table.bzr-table > tbody > tr {
    cursor: context-menu;
}
@media (min-width: 768px) {
    .p-heading {
        width: 85%;
        margin-right: auto;
        margin-left: auto;
        }
    .col-md-4 {
        flex: 0 0 33.333333%;
        max-width: 100%;
        }
}
@media screen and (min-width: 1281px) {
    table.bzr-table > thead > tr > th {
        font-size: 0.9rem;
    }
    table.bzr-table > tbody > tr > td {
        font-size: 0.9rem;
    }
    table.items > thead > tr > th {
        font-size: .9rem;
    }
    
    table.items > tbody > tr > td {
        font-size: .9rem;
    }
    table.items > tbody > tr > td.number-format {
        font-size: 1rem;
    }
    /* list */
    table.list > thead > tr > th {
        font-size: .9rem;
    }
    table.list > tbody > tr > td {
        font-size: .9rem;
    }
    table.list > tbody > tr > td.number-format {
        font-size: 1rem;
    }

    .p-heading {
        width: 44%;
        }
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-fluid {
        max-width: 1400px;
    }
}

.upload-image {
    background-image: url(/static/media/upload-image.dee75d72.png);
    background-repeat: no-repeat;
    background-position: center;
}

.center-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80px;
    height: 80px;
    margin-top: 60px !important;
}

table.bzr-table {
    min-height: 200px;
    display: block;
    
    text-align: left;
    overflow-x: auto !important;
/*     width: fit-content;*/
    margin-left: auto;
    margin-right: auto; 
}

table.bzr-table > thead > tr > th {
    background-color: gray;
    color: #fff !important;
    vertical-align: top;
    text-align: center;
    font-size: .75rem;
    /* border-top: solid #999 1px; */
    border-bottom: solid #999 1px;
    padding: 5px 0;
}
table.bzr-table thead > tr > th > div {
    vertical-align: top;
}
table.bzr-table > thead > tr > th > div > div {
    display: inline-block;
}
table.bzr-table > thead > tr > th > div > div > a {
    padding: 0px 3px;
}
.card table.bzr-table > thead > tr > th:first-child {
    min-width: 20px;
    padding: 5px 7px;
}
.card table.bzr-table > tbody > tr > td:first-child {
    min-width: 20px;
    padding: 5px 7px;
}

table.bzr-table > tbody > tr > td {
    border-top: solid #999 1px;
    text-align: center;
    vertical-align: top;
    font-size: .75rem;
}
table.bzr-table thead > tr > th > div, table.bzr-table thead > tr > th > div.dropdown.nav-item > a > div {
    color: #fff !important;
    font-weight: 600;
}
table.bzr-table thead > tr > th > div.dropdown.nav-item > a {
    padding-top: 0;
}

table.bzr-table tfoot {
    font-size: .75rem;
    text-align: left;
    color: #eee;
}
table.bzr-table tfoot > tr > td span {
    font-size: .75rem;
    margin: 0;
    padding: 0;
    width: auto;
    color: #eee;
    border: none;
    float: right;
}
.bzr-table-toolbar {
    float: right;
}
.bzr-table-toolbar span {
    font-size: 1.2rem;
}

.bzr-mini-table {
    font-size: 0.75rem;
    font-weight: 600;
}

.bzr-lateral-box {
    height: 100vh;
    /* width: calc(100vh*0.78 + 300px); */
    width: calc(100vw - 225px);
    position: fixed;
    top: 0px;
    right: -100vw;
    transition: right 300ms linear;
    z-index: 5000;
    box-shadow: -5px 0px 30px #000000ac;
    background-color: #000000aa;
    overflow-y: scroll;
}
.bzr-lateral-box.hide {
    width: calc(100vw - 70px);
}
.bzr-lateral-box.show{
    right: 0px;
    transition: right .5s linear;
}
.bzr-lateral-box object {
    height: 100vh;
    width: calc(100vh*0.78);
}
.bzr-lateral-box .menu {
    max-width: 300px;
    background-color: white;
    margin-left: auto;
    height: 100vh;
}
.bzr-lateral-box .object {
    width: calc(100vh*0.78);
}
.bzr-lateral-box .lateral-form {
    height: 100vh;
    max-width: 1200px;
    background-color: white;
    margin-left: auto;
}
.bzr-lateral-box .lateral-form .input-group-text {
    padding: 2px 10px;
    margin-left: 0;
    height: 30px;
    border-radius: 2px;
}
.bzr-lateral-box .form-1 {
    margin: 0 auto;
    width: 90%;
}
.bzr-lateral-box .fas.fa-times {
    font-size: 30px !important;
    color: dimgray !important;
}
.bzr-lateral-nav hr {
    width: 100%;
    margin: .5rem;
}
.bzr-lateral-nav .nav-link {
    color: #555;
    font-size: .95rem;
    font-weight: 600;
    text-transform: capitalize;
}
.bzr-lateral-nav .nav-link:hover {
    color: #fff;
    background-color: #1d7acbad;
    border-radius: 3px;
}
.bzr-lateral-nav .nav-link.disabled {
    color: #999;
}
.bzr-lateral-nav form {
    margin-left: 20px;
}
.bzr-lateral-nav form > div > label:hover {
    text-decoration: underline;
}
div.bzr-lateral-tabs{
    margin-bottom: 1rem;
}
div.bzr-lateral-tabs div > a {
    padding: .2rem .5rem;
    text-transform: capitalize;
    font-weight: 600;
}
div.bzr-tab-pane-scrollable {
    height: 400px;
    overflow-y: auto;
}
input.cell-number {
    border: none;
    text-align: right;
}
.bzr-lateral-scrollable-100vh {
    height: calc(100vh);
    overflow: scroll;
}
.bzr-lateral-scrollable-75vh {
    height: calc(75vh);
    overflow: scroll;
}
.bzr-lateral-scrollable-50vh {
    height: calc(50vh);
    overflow: scroll;
}

/* table.bzr-table {
    width: 100%;
}
table.bzr-table tbody{
    display:block;
    overflow:auto;
    height:200px;
    width:100%;
}
table.bzr-table thead tr{
    display:block;
}
table.bzr-table tbody tr{
    display:block;
} */

table.items > thead > tr > th {
    text-align: center;
    vertical-align: top;
    font-size: .8rem;
}

table.items > tbody > tr > td {
    vertical-align: top;
    font-size: .8rem;
}
table.items > tbody > tr > td.number-format {
    font-size: .85rem;
}

/* The animation code */
@-webkit-keyframes flash-text {
    from {
        text-shadow: 0px 0px 3px yellow;
        color: orangered;
        font-weight: 600;
    }
    to {
        font-weight: 400;
        text-shadow: none;
        color: #000;
        color: initial;
    }
  }
@keyframes flash-text {
    from {
        text-shadow: 0px 0px 3px yellow;
        color: orangered;
        font-weight: 600;
    }
    to {
        font-weight: 400;
        text-shadow: none;
        color: #000;
        color: initial;
    }
  }
  
/* The element to apply the animation to */
table.flash-text-animation.items > tbody > tr > td.number-format {
    -webkit-animation-name: none;
            animation-name: none;
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
}

table.items > tbody p{
    padding: 0;
    margin: 0;
    font-size: .75rem;
}

/* PRICES */
.price-presets-modal {
    min-width: 700px;
}

table.prices > thead > tr > th {
    text-align: center;
    vertical-align: top;
    font-size: .7rem;
}
table.prices > thead > tr:first-child > th{
    color: white !important;
}
table.prices > tbody > tr > td {
    vertical-align: top;
    font-size: .7rem;
}
table.prices > tbody p{
    padding: 0;
    margin: 0;
    font-size: .75rem;
}

.dropdown.icon-dropdown {
    padding: 0;
    margin: 0;
}
.dropdown.icon-dropdown span {
    margin: 0;
}
.dropdown.icon-dropdown button {
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
}

.dropdown.icon-dropdown button::after {
    content: none;
}
/***********************/
table.totals-table tr > td {
    padding: 0 0 5px;
    border: none;
    text-transform: uppercase;
}
table.totals-table tbody > tr:first-child > th {
    border-top: none;
}
table.totals-table tr > th {
    padding: 0 0 5px;
    text-transform: uppercase;
}

table.totals-table > tbody > tr > td {
    font-size: 0.7rem;
}
table.totals-table > tbody > tr > th {
    font-size: 1.1rem;
}
table.totals-table > tbody > tr.sub-total > th {
    font-size: 0.9rem;
}
table.preview-table > tbody > tr > td, th {
    padding: 0;
}
.card .table tbody td:last-child, .card .table thead th:last-child {
    padding-right: 0px;
    }


div.bzr-cblist .dropdown button.btn {
    border-radius: .1rem;
}
div.bzr-cblist .dropdown button.btn:after {
    display: none
}

div.cblist-menu {
    position: absolute;
    min-width: 350px;

    max-width: 370px;
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    top: 0px;
    transform: translate3d(calc(-100% + 33px), 31px, 0px) !important; 
    border-radius: .2rem;
    border: none;
    text-transform: capitalize;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    
    max-height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 3000;
}
div.cblist-menu > a {
    font-size: 0.8rem;
    padding: 7px 10px 0;
    font-weight: 600;
}
div.bzr-cblist .cblist-menu > a:hover {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px;
    background-color: #5fb4ff;
    color: #fff;
}
div.bzr-cblist .dropdown-menu > a:first-child {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}
div.bzr-cblist .dropdown-menu > a:last-child {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}
div.cblist-menu > a:focus {
    background-color: #1d7fd4;
    color: #fff;
}

div.cblist-options {
    display: inline;
}
div.cblist-options button {
    border: none;
    margin-top: 0px;
    font-size: 12px;
}
div.cblist-options .form-group {
    margin: 5px 15px;
}

.list-group-item {
    padding: 0.5rem;
}

/*************************/
/*       04. Modals      */
/*************************/
.modal.show {
    z-index: 7000;
}

.modal-preview {
    color: #000;
    width: calc(100vh*0.78 + 300px);
    max-width: none!important;
    margin-right: calc(100vh*0.11);
}

.modal-confirm {
    color: #000;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 1rem;
    margin: 0 auto;
}

.modal-preview .modal-content {
    height: 100vh !important;
    background-color: rgba(0,0,0,.7) !important;
    padding-left: 1rem;
    z-index: 7010;
}

.modal-preview object {
    width: calc(95vh*0.78);
    height: 95vh;
}

.modal-100vw {
    width: 100%;
    max-width: none!important;
}

.modal-100vw .modal-content {
    background-color: #000;
    border-radius: 0;
}

.modal-preview .btns .btn {
    padding: .1rem .5rem;
    margin: .5rem .5rem .5rem;
    font-weight: 500;
}

.modal-preview .btns .btn.download {
    padding: .2rem .5rem;
    margin: 0 .5rem .5rem;
}

.modal-preview .btns a {
    color: rgb(0, 102, 255);
    font-size: 0.9rem;
    font-weight: 600;
}
.modal-preview .btns a:hover {
    color: #777;
}
@media (min-width: 576px) {
    .modal-dialog.modal-parent {
        width: -webkit-fit-content !important;
        width: -moz-fit-content !important;
        width: fit-content !important;
        max-width: none;
        margin: 1.75rem auto;
    }
}
.modal-parent .modal-content {
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
}
.modal-parent button {
    text-transform: capitalize;
    font-size: 0.9rem;
    font-weight: 600;
    margin: auto 0px;
}
.modal-parent i {
    font-size: 30px;
}
.modal-parent label {
    font-size: .7rem !important;
    font-weight: 600 !important;
}

/*******************************/
/*       04. Enroll Steps      */
/*******************************/

.enrollment-progress {
}

.card-focus {
    box-shadow: 0 0.0625rem 0.375rem 0 rgb(0, 102, 255);
}

input.form-control:focus {
    box-shadow: 0 0.0625rem 0.375rem 0 rgb(0, 102, 255);
}

.main-container {
    min-height: calc(100vh - 180px) !important;
}

.bzr-form label {
    font-weight: 600 !important;
    font-size: 0.75rem;
    color: #555
}

.bzr-label {
    font-size: 0.75rem !important;
    padding: 10px 0 5px;
    text-transform: capitalize;
    font-weight: 600;
    color: #555;
}
.bzr-labels {
    text-transform: capitalize;
    font-weight: 600 !important;
    color: #555 !important;
}

.lateral-dialog label {
    font-size: 1rem !important;
    text-transform: capitalize;
}
.lateral-dialog input {
    margin-top: .2rem;
    font-size: 1.1rem !important;
}
table.lateral-items {
    margin: .5rem auto;
    width: 100%;
}
table.lateral-items > thead > tr > th {
    font-size: .75rem !important;
}
table.lateral-items > tbody > tr > td {
    font-size: 0.75rem !important;
    vertical-align: top;
}
table.lateral-items > tbody > tr > td > p {
    font-size: 0.75rem !important;
    margin-bottom: .3rem;
}
table.lateral-totals{
    margin-left: auto;
    max-width: 450px;
}
table.lateral-totals > thead > tr > th {
    font-size: .75rem !important;
    text-transform: capitalize;
}
table.table.lateral-totals > tbody > tr > td {
    font-size: 0.75rem !important;
    text-transform: capitalize;
    vertical-align: top;
    padding: 2px;
    border: none;
}
table.table.lateral-totals > tbody > tr > th {
    text-transform: capitalize;
    font-size: 0.8rem !important;
    vertical-align: top;
    padding: 2px;
}
table.lateral-totals > tbody > tr > td > p {
    text-transform: capitalize;
    font-size: 0.8rem !important;
}

.jumbotron {
    padding: 2rem 2rem;
}

/* new-product*/
.accordion.new-product .card-header {
    margin: .8rem auto .3rem;
    padding: .4rem 1rem;
    background-color: rgba(0, 85, 160, 0.9);
}
.accordion.new-product .card-header h5{
    width: 120px;
    color: rgb(255, 255, 220);
    font-weight: 600 !important;
}
.accordion.new-product .card-header h5 .caret{
    float: right !important;
    font-size: 1.5rem;
}
.accordion.new-product .card-header h6{
    padding: 0;
    color: rgb(255, 255, 200);
    font-size: .75rem;
    font-weight: 600 !important;
}
.accordion.new-product .card-header .d-flex{
    color: rgb(255, 255, 200);
    font-size: .75rem;
}
.accordion.new-product .form-row {
    margin-bottom: 0.1rem;
}
/* edit-product*/
.accordion.edit-product .card-header {
    margin: .8rem auto .3rem;
    padding: .4rem 1rem;
    background-color: dimgray;
}
.accordion.edit-product .card-header h5{
    width: 120px;
    color: #eee;
    font-weight: 600 !important;
}
.accordion.edit-product .card-header h5 .caret{
    float: right !important;
    font-size: 1.5rem;
}
.accordion.edit-product .card-header h6{
    padding: 0;
    color: #eee;
    font-size: .75rem;
    font-weight: 600 !important;
}
.accordion.edit-product .card-header .d-flex{
    color: #eee;
    font-size: .7rem;
}

.accordion.edit-product .form-row {
    margin-bottom: 0.1rem;
}

p.accordion-card-footer {
    font-size: 0.8rem;
    color: #555;
    text-transform: capitalize;
}

.accordion > .card.let-overflow {
    overflow: visible !important;
}

.accordion-edit-label {
    color: #1d7acb;
}

.accordion-edit-label > span {
    font-size: 1.8rem;
}

.language-select {
    display: inline-block;
    width: 55px;
    margin-top: 1rem;
    margin-left: .5rem;
}
.language-select select {
    color: #1d7acb !important;
    border: none !important;
    width: 60px;
}
.admin-language-select {
    display: inline-block;
    width: 55px;
    margin-top: 10px;
}
.admin-language-select .form-group select {
    border: none;
    padding-left: 10px;
    color: #1d7acb !important;
}

canvas {
    image-rendering: -moz-crisp-edges;
    image-rendering: -webkit-crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
        image-rendering: pixelated;
    image-rendering: crisp-edges;
}

.verifier h6 {
    font-size: 0.75rem;
    font-weight: 700 !important;
    color: #c23251 !important;
}

.bzr-sale.input-group-text {
    font-size: 0.6rem !important;
    font-weight: 600;
    text-transform: uppercase;
}
.number-format {
    font-family: 'Open Sans', sans-serif;
    text-align: right;
}

.preview-table.table {
    padding: 0px;
}
.preview-table.table > tbody > tr > td {
    font-size: 0.75rem;
    vertical-align: top;
    padding: 4px 0 0 0;
}
.preview-table.table > tbody > tr > td > p {
    font-size: 0.7rem;
    margin: 0;
}
.preview-table.table > tbody > tr > td > p:first-child {

    margin: 3px 0 0 0;
}

.preview-table.table > tbody > tr > td:first-child {
    width: 100px;
    font-size: 0.7rem;
    text-transform: uppercase;
    font-weight: 500;
    color: dimgray;
    padding: 5px 0 0 0;
}


/*************************/
/*     10. Dashboard     */
/*************************/
.bzr-dashboard {
    background-color: white;
}

.bzr-chart-btns button{
    border-radius: 2px;
    padding: 2px 5px;
    font-size: .9rem;
}

@media (max-width: 992px){
    .bzr-dashboard {
        max-width: 900px;
    }
}

.bzr-dashboard .bzr-list-1 > div {
    border-radius: 1px;
    font-size: 0.65rem;
    color: #555;
    font-weight: 600;
    text-transform: uppercase;
}
.bzr-dashboard .bzr-list-1 > div > span {
    color: #000;
    float: right;
}

.totals-bag {
    position: fixed;
    bottom: 0;
    right: 4%;
    width: 42%;
    background-color: #333;
}

.totals-bag .table{
    color: #eee !important;
    margin: 0;
}
#totals-box > div > div:first-child {
    background-color: dimgray;
    border-radius: .1rem;
}
#totals-box label.col-form-label{
    font-size: 0.75rem !important;
    padding: 0;
    padding-top: 2px;
}
#totals-box select{
    vertical-align: top;
    font-size: 0.85rem !important;
    padding: 0;
    padding-left: 15px;
    height: 20px;
}
#totals-box input{
    font-size: 0.85rem !important;
    padding: 0;
    padding-right: 15px;
    height: 20px;
}


/*  Subscription - Pricing  */

.pricing-card {
    border: solid dimgray 2px;
}

.dashed-border {
    border: 1px dashed #dee2e6!important;
}
.bzr-button-link {
    font-size: .7rem;
    font-weight: 600;
    color: #1d7acb !important;
    text-transform: uppercase;
    border: none;
    background-color: transparent !important;
    position: absolute;
    right: 60px;
}
.bzr-navegation-pane .bzr-button-link {
    font-size: .8rem;
    font-weight: 600;
    color: dimgray !important;
    margin: 0px;
    z-index: 10000;
}
.input-sale-number label {
    text-transform: uppercase;
    font-size: .7rem;
    font-weight: 600;
    display: block;
    text-align: center;
    margin-bottom: 0;
}
.input-sale-number input {
    display: block;
    width: 100%;
    border: none;
/*     border: solid 1px #ddd;
    border-bottom-left-radius: .1rem;
    border-bottom-right-radius: .1rem;
    border-top: none; */
    text-align: right;
    padding-right: 5px;
    color: #174ab1;
    background-color: #f3f3f3;
    font-size: 0.9rem;
}
.input-sale-number input:-moz-read-only {
    background-color: #fafafa;
    font-size: 0.9rem;
    font-weight: 600;
}
.input-sale-number input:read-only {
    background-color: #fafafa;
    font-size: 0.9rem;
    font-weight: 600;
}
.editable-cell {
    display: inline;
    border-bottom: dotted 1px cornflowerblue;
}
.helper-msg {
    color: lightgoldenrodyellow;
    background-color: #333;
    box-shadow: 0px 0px 2px orangered;
    border-radius: 50%;
    font-weight: 600;
    padding: 1px 3px;
}
.bzr-textarea {
    font-size: .75rem;
    color: rgb(0, 92, 179) !important;
    font-weight: 600;
    border-color: #ddd;
    border-radius: 2px;
    width: 100%;
}
.modal-frame {
    position: absolute;
    top: -5px;
    left: -5px;
    background-color: #333333aa;
    height: 100%;
    width: 100%;
    z-index: 7000;
    font-size: 1.2rem;
    font-weight: 600;
    color: #fff;
    text-align: center;
}
.modal-frame > div {
    margin-top: calc(10%);
}

/* clients-form */
.clients-list div:first-child.row {
    max-width: 880px;
    margin: auto !important;
}
.client-option {
    max-width: 300px;
    border-radius: 5px;
    padding: 2rem;
    margin: 25px auto !important;
    text-align: center;
}
.client-option > i {
    font-size: 80px;
}
.bzr-color-new {
    background-color: #4dbd74;
    color: white;
}
.bzr-color-mod {
    background-color: #20a8d8;
    color: white;
}
.bzr-color-extra-1 {
    background-color: #ffc107;
    color: white;
}
[class*="bzr-color-"] h4 {
    margin-top: 1rem;
    font-weight: 600;
    color: white !important;
}


/*    ALARMS    */
.show-alarm-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
    font-weight: 600;
}
.show-alarm-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    font-weight: 600;
}
.show-alarm-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
    font-weight: 600;
}
.show-alarm-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
    font-weight: 600;
}
.line-through {
    text-decoration: line-through;
}


/* PLACE HOLDER ANIMATION EXAMPLE */

.placeholder-item {
    box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.15);
    border-radius: 4px;
    position: relative;
    overflow: hidden;
}

.placeholder-loading::before {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
    -webkit-animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1);
            animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1);
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
}

@-webkit-keyframes load {
    from {
        left: -150px;
    }
    to   {
        left: 100%;
    }
}

@keyframes load {
    from {
        left: -150px;
    }
    to   {
        left: 100%;
    }
}

.pop-up-message {
    position: fixed;
    top: 100px;
    left: 100px;
    background-color: white;
    color: dimgray;
    z-index: 30000;
    padding: 1rem;
}


/* new styles */

.bzr-stat {
    border-radius: .1rem;
}
.bzr-stat .card-body {
    padding: 1rem;
}

.stat-widget-one .stat-icon i {
    font-size: 30px;
    border-width: 3px;
    border-style: solid;
    border-radius: 100px;
    padding: 10px;
    font-weight: 900;
    display: inline-block;
}

@media (max-width: 1368px) {
    .stat-widget-one .stat-content {
        margin-left: 10px;
        }
}
.stat-widget-one {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.stat-widget-one .stat-content {
    margin-left: 10x;
}
.stat-widget-one .stat-text {
    text-transform: capitalize;
    font-size: 1rem;
    font-weight: 600;
    color: #868e96;
}
.dib {
    display: inline-block;
}

.bzr-msg-bubble {
    width: 300px;
    height: 50px;
    position: fixed;
    top: calc(100vh - 100px);
    right: -310px;
    transition: right 300ms linear;
    z-index: 10000;
    box-shadow: -5px 0px 100px #000000ac;
    background-color: transparent;
}
.bzr-msg-bubble.show{
    right: 50px;
    transition: right .5s linear;
}

/*          Changes for small display      */
@media (max-width: 991px) {
    .main-panel {
      width: 100%; 
    }
    .navbar-transparent {
      padding-top: 15px;
      background-color: rgba(0, 0, 0, 0.45); }
    body {
      position: relative; }
    .main-panel {
      transform: translate3d(0px, 0, 0);
      transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
      left: 0; }
    .navbar .container {
      left: 0;
      width: 100%;
      transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
      position: relative; }
    .navbar .navbar-collapse.collapse,
    .navbar .navbar-collapse.collapse.in,
    .navbar .navbar-collapse.collapsing {
      display: none !important; }
    .navbar-nav > li {
      float: none;
      position: relative;
      display: block; }
    .sidebar {
      position: fixed;
      display: block;
      top: 0;
      height: 100%;
      width: 225px;
      right: 0;
      left: auto;
      z-index: 6000;
      visibility: visible;
      background-color: #999;
      overflow-y: visible;
      border-top: none;
      text-align: left;
      padding: 0;
      transform: translate3d(225px, 0, 0);
      transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1); 
    }
      .sidebar > ul {
        position: relative;
        overflow-y: scroll;
        height: calc(100vh - 61px);
        width: 100%; 
    }
      .sidebar::before {
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        position: absolute;
        background-color: #282828;
        display: block;
        content: "";
    }
  
      .sidebar .sidebar-wrapper > .nav > div .nav {
        margin-top: 10px;
        float: none !important; 
      }
      .sidebar .sidebar-wrapper > .nav > div .nav > div > a {
        margin: 0;
        line-height: 30px;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        margin: 5px 15px; 
        color: #fff;
      }
      .sidebar .sidebar-wrapper > .nav > div .notification {
        float: left;
        line-height: 30px;
        margin-right: 8px;
        font-weight: 600; }
    .nav-open .sidebar {
      transform: translate3d(0px, 0, 0); }
    .nav-open .navbar .container {
      left: -225px; }
    .nav-open .main-panel {
      left: 0;
      transform: translate3d(-225px, 0, 0); }
  
    .bar1,
    .bar2,
    .bar3 {
      outline: 1px solid transparent; }
    .bar1 {
      top: 0px;
      -webkit-animation: topbar-back 500ms linear 0s;
      animation: topbar-back 500ms 0s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards; }
    .bar2 {
      opacity: 1; }
    .bar3 {
      bottom: 0px;
      -webkit-animation: bottombar-back 500ms linear 0s;
      animation: bottombar-back 500ms 0s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards; }
    .toggled .bar1 {
      top: 6px;
      -webkit-animation: topbar-x 500ms linear 0s;
      animation: topbar-x 500ms 0s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards; }
    .toggled .bar2 {
      opacity: 0; }
    .toggled .bar3 {
      bottom: 6px;
      -webkit-animation: bottombar-x 500ms linear 0s;
      animation: bottombar-x 500ms 0s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards; }
    @keyframes topbar-x {
      0% {
        top: 0px;
        transform: rotate(0deg); }
      45% {
        top: 6px;
        transform: rotate(145deg); }
      75% {
        transform: rotate(130deg); }
      100% {
        transform: rotate(135deg); } }
    @-webkit-keyframes topbar-x {
      0% {
        top: 0px;
        -webkit-transform: rotate(0deg); }
      45% {
        top: 6px;
        -webkit-transform: rotate(145deg); }
      75% {
        -webkit-transform: rotate(130deg); }
      100% {
        -webkit-transform: rotate(135deg); } }
    @keyframes topbar-back {
      0% {
        top: 6px;
        transform: rotate(135deg); }
      45% {
        transform: rotate(-10deg); }
      75% {
        transform: rotate(5deg); }
      100% {
        top: 0px;
        transform: rotate(0); } }
    @-webkit-keyframes topbar-back {
      0% {
        top: 6px;
        -webkit-transform: rotate(135deg); }
      45% {
        -webkit-transform: rotate(-10deg); }
      75% {
        -webkit-transform: rotate(5deg); }
      100% {
        top: 0px;
        -webkit-transform: rotate(0); } }
    @keyframes bottombar-x {
      0% {
        bottom: 0px;
        transform: rotate(0deg); }
      45% {
        bottom: 6px;
        transform: rotate(-145deg); }
      75% {
        transform: rotate(-130deg); }
      100% {
        transform: rotate(-135deg); } }
    @-webkit-keyframes bottombar-x {
      0% {
        bottom: 0px;
        -webkit-transform: rotate(0deg); }
      45% {
        bottom: 6px;
        -webkit-transform: rotate(-145deg); }
      75% {
        -webkit-transform: rotate(-130deg); }
      100% {
        -webkit-transform: rotate(-135deg); } }
    @keyframes bottombar-back {
      0% {
        bottom: 6px;
        transform: rotate(-135deg); }
      45% {
        transform: rotate(10deg); }
      75% {
        transform: rotate(-5deg); }
      100% {
        bottom: 0px;
        transform: rotate(0); } }
    @-webkit-keyframes bottombar-back {
      0% {
        bottom: 6px;
        -webkit-transform: rotate(-135deg); }
      45% {
        -webkit-transform: rotate(10deg); }
      75% {
        -webkit-transform: rotate(-5deg); }
      100% {
        bottom: 0px;
        -webkit-transform: rotate(0); } }
    @-webkit-keyframes fadeIn {
      0% {
        opacity: 0; }
      100% {
        opacity: 1; } }
    @keyframes fadeIn {
      0% {
        opacity: 0; }
      100% {
        opacity: 1; } }
    .dropdown-menu .divider {
      background-color: rgba(229, 229, 229, 0.15); }
    .navbar-nav {
      margin: 1px 0; }
      .navbar-nav .open .dropdown-menu > a {
        padding: 15px 15px 5px 50px; }
      .navbar-nav .open .dropdown-menu > a:first-child {
        padding: 5px 15px 5px 50px; }
      .navbar-nav .open .dropdown-menu > a:last-child {
        padding: 15px 15px 25px 50px; }
    [class*="navbar-"] .navbar-nav > a,
    [class*="navbar-"] .navbar-nav > a:hover,
    [class*="navbar-"] .navbar-nav > a:focus,
    [class*="navbar-"] .navbar-nav .active > a,
    [class*="navbar-"] .navbar-nav .active > a:hover,
    [class*="navbar-"] .navbar-nav .active > a:focus,
    [class*="navbar-"] .navbar-nav .open .dropdown-menu > a,
    [class*="navbar-"] .navbar-nav .open .dropdown-menu > a:hover,
    [class*="navbar-"] .navbar-nav .open .dropdown-menu > a:focus,
    [class*="navbar-"] .navbar-nav .navbar-nav .open .dropdown-menu > a:active {
      color: white; }
    [class*="navbar-"] .navbar-nav > a,
    [class*="navbar-"] .navbar-nav > a:hover,
    [class*="navbar-"] .navbar-nav > a:focus,
    [class*="navbar-"] .navbar-nav .open .dropdown-menu > a,
    [class*="navbar-"] .navbar-nav .open .dropdown-menu > a:hover,
    [class*="navbar-"] .navbar-nav .open .dropdown-menu > a:focus {
      opacity: .7;
      background: transparent; }
    [class*="navbar-"] .navbar-nav.navbar-nav .open .dropdown-menu > a:active {
      opacity: 1; }
    [class*="navbar-"] .navbar-nav .dropdown > a:hover .caret {
      border-bottom-color: #777;
      border-top-color: #777; }
    [class*="navbar-"] .navbar-nav .dropdown > a:active .caret {
      border-bottom-color: white;
      border-top-color: white; }
    .dropdown-menu {
      display: none; }
    .navbar-fixed-top {
      -webkit-backface-visibility: hidden; }
    #bodyClick {
      height: 100%;
      width: 100%;
      position: fixed;
      opacity: 0;
      top: 0;
      left: auto;
      right: 250px;
      content: "";
      z-index: 9999;
      overflow-x: hidden; }
    .social-line .btn {
      margin: 0 0 10px 0; }
    .subscribe-line .form-control {
      margin: 0 0 8px 0; }
    .social-line.pull-right {
      float: none; }
    .footer nav.pull-left {
      float: none !important; }
    .footer:not(.footer-big) nav > ul li {
      float: none; }
    .social-area.pull-right {
      float: none !important; }
    .form-control + .form-control-feedback {
      margin-top: -8px; }
    .btn.dropdown-toggle {
      margin-bottom: 0; }
    .media-post .author {
      width: 20%;
      float: none !important;
      display: block;
      margin: 0 auto 10px; }
    .media-post .media-body {
      width: 100%; }
    .navbar-collapse.collapse {
      height: 100% !important; }
    .navbar-collapse.collapse.in {
      display: block; }
    .navbar-header .collapse {
      display: block !important; }
    .navbar-header {
      float: none; }
    .navbar-nav .open .dropdown-menu {
      position: static;
      float: none;
      width: auto;
      margin-top: 0;
      background-color: transparent;
      border: 0;
      box-shadow: none; }
    .navbar-collapse .nav p {
      font-size: 14px;
      margin: 0; }
    .navbar-collapse [class^="pe-7s-"] {
        float: left;
        font-size: 20px;
        margin-right: 10px; 
    } 

    .table-responsive {
        width: 100%;
        margin-bottom: 15px;
        overflow-x: scroll;
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        -webkit-overflow-scrolling: touch; 
    }
    .preview-bag {
        position: fixed !important;
        top: 0;
        left: 0;
        width: 70%;
        background-color: #333;
        color: #eee;
    }
}
  
      
@media (min-width: 992px) {
    .main-panel {
        position: relative;
        overflow: hidden;
        float: right;
        width: calc(100% - 225px); 
        min-height: 100vh;
    }
    .container {
        width: 100%;
        padding-right: 5px;
        padding-left: 5px;
        margin-right: 0;
        margin-left: 0;
        }
    .preview-bag {
        position: fixed;
        top: 0;
        right: 0;
        width: 35%;
        background-color: white;
        color: #eee;
        height: 250px;
        overflow-y: auto;
        box-shadow: 0px 0px 5px #000000;
    }
    .table-full-width {
      margin-left: -15px;
      margin-right: -15px; }
    .table-responsive {
      overflow: visible; } 
}
  
