h6, .h6 {
    margin: .5rem;
    font-size: 0.7rem;
    font-weight: 600;
    text-transform: uppercase; 
}
.input-group-prepend span {
    padding: 2px 10px;
    margin-left: 0;
    height: 30px;
    border-radius: 2px;
}
.input-group-append span {
    padding: 2px 10px;
    margin-left: 0;
    height: 30px;
    border-radius: 2px;
}
.transacs-tabs {
    margin-bottom: -47px;
}
.transacs-tabs a {
    text-transform: capitalize;
    font-size: 1.5rem;
    font-weight: 300;
    z-index: 100;
    padding: 5px 15px;
    color: #1d7acb;
}
.transacs-tabs.nav-tabs .nav-link {
    border-top-left-radius: .1rem;
    border-top-right-radius: .1rem;
}
.bzr-home header div {
    max-width: 1100px;
    margin: auto;
}
.bzr-home div div {
    max-width: 1100px;
    margin: auto;
}
.bzr-form h4, .h4 {
    font-size: 20px;
    line-height: 30px; 
    margin-top: 0px;
    margin-bottom: .5rem;
    text-transform: capitalize;
}
.bzr-form {
    max-width: 768px;
    margin: 1rem auto;
    padding: 1rem;
}
label.col-form-label {
    padding-top: 5px;
}
.dc-popover a {
    color: #174ab1;
    font-weight: 600;
    text-transform: uppercase;
}
.dc-popover #delete.a {
    color: red;
}
.custom-select {
    height: 30px;
    padding: 0;
    padding-left: 10px;
    padding-right: 25px;
    text-transform: capitalize;
    font-size: .9rem;
    font-weight: 600;
}
.bzr-inventory-input select {
    padding: 0;
    padding-left: 10px;
    text-align: right;
    text-transform: uppercase;
    border: none;
    font-size: .7rem !important;
    font-weight: 600;
    background-color: #f3f3f3;
    color: dimgray;
    height: 20px;
}
.bzr-inventory-input label {
    margin-top: 1px;
    font-size: .7rem !important;
    font-weight: 600;
    color: #1d7acb !important;
    text-transform: uppercase;
    text-align: right;
}
#search-box {
    border: none;
    border-bottom: solid gray 1px;
}

.display-5 {
    font-size: 2rem;
    font-weight: 300;
    line-height: 1.2;
    padding: 10px;
}

.text-white div label {
    color: white !important;
}

.auth-form{
    max-width: 480px;
    min-width: 380px;
}

.header {
    margin-bottom: 2.5rem;
}

.card-out {
    font-size: 0.85rem;
    margin-bottom: -25px !important;
}

::placeholder {
    color: #999 !important;
}

.form-group {
    margin-bottom: 0.5rem;
}

.form-control {
    font-size: 0.9rem;
}

.form-control-resize > div {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}
.form-row {
    margin: 0;
}
.form-amount {
    border: none;
    font-size: 1.2rem;
    font-weight: 600;
}

.input-group.date-picker > .react-datepicker-wrapper {
    border: 1px solid #ced4da;
    border-left: none;
    border-radius: 0 3px 3px 0;
}
.input-group.date-picker > .react-datepicker-wrapper input.form-control{
    height: 25px;
    text-align: center;
    border: none;
}
.input-group.date-picker > .input-group-prepend > .input-group-text {
    padding: 0 5px;
}
.react-datepicker-wrapper .form-control {
    width: 110px;
    align-content: center;
}

nav #user-nav-dropdown .dropdown-menu {
    left: unset;
    right: 0 !important;
}
nav #user-nav-dropdown .dropdown-menu::before {
    left: 275px;
}
nav #user-nav-dropdown .dropdown-menu::after {
    left: 275px;
}

nav .user-dropdown > a {
    text-transform: capitalize;
}
nav .user-dropdown .dropdown-menu {
    width: 350px;
    height: 300px;
    padding: 1rem;
}

/* POS */
.bzr-pos {
    max-width: 1200;
    margin: 1rem auto;
    padding: 1rem;
}
.bzr-pos-grid-1 {
    padding-right: 3rem;
    padding-left: 1rem;
}
.bzr-pos-grid-2 {
    background-color: #1d7acb22;
    margin-left: .8rem;
    padding: 2px 1rem;
}
.bzr-pos-bill button {
    min-width: 200px;
}

/* filters */
.filt .dropdown {
    display: inline;
}
.filt .dropdown .dropdown-toggle.nav-link {
    display: inline;
}

.filt .dropdown-toggle::after {
    content: none;
}

.filt .dropdown > .dropdown-menu {
    min-width: 250px;
    width: fit-content;
    text-transform: capitalize;
}

.filt .dropdown > .dropdown-menu:before {
    border-bottom: 11px solid rgba(0,0,0);
    border-left: 11px solid  rgba(0,0,0,0);
    border-right: 11px solid rgba(0,0,0,0);
    content: "";
    display: inline-block;
    position: absolute;
    left: 12px;
    top: -11px;
    }
.filt .dropdown > .dropdown-menu:after {
    border-bottom: 11px solid #fff;
    border-left: 11px solid  rgba(0,0,0,0);
    border-right: 11px solid rgba(0,0,0,0);
    content: "";
    display: inline-block;
    position: absolute;
    left: 12px;
    top: -11px;
    }

.filt .filter-list .table thead tr:first-child th input.form-control  {
    display: inline-block !important;

}

.filt .filter-list .table thead tr:first-child th span  {
    margin-left: 10px;
    font-size: 1rem;
}

.filt .filter-list .table tbody td:first-child {
    width: 20px;
}
.filt .filter-list .table tbody tr:first-child td {
    font-weight: 600 !important;
}

div.filter-list table {
    min-width: 300px;
}
div.filter-list table > thead > tr > th div span {
    float: right !important;
    padding: 10px 10px 0px 0px;
}
div.filter-list table > thead > tr > th div > div {
    float: left !important;
    padding-top: 10px;
    padding-bottom: 0px;
}
div.filter-list table > thead > tr > th div > div > label {
    margin-left: 10px;
    font-weight: 600;
    color: #1d7acb;
}
.filter-list button {
    margin-top: 5px;
    margin-left: auto;
    padding: 0px 5px 1px 5px;
    font-size: .9rem;
    font-weight: 600;
}

/* Load Table*/
.load-table {
    margin: auto;
    width: 95%;
}
.load-table .table-responsive {
    overflow-x: auto;
}

/* list */
table.list > thead > tr > th {
    font-size: .8rem;
}
table.list > tbody > tr > td {
    font-size: .8rem;
}
table.list > tbody > tr > td.number-format {
    font-size: .85rem;
}

.bzr-table-actions.dropdown > div {
    padding: 10px 25px 10px 5px;
    min-width: 300px;
}
.bzr-table-actions.dropdown > div > a {
    text-transform: capitalize;
    font-size: 0.9rem;
    font-weight: 600;
    color: #333;
    padding: 2px 5px;
    border-radius: 0;

}
button.btn {
    border-radius: .2rem;
}

.bzr-table-actions button.btn {
    padding: 0 7px;
    line-height: 1rem;
}
.bzr-table-actions.dropdown > div > a:hover {
    background-color: #5fb4ff;
    color: #fff;
}
.bzr-table-actions.dropdown > div > a.disabled {
    color: #999;
}
.bzr-table-actions.dropdown > div > form {
    margin-left: 40px;
}
.bzr-row-bold > td {
    font-weight: 600;
}

.font-icon-detail span {
    display: inline-block;
    transition: color 150ms linear, background 150ms linear, font-size 150ms linear, width 150ms;
    padding: 2px 0px;
    font-size: 20px;
    width: 35px;
    margin: 5px;
    color: #1a1a1a;
    border: 1px solid #eaeaea;
    border-radius: 3px;
}
.font-icon-detail span:hover {
    transition: color 150ms linear, background 150ms linear, font-size 150ms linear, width 150ms;
    color: #eaeaea;
    background-color:  #1a1a1a;
}
.font-icon-detail > div {
    display: inline;
}
/*  ADMIN-NAVBAR  */
.admin-navbar .nav-item {
    padding-top: 7px;
}
.admin-navbar #active-bu.nav-item {
    margin-right: 20px;
    padding-top: 10px;
    color: #555;
    font-size: 1.1rem;
    font-weight: 600;
    text-transform: capitalize;
    cursor: pointer;
}
.admin-navbar .nav-item a.nav-link {
    margin: 0px;
    padding: 5px 5px;
}

.guest-navbar .navbar-collapse.collapse.show,
.guest-navbar .navbar-collapse.collapse.in {
  display: block !important; }

.guest-navbar .navbar-collapse.collapse.show a {
    padding: .2rem 1rem;
}
.guest-navbar .navbar-collapse.collapse.show .dropdown-menu.show {
    width: fit-content;
}
  

button.navbar-toggler {
    padding-top: .7rem;
    padding-bottom: 0;
} 

.navbar-light span.navbar-toggler-icon {
    background-image: none;
}

.navbar-light span.navbar-toggler-icon::after {
    font-family: "Font Awesome 5 Free";
    font-size: 1.5rem;
    content: "\f0c9";
}

@media (max-width: 991px) {
    .nav {
        display: block;
    }
}
@media (max-width: 991px) {
    .sidebar .sidebar-wrapper > .nav > div .nav > div > a {
        margin: 0px 15px;
    }
}

@media (max-width: 769px) {
    .form-control-resize > div {
        width: 90%;
    }
}


@media (min-width: 992px) {
.card form [class*="col-"]:last-child {
    padding-right: 0px;
    }
}

/************************/
/*     04. Sidebar     */
/************************/



#hide-button {
    background-color: #1d7acbad;
    opacity: 0.8;
    color: #eee;
    position: absolute;
    top: 43px;
    left: 225px;
    width: 13px;
    height: 35px; 
    padding-top: 8px;
    padding-right: 2px;
    font-size: 12px;
    font-weight: 400;
    transition: left 0.5s;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: 6100;
}

#hide-button:hover {
    background-color: #1d7acb;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, 0.5);
}

.hide #hide-button {
    left: 70px;
    transition: left 0.5s;
}

.sidebar {
    width: 225px;
    transition: width 0.5s;
    z-index: 6000;
}

.sidebar.hide {
    width: 70px;
    transition: width 0.5s;
}

.sidebar.hide .sidebar-wrapper {
    overflow: visible !important;
    width: 70px;
    /* transition: width 0.5s; */
}
.main-panel {
    background: rgba(203, 203, 210, 0.15);
}
.main-panel > .content {
    padding: 30px 15px;
    min-height: calc(100% - 123px); 
}
.main-panel > .footer {
    border-top: 1px solid #e7e7e7; 
}
.main-panel .navbar {
    margin-bottom: 0; 
}
.sidebar,
.main-panel {
    -webkit-transition-property: top,bottom;
    transition-property: top,bottom;
    -webkit-transition-duration: .2s,.2s;
    transition-duration: .2s,.2s;
    -webkit-transition-timing-function: linear,linear;
    transition-timing-function: linear,linear;
    -webkit-overflow-scrolling: touch; 
}
.main-panel.hide {
    width: calc(100% - 70px);
    transition: width 0.5s;
}

.sidebar.hide .nav li.active > a {
    color: #FFFFFF;
    opacity: 1;
    background: rgba(255, 255, 255, 0.23);
}
.sidebar.hide ul > li {
    width: 70px;
    /* transition: width 0.5s; */
}

.sidebar hr {
    width: 225px;
    margin-top: 1rem;
    border-top: 1px solid rgba(255, 255, 255, 0.7);
}
.sidebar.hide hr {
    width: 70px;
    /* transition: width 0.5s */;
}

.sidebar a {
    height: 50px;
}

.sidebar .logo .simple-text,
body > .navbar-collapse .logo .simple-text {
  display: block;
  margin-left: 15px;
  color: #FFFFFF;
  font-weight: 400;
  line-height: 30px; 
}

.sidebar .logo a.logo-normal {
    display: block; 
    font-size: 28px;
    padding-top: 15px;
  }
.sidebar .logo a.logo-normal span {
    font-style: italic;
    font-weight: 500;
    font-size: 10px;
}

.sidebar.hide .logo > a.logo-normal {
    display: none;
}

.sidebar.hide .nav > li > a > p {
    display: none;
}

.sidebar.hide .nav a::after {
    display: none;
}
.sidebar.hide .nav a > div > p {
    display: none;
}

.sidebar .dropdown.nav-item {
    width: 100%;
}

.sidebar .dropdown.nav-item {
    width: 100%;
}
.sidebar.hide div.dropdown-menu.show {
    background-color: #515158;
    position: absolute !important;
    transform: none !important;
    top: 0px !important;
    left: 70px !important;
}
.sidebar.hide .show .dropdown-menu a {
    background-color: #515158;
    height: 30px;
}

.sidebar.hide .nav a.dropdown-item {
    width: calc(100% - 10px) !important;
    padding: 0px;
    margin: 5px 5px !important;
}
.sidebar.hide .nav a:hover.dropdown-item {
    background-color: #ccc;
}


/************************/
/*     04. Buttons     */
/************************/

button.btn.btn-trash {
    padding: 0 5px;
}

button.btn.btn-download {
    background-color: #fff;
    color: #1dc7ea;
    padding: 0 5px;
}

.new-sale-btns .btn {
    font-size: 1rem;
    text-transform: capitalize;
    font-weight: 600;
    margin: auto 20px;
    padding: auto 50px;
    min-width: 150px;
}

.new-sale-btns .btn#clear {
    margin: auto;
    min-width: 100px;
}
.new-item-btn button {
    width: 100%;
    padding: 5px;
    text-transform: capitalize;
    font-weight: 600;
    border-radius: .1rem;
}

/************************/
/*     05. Features     */
/************************/
.tabs {
	padding-top: 2.5rem;
	padding-bottom: 2.5rem;
	background-color: #eee;
}

.tabs h2 {
	margin-bottom: 1.125rem;
    text-align: center;
    color: #717182;
    font: 700 2.25rem/2.75rem "Montserrat", sans-serif !important;
    text-transform: uppercase;
}

.tabs .p-heading {
	margin-bottom: 3.125rem;
}

.tabs .nav-tabs {
	margin-right: auto;
	margin-bottom: 2.5rem;
	margin-left: auto;
	justify-content: center;
	border-bottom: none;
}

.tabs .nav-link {
	margin-bottom: 1rem;
	padding: 0.5rem 1.375rem 0.25rem 1.375rem;
	border: none;
	border-bottom: 0.1875rem solid #f1f1f8;
	border-radius: 0;
	color: #515158;
	font: 700 1rem/1.75rem "Montserrat", sans-serif;
	text-decoration: none;
	transition: all 0.2s ease;
}

.tabs .nav-link.active,
.tabs .nav-link:hover {
	border-bottom: 0.1875rem solid #00c9db;
	background-color: transparent;
	color: #00c9db;
}

.tabs .nav-link .fas {
	margin-right: 0.375rem;
	font-size: 1rem;
}

.tabs .tab-content {
	width: 100%; /* for proper display in IE11 */
}

.tabs .card {
    border: none;
    box-shadow: none;
    background: transparent;
    margin-bottom: 0;
}

.tabs .card-body {
	padding: 1rem 0 1.25rem 0;
}

.tabs .card-title {
    
	margin-bottom: 0.5rem;
}

.tabs h4.card-title {
    color: #515158;
    font: 700 1.375rem/1.75rem "Montserrat", sans-serif !important;
    text-transform: capitalize;
}

.tabs .card .card-icon {
	display: inline-block;
	width: 3.5rem;
	height: 3.5rem;
	border-radius: 50%;
	background-color: #00c9db;
	text-align: center;
	vertical-align: top;
}

.tabs .card .card-icon .fas,
.tabs .card .card-icon .far {
	color: #fff;
	font-size: 1.75rem;
	line-height: 3.5rem;
}

.tabs #tab-1 .card.left-pane .text-wrapper {
	display: inline-block;
	width: 75%;
}

.tabs #tab-1 .card.left-pane .card-icon {
	float: left;
	margin-right: 1rem;
}

.tabs #tab-1 img {
	display: block;
	margin: 2rem auto 3rem auto;
}

.tabs #tab-1 .card.right-pane .text-wrapper {
	display: inline-block;
	width: 75%;
}

.tabs #tab-1 .card.right-pane .card-icon {
	margin-right: 1rem;
}

.tabs #tab-2 img {
	display: block;
	margin: 0 auto 2rem auto;
}

.tabs #tab-2 .text-area {
	margin-top: 1.5rem;
}

.tabs #tab-2 h3 {
	margin-bottom: 0.75rem;
}

.tabs #tab-2 .icon-cards-area {
	margin-top: 2.5rem;
}

.tabs #tab-2 .icon-cards-area .card {
	width: 100%; /* for proper display in IE11 */
}

.tabs #tab-3 .icon-cards-area .card {
	width: 100%; /* for proper display in IE11 */
}

.tabs #tab-3 .text-area {
	margin-top: 0.75rem;
	margin-bottom: 4rem;
}

.tabs #tab-3 h3 {
	margin-bottom: 0.75rem;
}

.tabs #tab-3 img {
	margin: 0 auto 3rem auto;
}

.tabs .card-body .text-wrapper p {
    font-size: 0.9rem;
}
.bzr-home header {
    margin: auto auto 50px;
}

.bzr-home .header-info > h1 {
    font-style: italic !important;
}
.bzr-home .header-info > h1:first-child {
    margin-top: 0px !important;
}
.bzr-home .header-info > h1:not(:first-child) {
    margin-top: 20px !important;
    font-style: italic !important;
}

.bzr-home .header-info > p {
    color: #555;
    font: 400 1.15rem "Open Sans", sans-serif;
    line-height: 35px;
}
.bzr-home .header-image {
    max-width: 400px;
}
.bzr-home p::first-letter { 
    text-transform: uppercase;
}
#features .card-body {
    margin: auto 10px;
}
table.bzr-table > tbody > tr {
    cursor: context-menu;
}
@media (min-width: 768px) {
    .p-heading {
        width: 85%;
        margin-right: auto;
        margin-left: auto;
        }
    .col-md-4 {
        flex: 0 0 33.333333%;
        max-width: 100%;
        }
}
@media screen and (min-width: 1281px) {
    table.bzr-table > thead > tr > th {
        font-size: 0.9rem;
    }
    table.bzr-table > tbody > tr > td {
        font-size: 0.9rem;
    }
    table.items > thead > tr > th {
        font-size: .9rem;
    }
    
    table.items > tbody > tr > td {
        font-size: .9rem;
    }
    table.items > tbody > tr > td.number-format {
        font-size: 1rem;
    }
    /* list */
    table.list > thead > tr > th {
        font-size: .9rem;
    }
    table.list > tbody > tr > td {
        font-size: .9rem;
    }
    table.list > tbody > tr > td.number-format {
        font-size: 1rem;
    }

    .p-heading {
        width: 44%;
        }
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-fluid {
        max-width: 1400px;
    }
}

.upload-image {
    background-image: url('../img/upload-image.png');
    background-repeat: no-repeat;
    background-position: center;
}

.center-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80px;
    height: 80px;
    margin-top: 60px !important;
}

table.bzr-table {
    min-height: 200px;
    display: block;
    
    text-align: left;
    overflow-x: auto !important;
/*     width: fit-content;*/
    margin-left: auto;
    margin-right: auto; 
}

table.bzr-table > thead > tr > th {
    background-color: gray;
    color: #fff !important;
    vertical-align: top;
    text-align: center;
    font-size: .75rem;
    /* border-top: solid #999 1px; */
    border-bottom: solid #999 1px;
    padding: 5px 0;
}
table.bzr-table thead > tr > th > div {
    vertical-align: top;
}
table.bzr-table > thead > tr > th > div > div {
    display: inline-block;
}
table.bzr-table > thead > tr > th > div > div > a {
    padding: 0px 3px;
}
.card table.bzr-table > thead > tr > th:first-child {
    min-width: 20px;
    padding: 5px 7px;
}
.card table.bzr-table > tbody > tr > td:first-child {
    min-width: 20px;
    padding: 5px 7px;
}

table.bzr-table > tbody > tr > td {
    border-top: solid #999 1px;
    text-align: center;
    vertical-align: top;
    font-size: .75rem;
}
table.bzr-table thead > tr > th > div, table.bzr-table thead > tr > th > div.dropdown.nav-item > a > div {
    color: #fff !important;
    font-weight: 600;
}
table.bzr-table thead > tr > th > div.dropdown.nav-item > a {
    padding-top: 0;
}

table.bzr-table tfoot {
    font-size: .75rem;
    text-align: left;
    color: #eee;
}
table.bzr-table tfoot > tr > td span {
    font-size: .75rem;
    margin: 0;
    padding: 0;
    width: auto;
    color: #eee;
    border: none;
    float: right;
}
.bzr-table-toolbar {
    float: right;
}
.bzr-table-toolbar span {
    font-size: 1.2rem;
}

.bzr-mini-table {
    font-size: 0.75rem;
    font-weight: 600;
}

.bzr-lateral-box {
    height: 100vh;
    /* width: calc(100vh*0.78 + 300px); */
    width: calc(100vw - 225px);
    position: fixed;
    top: 0px;
    right: -100vw;
    transition: right 300ms linear;
    z-index: 5000;
    box-shadow: -5px 0px 30px #000000ac;
    background-color: #000000aa;
    overflow-y: scroll;
}
.bzr-lateral-box.hide {
    width: calc(100vw - 70px);
}
.bzr-lateral-box.show{
    right: 0px;
    transition: right .5s linear;
}
.bzr-lateral-box object {
    height: 100vh;
    width: calc(100vh*0.78);
}
.bzr-lateral-box .menu {
    max-width: 300px;
    background-color: white;
    margin-left: auto;
    height: 100vh;
}
.bzr-lateral-box .object {
    width: calc(100vh*0.78);
}
.bzr-lateral-box .lateral-form {
    height: 100vh;
    max-width: 1200px;
    background-color: white;
    margin-left: auto;
}
.bzr-lateral-box .lateral-form .input-group-text {
    padding: 2px 10px;
    margin-left: 0;
    height: 30px;
    border-radius: 2px;
}
.bzr-lateral-box .form-1 {
    margin: 0 auto;
    width: 90%;
}
.bzr-lateral-box .fas.fa-times {
    font-size: 30px !important;
    color: dimgray !important;
}
.bzr-lateral-nav hr {
    width: 100%;
    margin: .5rem;
}
.bzr-lateral-nav .nav-link {
    color: #555;
    font-size: .95rem;
    font-weight: 600;
    text-transform: capitalize;
}
.bzr-lateral-nav .nav-link:hover {
    color: #fff;
    background-color: #1d7acbad;
    border-radius: 3px;
}
.bzr-lateral-nav .nav-link.disabled {
    color: #999;
}
.bzr-lateral-nav form {
    margin-left: 20px;
}
.bzr-lateral-nav form > div > label:hover {
    text-decoration: underline;
}
div.bzr-lateral-tabs{
    margin-bottom: 1rem;
}
div.bzr-lateral-tabs div > a {
    padding: .2rem .5rem;
    text-transform: capitalize;
    font-weight: 600;
}
div.bzr-tab-pane-scrollable {
    height: 400px;
    overflow-y: auto;
}
input.cell-number {
    border: none;
    text-align: right;
}
.bzr-lateral-scrollable-100vh {
    height: calc(100vh);
    overflow: scroll;
}
.bzr-lateral-scrollable-75vh {
    height: calc(75vh);
    overflow: scroll;
}
.bzr-lateral-scrollable-50vh {
    height: calc(50vh);
    overflow: scroll;
}

/* table.bzr-table {
    width: 100%;
}
table.bzr-table tbody{
    display:block;
    overflow:auto;
    height:200px;
    width:100%;
}
table.bzr-table thead tr{
    display:block;
}
table.bzr-table tbody tr{
    display:block;
} */

table.items > thead > tr > th {
    text-align: center;
    vertical-align: top;
    font-size: .8rem;
}

table.items > tbody > tr > td {
    vertical-align: top;
    font-size: .8rem;
}
table.items > tbody > tr > td.number-format {
    font-size: .85rem;
}

/* The animation code */
@keyframes flash-text {
    from {
        text-shadow: 0px 0px 3px yellow;
        color: orangered;
        font-weight: 600;
    }
    to {
        font-weight: 400;
        text-shadow: none;
        color: initial;
    }
  }
  
/* The element to apply the animation to */
table.flash-text-animation.items > tbody > tr > td.number-format {
    animation-name: none;
    animation-duration: 1.5s;
}

table.items > tbody p{
    padding: 0;
    margin: 0;
    font-size: .75rem;
}

/* PRICES */
.price-presets-modal {
    min-width: 700px;
}

table.prices > thead > tr > th {
    text-align: center;
    vertical-align: top;
    font-size: .7rem;
}
table.prices > thead > tr:first-child > th{
    color: white !important;
}
table.prices > tbody > tr > td {
    vertical-align: top;
    font-size: .7rem;
}
table.prices > tbody p{
    padding: 0;
    margin: 0;
    font-size: .75rem;
}

.dropdown.icon-dropdown {
    padding: 0;
    margin: 0;
}
.dropdown.icon-dropdown span {
    margin: 0;
}
.dropdown.icon-dropdown button {
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
}

.dropdown.icon-dropdown button::after {
    content: none;
}
/***********************/
table.totals-table tr > td {
    padding: 0 0 5px;
    border: none;
    text-transform: uppercase;
}
table.totals-table tbody > tr:first-child > th {
    border-top: none;
}
table.totals-table tr > th {
    padding: 0 0 5px;
    text-transform: uppercase;
}

table.totals-table > tbody > tr > td {
    font-size: 0.7rem;
}
table.totals-table > tbody > tr > th {
    font-size: 1.1rem;
}
table.totals-table > tbody > tr.sub-total > th {
    font-size: 0.9rem;
}
table.preview-table > tbody > tr > td, th {
    padding: 0;
}
.card .table tbody td:last-child, .card .table thead th:last-child {
    padding-right: 0px;
    }


div.bzr-cblist .dropdown button.btn {
    border-radius: .1rem;
}
div.bzr-cblist .dropdown button.btn:after {
    display: none
}

div.cblist-menu {
    position: absolute;
    min-width: 350px;

    max-width: 370px;
    width: fit-content !important;
    top: 0px;
    transform: translate3d(calc(-100% + 33px), 31px, 0px) !important; 
    border-radius: .2rem;
    border: none;
    text-transform: capitalize;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    
    max-height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 3000;
}
div.cblist-menu > a {
    font-size: 0.8rem;
    padding: 7px 10px 0;
    font-weight: 600;
}
div.bzr-cblist .cblist-menu > a:hover {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px;
    background-color: #5fb4ff;
    color: #fff;
}
div.bzr-cblist .dropdown-menu > a:first-child {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}
div.bzr-cblist .dropdown-menu > a:last-child {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}
div.cblist-menu > a:focus {
    background-color: #1d7fd4;
    color: #fff;
}

div.cblist-options {
    display: inline;
}
div.cblist-options button {
    border: none;
    margin-top: 0px;
    font-size: 12px;
}
div.cblist-options .form-group {
    margin: 5px 15px;
}

.list-group-item {
    padding: 0.5rem;
}

/*************************/
/*       04. Modals      */
/*************************/
.modal.show {
    z-index: 7000;
}

.modal-preview {
    color: #000;
    width: calc(100vh*0.78 + 300px);
    max-width: none!important;
    margin-right: calc(100vh*0.11);
}

.modal-confirm {
    color: #000;
    width: fit-content;
    padding: 1rem;
    margin: 0 auto;
}

.modal-preview .modal-content {
    height: 100vh !important;
    background-color: rgba(0,0,0,.7) !important;
    padding-left: 1rem;
    z-index: 7010;
}

.modal-preview object {
    width: calc(95vh*0.78);
    height: 95vh;
}

.modal-100vw {
    width: 100%;
    max-width: none!important;
}

.modal-100vw .modal-content {
    background-color: #000;
    border-radius: 0;
}

.modal-preview .btns .btn {
    padding: .1rem .5rem;
    margin: .5rem .5rem .5rem;
    font-weight: 500;
}

.modal-preview .btns .btn.download {
    padding: .2rem .5rem;
    margin: 0 .5rem .5rem;
}

.modal-preview .btns a {
    color: rgb(0, 102, 255);
    font-size: 0.9rem;
    font-weight: 600;
}
.modal-preview .btns a:hover {
    color: #777;
}
@media (min-width: 576px) {
    .modal-dialog.modal-parent {
        width: fit-content !important;
        max-width: none;
        margin: 1.75rem auto;
    }
}
.modal-parent .modal-content {
    width: fit-content !important;
}
.modal-parent button {
    text-transform: capitalize;
    font-size: 0.9rem;
    font-weight: 600;
    margin: auto 0px;
}
.modal-parent i {
    font-size: 30px;
}
.modal-parent label {
    font-size: .7rem !important;
    font-weight: 600 !important;
}

/*******************************/
/*       04. Enroll Steps      */
/*******************************/

.enrollment-progress {
}

.card-focus {
    box-shadow: 0 0.0625rem 0.375rem 0 rgb(0, 102, 255);
}

input.form-control:focus {
    box-shadow: 0 0.0625rem 0.375rem 0 rgb(0, 102, 255);
}

.main-container {
    min-height: calc(100vh - 180px) !important;
}

.bzr-form label {
    font-weight: 600 !important;
    font-size: 0.75rem;
    color: #555
}

.bzr-label {
    font-size: 0.75rem !important;
    padding: 10px 0 5px;
    text-transform: capitalize;
    font-weight: 600;
    color: #555;
}
.bzr-labels {
    text-transform: capitalize;
    font-weight: 600 !important;
    color: #555 !important;
}

.lateral-dialog label {
    font-size: 1rem !important;
    text-transform: capitalize;
}
.lateral-dialog input {
    margin-top: .2rem;
    font-size: 1.1rem !important;
}
table.lateral-items {
    margin: .5rem auto;
    width: 100%;
}
table.lateral-items > thead > tr > th {
    font-size: .75rem !important;
}
table.lateral-items > tbody > tr > td {
    font-size: 0.75rem !important;
    vertical-align: top;
}
table.lateral-items > tbody > tr > td > p {
    font-size: 0.75rem !important;
    margin-bottom: .3rem;
}
table.lateral-totals{
    margin-left: auto;
    max-width: 450px;
}
table.lateral-totals > thead > tr > th {
    font-size: .75rem !important;
    text-transform: capitalize;
}
table.table.lateral-totals > tbody > tr > td {
    font-size: 0.75rem !important;
    text-transform: capitalize;
    vertical-align: top;
    padding: 2px;
    border: none;
}
table.table.lateral-totals > tbody > tr > th {
    text-transform: capitalize;
    font-size: 0.8rem !important;
    vertical-align: top;
    padding: 2px;
}
table.lateral-totals > tbody > tr > td > p {
    text-transform: capitalize;
    font-size: 0.8rem !important;
}

.jumbotron {
    padding: 2rem 2rem;
}

/* new-product*/
.accordion.new-product .card-header {
    margin: .8rem auto .3rem;
    padding: .4rem 1rem;
    background-color: rgba(0, 85, 160, 0.9);
}
.accordion.new-product .card-header h5{
    width: 120px;
    color: rgb(255, 255, 220);
    font-weight: 600 !important;
}
.accordion.new-product .card-header h5 .caret{
    float: right !important;
    font-size: 1.5rem;
}
.accordion.new-product .card-header h6{
    padding: 0;
    color: rgb(255, 255, 200);
    font-size: .75rem;
    font-weight: 600 !important;
}
.accordion.new-product .card-header .d-flex{
    color: rgb(255, 255, 200);
    font-size: .75rem;
}
.accordion.new-product .form-row {
    margin-bottom: 0.1rem;
}
/* edit-product*/
.accordion.edit-product .card-header {
    margin: .8rem auto .3rem;
    padding: .4rem 1rem;
    background-color: dimgray;
}
.accordion.edit-product .card-header h5{
    width: 120px;
    color: #eee;
    font-weight: 600 !important;
}
.accordion.edit-product .card-header h5 .caret{
    float: right !important;
    font-size: 1.5rem;
}
.accordion.edit-product .card-header h6{
    padding: 0;
    color: #eee;
    font-size: .75rem;
    font-weight: 600 !important;
}
.accordion.edit-product .card-header .d-flex{
    color: #eee;
    font-size: .7rem;
}

.accordion.edit-product .form-row {
    margin-bottom: 0.1rem;
}

p.accordion-card-footer {
    font-size: 0.8rem;
    color: #555;
    text-transform: capitalize;
}

.accordion > .card.let-overflow {
    overflow: visible !important;
}

.accordion-edit-label {
    color: #1d7acb;
}

.accordion-edit-label > span {
    font-size: 1.8rem;
}

.language-select {
    display: inline-block;
    width: 55px;
    margin-top: 1rem;
    margin-left: .5rem;
}
.language-select select {
    color: #1d7acb !important;
    border: none !important;
    width: 60px;
}
.admin-language-select {
    display: inline-block;
    width: 55px;
    margin-top: 10px;
}
.admin-language-select .form-group select {
    border: none;
    padding-left: 10px;
    color: #1d7acb !important;
}

canvas {
    image-rendering: -moz-crisp-edges;
    image-rendering: -webkit-crisp-edges;
    image-rendering: pixelated;
    image-rendering: crisp-edges;
}

.verifier h6 {
    font-size: 0.75rem;
    font-weight: 700 !important;
    color: #c23251 !important;
}

.bzr-sale.input-group-text {
    font-size: 0.6rem !important;
    font-weight: 600;
    text-transform: uppercase;
}
.number-format {
    font-family: 'Open Sans', sans-serif;
    text-align: right;
}

.preview-table.table {
    padding: 0px;
}
.preview-table.table > tbody > tr > td {
    font-size: 0.75rem;
    vertical-align: top;
    padding: 4px 0 0 0;
}
.preview-table.table > tbody > tr > td > p {
    font-size: 0.7rem;
    margin: 0;
}
.preview-table.table > tbody > tr > td > p:first-child {

    margin: 3px 0 0 0;
}

.preview-table.table > tbody > tr > td:first-child {
    width: 100px;
    font-size: 0.7rem;
    text-transform: uppercase;
    font-weight: 500;
    color: dimgray;
    padding: 5px 0 0 0;
}


/*************************/
/*     10. Dashboard     */
/*************************/
.bzr-dashboard {
    background-color: white;
}

.bzr-chart-btns button{
    border-radius: 2px;
    padding: 2px 5px;
    font-size: .9rem;
}

@media (max-width: 992px){
    .bzr-dashboard {
        max-width: 900px;
    }
}

.bzr-dashboard .bzr-list-1 > div {
    border-radius: 1px;
    font-size: 0.65rem;
    color: #555;
    font-weight: 600;
    text-transform: uppercase;
}
.bzr-dashboard .bzr-list-1 > div > span {
    color: #000;
    float: right;
}

.totals-bag {
    position: fixed;
    bottom: 0;
    right: 4%;
    width: 42%;
    background-color: #333;
}

.totals-bag .table{
    color: #eee !important;
    margin: 0;
}
#totals-box > div > div:first-child {
    background-color: dimgray;
    border-radius: .1rem;
}
#totals-box label.col-form-label{
    font-size: 0.75rem !important;
    padding: 0;
    padding-top: 2px;
}
#totals-box select{
    vertical-align: top;
    font-size: 0.85rem !important;
    padding: 0;
    padding-left: 15px;
    height: 20px;
}
#totals-box input{
    font-size: 0.85rem !important;
    padding: 0;
    padding-right: 15px;
    height: 20px;
}


/*  Subscription - Pricing  */

.pricing-card {
    border: solid dimgray 2px;
}

.dashed-border {
    border: 1px dashed #dee2e6!important;
}
.bzr-button-link {
    font-size: .7rem;
    font-weight: 600;
    color: #1d7acb !important;
    text-transform: uppercase;
    border: none;
    background-color: transparent !important;
    position: absolute;
    right: 60px;
}
.bzr-navegation-pane .bzr-button-link {
    font-size: .8rem;
    font-weight: 600;
    color: dimgray !important;
    margin: 0px;
    z-index: 10000;
}
.input-sale-number label {
    text-transform: uppercase;
    font-size: .7rem;
    font-weight: 600;
    display: block;
    text-align: center;
    margin-bottom: 0;
}
.input-sale-number input {
    display: block;
    width: 100%;
    border: none;
/*     border: solid 1px #ddd;
    border-bottom-left-radius: .1rem;
    border-bottom-right-radius: .1rem;
    border-top: none; */
    text-align: right;
    padding-right: 5px;
    color: #174ab1;
    background-color: #f3f3f3;
    font-size: 0.9rem;
}
.input-sale-number input:read-only {
    background-color: #fafafa;
    font-size: 0.9rem;
    font-weight: 600;
}
.editable-cell {
    display: inline;
    border-bottom: dotted 1px cornflowerblue;
}
.helper-msg {
    color: lightgoldenrodyellow;
    background-color: #333;
    box-shadow: 0px 0px 2px orangered;
    border-radius: 50%;
    font-weight: 600;
    padding: 1px 3px;
}
.bzr-textarea {
    font-size: .75rem;
    color: rgb(0, 92, 179) !important;
    font-weight: 600;
    border-color: #ddd;
    border-radius: 2px;
    width: 100%;
}
.modal-frame {
    position: absolute;
    top: -5px;
    left: -5px;
    background-color: #333333aa;
    height: 100%;
    width: 100%;
    z-index: 7000;
    font-size: 1.2rem;
    font-weight: 600;
    color: #fff;
    text-align: center;
}
.modal-frame > div {
    margin-top: calc(10%);
}

/* clients-form */
.clients-list div:first-child.row {
    max-width: 880px;
    margin: auto !important;
}
.client-option {
    max-width: 300px;
    border-radius: 5px;
    padding: 2rem;
    margin: 25px auto !important;
    text-align: center;
}
.client-option > i {
    font-size: 80px;
}
.bzr-color-new {
    background-color: #4dbd74;
    color: white;
}
.bzr-color-mod {
    background-color: #20a8d8;
    color: white;
}
.bzr-color-extra-1 {
    background-color: #ffc107;
    color: white;
}
[class*="bzr-color-"] h4 {
    margin-top: 1rem;
    font-weight: 600;
    color: white !important;
}


/*    ALARMS    */
.show-alarm-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
    font-weight: 600;
}
.show-alarm-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    font-weight: 600;
}
.show-alarm-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
    font-weight: 600;
}
.show-alarm-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
    font-weight: 600;
}
.line-through {
    text-decoration: line-through;
}


/* PLACE HOLDER ANIMATION EXAMPLE */

.placeholder-item {
    box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.15);
    border-radius: 4px;
    position: relative;
    overflow: hidden;
}

.placeholder-loading::before {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
    animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1);
    animation-iteration-count: infinite;
}

@keyframes load {
    from {
        left: -150px;
    }
    to   {
        left: 100%;
    }
}

.pop-up-message {
    position: fixed;
    top: 100px;
    left: 100px;
    background-color: white;
    color: dimgray;
    z-index: 30000;
    padding: 1rem;
}


/* new styles */

.bzr-stat {
    border-radius: .1rem;
}
.bzr-stat .card-body {
    padding: 1rem;
}

.stat-widget-one .stat-icon i {
    font-size: 30px;
    border-width: 3px;
    border-style: solid;
    border-radius: 100px;
    padding: 10px;
    font-weight: 900;
    display: inline-block;
}

@media (max-width: 1368px) {
    .stat-widget-one .stat-content {
        margin-left: 10px;
        }
}
.stat-widget-one {
    width: fit-content;
}
.stat-widget-one .stat-content {
    margin-left: 10x;
}
.stat-widget-one .stat-text {
    text-transform: capitalize;
    font-size: 1rem;
    font-weight: 600;
    color: #868e96;
}
.dib {
    display: inline-block;
}

.bzr-msg-bubble {
    width: 300px;
    height: 50px;
    position: fixed;
    top: calc(100vh - 100px);
    right: -310px;
    transition: right 300ms linear;
    z-index: 10000;
    box-shadow: -5px 0px 100px #000000ac;
    background-color: transparent;
}
.bzr-msg-bubble.show{
    right: 50px;
    transition: right .5s linear;
}

/*          Changes for small display      */
@media (max-width: 991px) {
    .main-panel {
      width: 100%; 
    }
    .navbar-transparent {
      padding-top: 15px;
      background-color: rgba(0, 0, 0, 0.45); }
    body {
      position: relative; }
    .main-panel {
      -webkit-transform: translate3d(0px, 0, 0);
      -moz-transform: translate3d(0px, 0, 0);
      -o-transform: translate3d(0px, 0, 0);
      -ms-transform: translate3d(0px, 0, 0);
      transform: translate3d(0px, 0, 0);
      -webkit-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
      -moz-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
      -o-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
      -ms-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
      transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
      left: 0; }
    .navbar .container {
      left: 0;
      width: 100%;
      -webkit-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
      -moz-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
      -o-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
      -ms-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
      transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
      position: relative; }
    .navbar .navbar-collapse.collapse,
    .navbar .navbar-collapse.collapse.in,
    .navbar .navbar-collapse.collapsing {
      display: none !important; }
    .navbar-nav > li {
      float: none;
      position: relative;
      display: block; }
    .sidebar {
      position: fixed;
      display: block;
      top: 0;
      height: 100%;
      width: 225px;
      right: 0;
      left: auto;
      z-index: 6000;
      visibility: visible;
      background-color: #999;
      overflow-y: visible;
      border-top: none;
      text-align: left;
      padding: 0;
      -webkit-transform: translate3d(225px, 0, 0);
      -moz-transform: translate3d(225px, 0, 0);
      -o-transform: translate3d(225px, 0, 0);
      -ms-transform: translate3d(225px, 0, 0);
      transform: translate3d(225px, 0, 0);
      -webkit-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
      -moz-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
      -o-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
      -ms-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
      transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1); 
    }
      .sidebar > ul {
        position: relative;
        overflow-y: scroll;
        height: calc(100vh - 61px);
        width: 100%; 
    }
      .sidebar::before {
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        position: absolute;
        background-color: #282828;
        display: block;
        content: "";
    }
  
      .sidebar .sidebar-wrapper > .nav > div .nav {
        margin-top: 10px;
        float: none !important; 
      }
      .sidebar .sidebar-wrapper > .nav > div .nav > div > a {
        margin: 0;
        line-height: 30px;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        margin: 5px 15px; 
        color: #fff;
      }
      .sidebar .sidebar-wrapper > .nav > div .notification {
        float: left;
        line-height: 30px;
        margin-right: 8px;
        font-weight: 600; }
    .nav-open .sidebar {
      -webkit-transform: translate3d(0px, 0, 0);
      -moz-transform: translate3d(0px, 0, 0);
      -o-transform: translate3d(0px, 0, 0);
      -ms-transform: translate3d(0px, 0, 0);
      transform: translate3d(0px, 0, 0); }
    .nav-open .navbar .container {
      left: -225px; }
    .nav-open .main-panel {
      left: 0;
      -webkit-transform: translate3d(-225px, 0, 0);
      -moz-transform: translate3d(-225px, 0, 0);
      -o-transform: translate3d(-225px, 0, 0);
      -ms-transform: translate3d(-225px, 0, 0);
      transform: translate3d(-225px, 0, 0); }
  
    .bar1,
    .bar2,
    .bar3 {
      outline: 1px solid transparent; }
    .bar1 {
      top: 0px;
      -webkit-animation: topbar-back 500ms linear 0s;
      -moz-animation: topbar-back 500ms linear 0s;
      animation: topbar-back 500ms 0s;
      -webkit-animation-fill-mode: forwards;
      -moz-animation-fill-mode: forwards;
      animation-fill-mode: forwards; }
    .bar2 {
      opacity: 1; }
    .bar3 {
      bottom: 0px;
      -webkit-animation: bottombar-back 500ms linear 0s;
      -moz-animation: bottombar-back 500ms linear 0s;
      animation: bottombar-back 500ms 0s;
      -webkit-animation-fill-mode: forwards;
      -moz-animation-fill-mode: forwards;
      animation-fill-mode: forwards; }
    .toggled .bar1 {
      top: 6px;
      -webkit-animation: topbar-x 500ms linear 0s;
      -moz-animation: topbar-x 500ms linear 0s;
      animation: topbar-x 500ms 0s;
      -webkit-animation-fill-mode: forwards;
      -moz-animation-fill-mode: forwards;
      animation-fill-mode: forwards; }
    .toggled .bar2 {
      opacity: 0; }
    .toggled .bar3 {
      bottom: 6px;
      -webkit-animation: bottombar-x 500ms linear 0s;
      -moz-animation: bottombar-x 500ms linear 0s;
      animation: bottombar-x 500ms 0s;
      -webkit-animation-fill-mode: forwards;
      -moz-animation-fill-mode: forwards;
      animation-fill-mode: forwards; }
    @keyframes topbar-x {
      0% {
        top: 0px;
        transform: rotate(0deg); }
      45% {
        top: 6px;
        transform: rotate(145deg); }
      75% {
        transform: rotate(130deg); }
      100% {
        transform: rotate(135deg); } }
    @-webkit-keyframes topbar-x {
      0% {
        top: 0px;
        -webkit-transform: rotate(0deg); }
      45% {
        top: 6px;
        -webkit-transform: rotate(145deg); }
      75% {
        -webkit-transform: rotate(130deg); }
      100% {
        -webkit-transform: rotate(135deg); } }
    @-moz-keyframes topbar-x {
      0% {
        top: 0px;
        -moz-transform: rotate(0deg); }
      45% {
        top: 6px;
        -moz-transform: rotate(145deg); }
      75% {
        -moz-transform: rotate(130deg); }
      100% {
        -moz-transform: rotate(135deg); } }
    @keyframes topbar-back {
      0% {
        top: 6px;
        transform: rotate(135deg); }
      45% {
        transform: rotate(-10deg); }
      75% {
        transform: rotate(5deg); }
      100% {
        top: 0px;
        transform: rotate(0); } }
    @-webkit-keyframes topbar-back {
      0% {
        top: 6px;
        -webkit-transform: rotate(135deg); }
      45% {
        -webkit-transform: rotate(-10deg); }
      75% {
        -webkit-transform: rotate(5deg); }
      100% {
        top: 0px;
        -webkit-transform: rotate(0); } }
    @-moz-keyframes topbar-back {
      0% {
        top: 6px;
        -moz-transform: rotate(135deg); }
      45% {
        -moz-transform: rotate(-10deg); }
      75% {
        -moz-transform: rotate(5deg); }
      100% {
        top: 0px;
        -moz-transform: rotate(0); } }
    @keyframes bottombar-x {
      0% {
        bottom: 0px;
        transform: rotate(0deg); }
      45% {
        bottom: 6px;
        transform: rotate(-145deg); }
      75% {
        transform: rotate(-130deg); }
      100% {
        transform: rotate(-135deg); } }
    @-webkit-keyframes bottombar-x {
      0% {
        bottom: 0px;
        -webkit-transform: rotate(0deg); }
      45% {
        bottom: 6px;
        -webkit-transform: rotate(-145deg); }
      75% {
        -webkit-transform: rotate(-130deg); }
      100% {
        -webkit-transform: rotate(-135deg); } }
    @-moz-keyframes bottombar-x {
      0% {
        bottom: 0px;
        -moz-transform: rotate(0deg); }
      45% {
        bottom: 6px;
        -moz-transform: rotate(-145deg); }
      75% {
        -moz-transform: rotate(-130deg); }
      100% {
        -moz-transform: rotate(-135deg); } }
    @keyframes bottombar-back {
      0% {
        bottom: 6px;
        transform: rotate(-135deg); }
      45% {
        transform: rotate(10deg); }
      75% {
        transform: rotate(-5deg); }
      100% {
        bottom: 0px;
        transform: rotate(0); } }
    @-webkit-keyframes bottombar-back {
      0% {
        bottom: 6px;
        -webkit-transform: rotate(-135deg); }
      45% {
        -webkit-transform: rotate(10deg); }
      75% {
        -webkit-transform: rotate(-5deg); }
      100% {
        bottom: 0px;
        -webkit-transform: rotate(0); } }
    @-moz-keyframes bottombar-back {
      0% {
        bottom: 6px;
        -moz-transform: rotate(-135deg); }
      45% {
        -moz-transform: rotate(10deg); }
      75% {
        -moz-transform: rotate(-5deg); }
      100% {
        bottom: 0px;
        -moz-transform: rotate(0); } }
    @-webkit-keyframes fadeIn {
      0% {
        opacity: 0; }
      100% {
        opacity: 1; } }
    @-moz-keyframes fadeIn {
      0% {
        opacity: 0; }
      100% {
        opacity: 1; } }
    @keyframes fadeIn {
      0% {
        opacity: 0; }
      100% {
        opacity: 1; } }
    .dropdown-menu .divider {
      background-color: rgba(229, 229, 229, 0.15); }
    .navbar-nav {
      margin: 1px 0; }
      .navbar-nav .open .dropdown-menu > a {
        padding: 15px 15px 5px 50px; }
      .navbar-nav .open .dropdown-menu > a:first-child {
        padding: 5px 15px 5px 50px; }
      .navbar-nav .open .dropdown-menu > a:last-child {
        padding: 15px 15px 25px 50px; }
    [class*="navbar-"] .navbar-nav > a,
    [class*="navbar-"] .navbar-nav > a:hover,
    [class*="navbar-"] .navbar-nav > a:focus,
    [class*="navbar-"] .navbar-nav .active > a,
    [class*="navbar-"] .navbar-nav .active > a:hover,
    [class*="navbar-"] .navbar-nav .active > a:focus,
    [class*="navbar-"] .navbar-nav .open .dropdown-menu > a,
    [class*="navbar-"] .navbar-nav .open .dropdown-menu > a:hover,
    [class*="navbar-"] .navbar-nav .open .dropdown-menu > a:focus,
    [class*="navbar-"] .navbar-nav .navbar-nav .open .dropdown-menu > a:active {
      color: white; }
    [class*="navbar-"] .navbar-nav > a,
    [class*="navbar-"] .navbar-nav > a:hover,
    [class*="navbar-"] .navbar-nav > a:focus,
    [class*="navbar-"] .navbar-nav .open .dropdown-menu > a,
    [class*="navbar-"] .navbar-nav .open .dropdown-menu > a:hover,
    [class*="navbar-"] .navbar-nav .open .dropdown-menu > a:focus {
      opacity: .7;
      background: transparent; }
    [class*="navbar-"] .navbar-nav.navbar-nav .open .dropdown-menu > a:active {
      opacity: 1; }
    [class*="navbar-"] .navbar-nav .dropdown > a:hover .caret {
      border-bottom-color: #777;
      border-top-color: #777; }
    [class*="navbar-"] .navbar-nav .dropdown > a:active .caret {
      border-bottom-color: white;
      border-top-color: white; }
    .dropdown-menu {
      display: none; }
    .navbar-fixed-top {
      -webkit-backface-visibility: hidden; }
    #bodyClick {
      height: 100%;
      width: 100%;
      position: fixed;
      opacity: 0;
      top: 0;
      left: auto;
      right: 250px;
      content: "";
      z-index: 9999;
      overflow-x: hidden; }
    .social-line .btn {
      margin: 0 0 10px 0; }
    .subscribe-line .form-control {
      margin: 0 0 8px 0; }
    .social-line.pull-right {
      float: none; }
    .footer nav.pull-left {
      float: none !important; }
    .footer:not(.footer-big) nav > ul li {
      float: none; }
    .social-area.pull-right {
      float: none !important; }
    .form-control + .form-control-feedback {
      margin-top: -8px; }
    .btn.dropdown-toggle {
      margin-bottom: 0; }
    .media-post .author {
      width: 20%;
      float: none !important;
      display: block;
      margin: 0 auto 10px; }
    .media-post .media-body {
      width: 100%; }
    .navbar-collapse.collapse {
      height: 100% !important; }
    .navbar-collapse.collapse.in {
      display: block; }
    .navbar-header .collapse {
      display: block !important; }
    .navbar-header {
      float: none; }
    .navbar-nav .open .dropdown-menu {
      position: static;
      float: none;
      width: auto;
      margin-top: 0;
      background-color: transparent;
      border: 0;
      -webkit-box-shadow: none;
      box-shadow: none; }
    .navbar-collapse .nav p {
      font-size: 14px;
      margin: 0; }
    .navbar-collapse [class^="pe-7s-"] {
        float: left;
        font-size: 20px;
        margin-right: 10px; 
    } 

    .table-responsive {
        width: 100%;
        margin-bottom: 15px;
        overflow-x: scroll;
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        -webkit-overflow-scrolling: touch; 
    }
    .preview-bag {
        position: fixed !important;
        top: 0;
        left: 0;
        width: 70%;
        background-color: #333;
        color: #eee;
    }
}
  
      
@media (min-width: 992px) {
    .main-panel {
        position: relative;
        overflow: hidden;
        float: right;
        width: calc(100% - 225px); 
        min-height: 100vh;
    }
    .container {
        width: 100%;
        padding-right: 5px;
        padding-left: 5px;
        margin-right: 0;
        margin-left: 0;
        }
    .preview-bag {
        position: fixed;
        top: 0;
        right: 0;
        width: 35%;
        background-color: white;
        color: #eee;
        height: 250px;
        overflow-y: auto;
        box-shadow: 0px 0px 5px #000000;
    }
    .table-full-width {
      margin-left: -15px;
      margin-right: -15px; }
    .table-responsive {
      overflow: visible; } 
}
  